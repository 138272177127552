import { ChevronDownIcon, ISelectProps, Select } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import { colors } from '../../../../styles';
import { FormControl, FormControlInputProps } from './FormControl';

export interface SelectInputOption {
  label: string;
  value: string;
}

export interface SelectInputProps extends FormControlInputProps {
  value: string;
  onChange: (newValue: string) => void;
  options: SelectInputOption[];
  inputProps?: ISelectProps;
  placeholder?: string;
}

export const SelectInput: React.FC<SelectInputProps> = React.memo((props) => {
  const { value, onChange, options, placeholder, label, disabled, inputProps } = props;

  return (
    <FormControl {...props}>
      <Select
        _actionSheet={{
          useRNModal: Platform.OS === 'ios',
        }}
        placeholder={placeholder ?? 'Select...'}
        w="100%"
        py={4}
        borderRadius={10}
        backgroundColor={colors.white}
        fontSize={14}
        dropdownIcon={<ChevronDownIcon mr={4} />}
        accessibilityLabel={label}
        isDisabled={disabled}
        onValueChange={onChange}
        selectedValue={value}
        {...inputProps}
      >
        {options.map((o) => (
          <Select.Item key={o.label} label={o.label} value={o.value} />
        ))}
      </Select>
    </FormControl>
  );
});
