import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Device } from '../../models/data';
import { deviceService } from '../../services';
import { commonActions } from '../commonActions';
import { DeviceState } from './types';

const getDevices = createAsyncThunk('devices/getDevices', async () => {
  return deviceService.getDevices();
});

const createDevice = createAsyncThunk('devices/createDevice', async (device: Partial<Device>) => {
  return deviceService.createDevice(device);
});

const deleteDevice = createAsyncThunk('devices/deleteDevice', async (id: string) => {
  return deviceService.deleteDevice(id);
});

const initialState: DeviceState = {
  currentDevice: null,
  devices: [],
  loading: false,
  error: null,
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDevices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        state.loading = false;
        state.devices = action.payload;
      })
      .addCase(getDevices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load device.';
      });

    builder
      .addCase(createDevice.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDevice.fulfilled, (state, action) => {
        state.loading = false;

        const { devices } = state;

        let exists = false;
        const nextDevices = [...devices].map((d) => {
          if (d.id === action.payload.id) {
            exists = true;
            return action.payload;
          }

          return d;
        });

        if (!exists) {
          nextDevices.push(action.payload);
        }

        state.currentDevice = action.payload;
      })
      .addCase(createDevice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to create device.';
      });

    builder
      .addCase(deleteDevice.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDevice.fulfilled, (state, action) => {
        state.loading = false;
        state.devices = state.devices.filter((device) => device.id !== action.payload);
      })
      .addCase(deleteDevice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to delete device.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });
  },
});

export const deviceActions = {
  ...deviceSlice.actions,
  getDevices,
  createDevice,
  deleteDevice,
};

export type DeviceSlice = {
  [deviceSlice.name]: ReturnType<typeof deviceSlice['reducer']>;
};
