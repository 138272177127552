import { extendTheme } from 'native-base';
import { Platform } from 'react-native';

export const colors = {
  darkGrey: '#8C8B8B',
  grey: '#8C8B8B',
  lightGrey: '#EFEFEF',
  lighterGrey: '#F8F8F8',
  black: '#232323',
  white: '#fff',
  primary: '#124B8F',
  primaryLight: '#F2F5F4',
  secondary: '#47ABC6',
  alert: '#F15D34',
  alertLight: '#F8E8E4',
  danger: '#d7263d',
  dangerLight: '#F8E8E4',
  warning: '#FBAF3E',
  warningLight: '#FEEBCC',
};

export const theme = extendTheme({
  colors: {
    // Add new color
    primary: {
      50: '#e0e5f7',
      100: '#bcc9eb',
      200: '#99acd9',
      300: '#778fc7',
      400: '#5572b5',
      500: '#3355a3',
      600: colors.primary,
      700: '#0f3f77',
      800: '#0d325f',
      900: '#0b2746',
    },
    secondary: {
      50: '#c4eef6',
      100: '#9edcf2',
      200: '#78cae9',
      300: '#52b8df',
      400: '#2ca6d5',
      500: '#0794cb',
      600: colors.secondary,
      700: '#0075a6',
      800: '#005b8a',
      900: '#00466f',
    },
  },
  fontConfig: {
    Avenir: {
      100: {
        normal: 'Avenir-Regular',
        italic: 'Avenir-Italic',
      },
      200: {
        normal: 'Avenir-Regular',
        italic: 'Avenir-Italic',
      },
      300: {
        normal: 'Avenir-Regular',
        italic: 'Avenir-Italic',
      },
      400: {
        normal: 'Avenir-Regular',
        italic: 'Avenir-Italic',
      },
      500: {
        normal: 'Avenir-Regular',
        italic: 'Avenir-Italic',
      },
      600: {
        normal: 'Avenir-Bold',
        italic: 'Avenir-Bold-Italic',
      },
      700: {
        normal: 'Avenir-Bold',
        italic: 'Avenir-Bold-Italic',
      },
      800: {
        normal: 'Avenir-Bold',
        italic: 'Avenir-Bold-Italic',
      },
      900: {
        normal: 'Avenir-Bold',
        italic: 'Avenir-Bold-Italic',
      },
    },
  },
  fonts: {
    heading: 'Avenir',
    body: 'Avenir',
    mono: Platform.select({ android: 'monospace', default: 'Courier New' }),
  },
});
