import * as Notifications from 'expo-notifications';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Notification } from '../../models/view';
import { useAppSelector } from '../../store';

export const useNotifications = (filter: (notification: Notification) => boolean) => {
  const { userNotifications, loading, error } = useAppSelector((state) => state.notifications);

  const notifications = useMemo((): Notification[] => {
    return userNotifications.map((userNotification) => ({
      id: userNotification.id.toString(),
      subject: userNotification.notification.subject,
      message: userNotification.notification.message,
      urgency: userNotification.notification.urgency,
      link: userNotification.notification.link,
      createdAt: new Date(userNotification.notification.createdAt),
      readAt: userNotification.readAt,
    }));
  }, [userNotifications]);

  const unreadNotifications = useMemo(() => {
    return notifications.filter((notification) => !notification.readAt);
  }, [notifications]);

  useEffect(() => {
    Notifications.setBadgeCountAsync(unreadNotifications.length);
  }, [unreadNotifications.length]);

  const filteredNotifications = useMemo(() => notifications.filter(filter), [notifications, filter]);

  return { notifications: filteredNotifications, loading, error };
};

export const useAllNotifications = () => {
  return useNotifications(useCallback(() => true, []));
};

export const useReadNotifications = () => {
  return useNotifications(useCallback((notification) => !!notification.readAt, []));
};

export const useUnreadNotifications = () => {
  return useNotifications(useCallback((notification) => !notification.readAt, []));
};

export const useNotification = (id?: string) => {
  const { notifications } = useAllNotifications();
  const [notification, setNotification] = useState<Notification | null>(null);

  useEffect(() => {
    setNotification(notifications.find((a) => a.id === id) ?? null);
  }, [notifications]);

  return { notification };
};
