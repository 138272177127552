export enum SettingSlugs {
  API_MODE = 'api_mode',

  CURRICULUM_PASSWORD_STUDENT_CANADA = 'curriculum_password_student_canada',
  CURRICULUM_PASSWORD_STUDENT_CANADA_COPPER = 'curriculum_password_student_canada_copper',
  CURRICULUM_PASSWORD_STUDENT_CANADA_GREEN = 'curriculum_password_student_canada_green',
  CURRICULUM_PASSWORD_STUDENT_CANADA_SILVER = 'curriculum_password_student_canada_silver',
  CURRICULUM_PASSWORD_STUDENT_CANADA_GOLD = 'curriculum_password_student_canada_gold',

  CURRICULUM_PASSWORD_STUDENT_COPPER = 'curriculum_password_student_copper',
  CURRICULUM_PASSWORD_STUDENT_GREEN = 'curriculum_password_student_green',
  CURRICULUM_PASSWORD_STUDENT_SILVER = 'curriculum_password_student_silver',
  CURRICULUM_PASSWORD_STUDENT_GOLD = 'curriculum_password_student_gold',

  CURRICULUM_PASSWORD_STUDENT_UK = 'curriculum_password_student_uk',
  CURRICULUM_PASSWORD_STUDENT_UK_COPPER = 'curriculum_password_student_uk_copper',
  CURRICULUM_PASSWORD_STUDENT_UK_GREEN = 'curriculum_password_student_uk_green',
  CURRICULUM_PASSWORD_STUDENT_UK_SILVER = 'curriculum_password_student_uk_silver',
  CURRICULUM_PASSWORD_STUDENT_UK_GOLD = 'curriculum_password_student_uk_gold',

  CURRICULUM_PASSWORD_INSTRUCTOR_CANADA = 'curriculum_password_instructor_canada',
  CURRICULUM_PASSWORD_INSTRUCTOR_CANADA_COPPER = 'curriculum_password_instructor_canada_copper',
  CURRICULUM_PASSWORD_INSTRUCTOR_CANADA_GREEN = 'curriculum_password_instructor_canada_green',
  CURRICULUM_PASSWORD_INSTRUCTOR_CANADA_SILVER = 'curriculum_password_instructor_canada_silver',
  CURRICULUM_PASSWORD_INSTRUCTOR_CANADA_GOLD = 'curriculum_password_instructor_canada_gold',

  CURRICULUM_PASSWORD_INSTRUCTOR_COPPER = 'curriculum_password_instructor_copper',
  CURRICULUM_PASSWORD_INSTRUCTOR_GREEN = 'curriculum_password_instructor_green',
  CURRICULUM_PASSWORD_INSTRUCTOR_SILVER = 'curriculum_password_instructor_silver',
  CURRICULUM_PASSWORD_INSTRUCTOR_GOLD = 'curriculum_password_instructor_gold',

  CURRICULUM_PASSWORD_INSTRUCTOR_UK = 'curriculum_password_instructor_uk',
  CURRICULUM_PASSWORD_INSTRUCTOR_UK_COPPER = 'curriculum_password_instructor_uk_copper',
  CURRICULUM_PASSWORD_INSTRUCTOR_UK_GREEN = 'curriculum_password_instructor_uk_green',
  CURRICULUM_PASSWORD_INSTRUCTOR_UK_SILVER = 'curriculum_password_instructor_uk_silver',
  CURRICULUM_PASSWORD_INSTRUCTOR_UK_GOLD = 'curriculum_password_instructor_uk_gold',

  CURRICULUM_PASSWORD_PROTEGE = 'curriculum_password_protege',
  CURRICULUM_PASSWORD_PROTEGEE = 'curriculum_password_protegee',
  CURRICULUM_PASSWORD_MENTOR = 'curriculum_password_mentor',

  CURRICULUM_URL_STUDENT_CANADA = 'curriculum_url_student_canada',
  CURRICULUM_URL_STUDENT_CANADA_COPPER = 'curriculum_url_student_canada_copper',
  CURRICULUM_URL_STUDENT_CANADA_GREEN = 'curriculum_url_student_canada_green',
  CURRICULUM_URL_STUDENT_CANADA_SILVER = 'curriculum_url_student_canada_silver',
  CURRICULUM_URL_STUDENT_CANADA_GOLD = 'curriculum_url_student_canada_gold',

  CURRICULUM_URL_STUDENT_COPPER = 'curriculum_url_student_copper',
  CURRICULUM_URL_STUDENT_GREEN = 'curriculum_url_student_green',
  CURRICULUM_URL_STUDENT_SILVER = 'curriculum_url_student_silver',
  CURRICULUM_URL_STUDENT_GOLD = 'curriculum_url_student_gold',

  CURRICULUM_URL_STUDENT_UK = 'curriculum_url_student_uk',
  CURRICULUM_URL_STUDENT_UK_COPPER = 'curriculum_url_student_uk_copper',
  CURRICULUM_URL_STUDENT_UK_GREEN = 'curriculum_url_student_uk_green',
  CURRICULUM_URL_STUDENT_UK_SILVER = 'curriculum_url_student_uk_silver',
  CURRICULUM_URL_STUDENT_UK_GOLD = 'curriculum_url_student_uk_gold',

  CURRICULUM_URL_INSTRUCTOR_CANADA = 'curriculum_url_instructor_canada',
  CURRICULUM_URL_INSTRUCTOR_CANADA_COPPER = 'curriculum_url_instructor_canada_copper',
  CURRICULUM_URL_INSTRUCTOR_CANADA_GREEN = 'curriculum_url_instructor_canada_green',
  CURRICULUM_URL_INSTRUCTOR_CANADA_SILVER = 'curriculum_url_instructor_canada_silver',
  CURRICULUM_URL_INSTRUCTOR_CANADA_GOLD = 'curriculum_url_instructor_canada_gold',

  CURRICULUM_URL_INSTRUCTOR_COPPER = 'curriculum_url_instructor_copper',
  CURRICULUM_URL_INSTRUCTOR_GREEN = 'curriculum_url_instructor_green',
  CURRICULUM_URL_INSTRUCTOR_SILVER = 'curriculum_url_instructor_silver',
  CURRICULUM_URL_INSTRUCTOR_GOLD = 'curriculum_url_instructor_gold',

  CURRICULUM_URL_INSTRUCTOR_UK = 'curriculum_url_instructor_uk',
  CURRICULUM_URL_INSTRUCTOR_UK_COPPER = 'curriculum_url_instructor_uk_copper',
  CURRICULUM_URL_INSTRUCTOR_UK_GREEN = 'curriculum_url_instructor_uk_green',
  CURRICULUM_URL_INSTRUCTOR_UK_SILVER = 'curriculum_url_instructor_uk_silver',
  CURRICULUM_URL_INSTRUCTOR_UK_GOLD = 'curriculum_url_instructor_uk_gold',

  CURRICULUM_URL_PROTEGE = 'curriculum_url_protege',
  CURRICULUM_URL_PROTEGEE = 'curriculum_url_protegee',
  CURRICULUM_URL_MENTOR = 'curriculum_url_mentor',

  PROGRAM_TEAM_ONE_EMAIL = 'program_team_one_email',
  PROGRAM_TEAM_ONE_NAME = 'program_team_one_name',
  PROGRAM_TEAM_ONE_TITLE = 'program_team_one_title',
  PROGRAM_TEAM_TWO_EMAIL = 'program_team_two_email',
  PROGRAM_TEAM_TWO_NAME = 'program_team_two_name',
  PROGRAM_TEAM_TWO_TITLE = 'program_team_two_title',
  PROGRAM_TEAM_THREE_EMAIL = 'program_team_three_email',
  PROGRAM_TEAM_THREE_NAME = 'program_team_three_name',
  PROGRAM_TEAM_THREE_TITLE = 'program_team_three_title',
  PROGRAM_TEAM_FOUR_EMAIL = 'program_team_four_email',
  PROGRAM_TEAM_FOUR_NAME = 'program_team_four_name',
  PROGRAM_TEAM_FOUR_TITLE = 'program_team_four_title',
  PROGRAM_TEAM_FIVE_EMAIL = 'program_team_five_email',
  PROGRAM_TEAM_FIVE_NAME = 'program_team_five_name',
  PROGRAM_TEAM_FIVE_TITLE = 'program_team_five_title',
  PROGRAM_TEAM_SIX_EMAIL = 'program_team_six_email',
  PROGRAM_TEAM_SIX_NAME = 'program_team_six_name',
  PROGRAM_TEAM_SIX_TITLE = 'program_team_six_title',
  PROGRAM_TEAM_SEVEN_EMAIL = 'program_team_seven_email',
  PROGRAM_TEAM_SEVEN_NAME = 'program_team_seven_name',
  PROGRAM_TEAM_SEVEN_TITLE = 'program_team_seven_title',
  PROGRAM_TEAM_EIGHT_EMAIL = 'program_team_eight_email',
  PROGRAM_TEAM_EIGHT_NAME = 'program_team_eight_name',
  PROGRAM_TEAM_EIGHT_TITLE = 'program_team_eight_title',

  LINKEDIN_URL = 'linkedin_url',
  LINKEDIN_ALUMNI_URL = 'linkedin_alumni_url',
  LINKEDIN_VOLUNTEER_URL = 'linkedin_volunteer_url',

  RTSWS_HOME_URL = 'rtsws_home_url',
  RTSWS_JOBS_URL = 'rtsws_jobs_url',
  RTSWS_DONATE_URL = 'rtsws_donate_url',

  PAYPAL_URL = 'paypal_url',
  INSTAGRAM_URL = 'instagram_url',
  TWITTER_URL = 'twitter_url',
  YOUTUBE_URL = 'youtube_url',
  FACEBOOK_URL = 'facebook_url',
  SNAPCHAT_URL = 'snapchat_url',
  TIKTOK_URL = 'tiktok_url',
  THREADS_URL = 'threads_url',
}
