import Constants from 'expo-constants';
import { useMemo } from 'react';

export const useAppVersion = () => {
  const version = useMemo(() => {
    const appVersion = Constants.manifest2?.extra?.expoClient?.version ?? 'Unknown';

    const buildNumber = Constants.manifest2?.extra?.expoClient?.ios?.buildNumber ?? 'Unknown';

    return `${appVersion} (${buildNumber})`;
  }, []);

  return version;
};
