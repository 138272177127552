import { Ionicons } from '@expo/vector-icons';
import { Box, CloseIcon, Flex, Pressable, Stack, Text, VStack } from 'native-base';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Platform } from 'react-native';
import { MenuHeader } from '../../components/menu/MenuHeader';
import { IconTitle, ListItem } from '../../components/ui';
import { FullWidthSection } from '../../components/ui/sections/FullWidthSection';
import { SettingSlugs } from '../../constants';
import { useSettings } from '../../hooks';
import { MenuStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActions } from '../../store/actions';
import { colors } from '../../styles';
import { openUrl } from '../../utils';
import { nativeConfirm } from '../../utils/ui';

export const MenuScreen: React.FC<MenuStackScreenWithNavProps<'Menu'>> = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const { currentDevice } = useAppSelector((state) => state.device);
  const { getSettingValueByName } = useSettings();

  const CareerCenterUrl = getSettingValueByName(SettingSlugs.RTSWS_JOBS_URL);
  const alumnaeUrl = getSettingValueByName(SettingSlugs.LINKEDIN_ALUMNI_URL);
  const rtswsUrl = getSettingValueByName(SettingSlugs.RTSWS_HOME_URL);

  const [signingOut, setSigningOut] = useState(false);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Pressable
          style={Platform.select({ web: { marginLeft: 12 } })}
          onPress={() => navigation.goBack()}
          disabled={signingOut}
        >
          <Box py={4} pr={4}>
            <CloseIcon />
          </Box>
        </Pressable>
      ),
    });
  }, [signingOut]);

  const signOut = useCallback(async () => {
    if (await nativeConfirm('Sign Out', 'Are you sure you want to sign out?')) {
      setSigningOut(true);
      dispatch(authActions.signOutUser({ currentDeviceId: currentDevice?.id, includeBrowserSessionLogout: true }));
    }
  }, [dispatch, currentDevice]);

  const year = useMemo(() => new Date().getFullYear(), []);

  if (signingOut) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <Text>Signing out...</Text>
        <ActivityIndicator color={colors.darkGrey} />
      </Stack>
    );
  }

  return (
    <Flex flex={1}>
      <VStack flex={1}>
        <Box mt={6} p={4} mb={4}>
          <MenuHeader onPress={() => navigation.navigate('Settings')} />
        </Box>
        <FullWidthSection mx={0}>
          <VStack>
            <ListItem onPress={() => navigation.navigate('Notifications')} mt={-2}>
              <IconTitle
                title={'Notifications'}
                mb={0}
                renderIcon={(color) => <Ionicons name="notifications-outline" color={color} size={20} />}
              />
            </ListItem>
            <ListItem onPress={() => openUrl(CareerCenterUrl)}>
              <IconTitle
                title={'Career Center'}
                mb={0}
                renderIcon={(color) => <Ionicons name="briefcase-outline" color={color} size={20} />}
              />
            </ListItem>
            <ListItem onPress={() => openUrl(alumnaeUrl)}>
              <IconTitle
                title={'Alumnae'}
                mb={0}
                renderIcon={(color) => <Ionicons name="briefcase-outline" color={color} size={20} />}
              />
            </ListItem>
            <ListItem onPress={() => navigation.navigate('Contacts')}>
              <IconTitle
                title={'Contacts'}
                mb={0}
                renderIcon={(color) => <Ionicons name="people-outline" color={color} size={20} />}
              />
            </ListItem>
            <ListItem onPress={() => navigation.navigate('StudentCEO')}>
              <IconTitle
                title={'Student CEO'}
                mb={0}
                renderIcon={(color) => <Ionicons name="ribbon-outline" color={color} size={20} />}
              />
            </ListItem>
            <ListItem onPress={() => navigation.navigate('About')}>
              <IconTitle
                title={'About RTSWS'}
                mb={0}
                renderIcon={(color) => <Ionicons name="information-circle-outline" color={color} size={20} />}
              />
            </ListItem>
            <ListItem onPress={() => openUrl(rtswsUrl)} borderBottomWidth={0} mb={-2}>
              <IconTitle
                title={'RTSWS Website'}
                mb={0}
                renderIcon={(color) => <Ionicons name="compass-outline" color={color} size={20} />}
              />
            </ListItem>
          </VStack>
        </FullWidthSection>
      </VStack>
      <VStack flex={0} flexBasis={Platform.OS === 'web' ? 'min-content' : undefined}>
        <FullWidthSection mx={0}>
          <VStack mb={8}>
            <ListItem iconName="log-out-outline" iconColor={colors.alert} mt={-2} onPress={() => signOut()}>
              <Text color={colors.alert}>Sign Out</Text>
            </ListItem>
            <ListItem mb={-2} hideIcon borderBottomWidth={0}>
              <Text color="gray.400" fontSize={12} fontStyle="italic">
                Copyright {year} Rock the Street, Wall Street
              </Text>
            </ListItem>
          </VStack>
        </FullWidthSection>
      </VStack>
    </Flex>
  );
};
