import { MaterialIcons } from '@expo/vector-icons';
import { Box, HStack, Icon, ScrollView, Text } from 'native-base';
import React, { useCallback, useMemo } from 'react';
import { useNotifications } from '../../hooks/data/useNotifications';
import { Notification } from '../../models/view';
import { colors } from '../../styles';
import { NoData } from '../NoData';
import { NotificationCard } from '../ui';

export interface NotificationsListProps {
  filter: 'read' | 'unread' | 'all';
  onNotificationPress: (notification: Notification) => void;
}

export const NotificationsList: React.FC<NotificationsListProps> = ({ onNotificationPress, filter }) => {
  const filterFn = useMemo(() => {
    switch (filter) {
      case 'all':
        return () => true;
      case 'read':
        return (notification: Notification) => !!notification.readAt;
      case 'unread':
        return (notification: Notification) => !notification.readAt;
    }
  }, [filter]);

  const { notifications } = useNotifications(filterFn);

  const content = useCallback((notification: Notification) => {
    return (
      <HStack justifyContent="space-between" w="100%">
        <Text fontSize={13} color={colors.darkGrey}>
          {notification.createdAt.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
          {' at '}
          {notification.createdAt.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
          })}
        </Text>
        {notification.urgency && <Icon as={MaterialIcons} name="error" color={colors.warning} />}
      </HStack>
    );
  }, []);

  if (notifications.length === 0) {
    return <NoData message="No notifications available." px={4} />;
  }

  return (
    <ScrollView contentContainerStyle={{ paddingBottom: 250 }}>
      {notifications.map((notification) => (
        <Box key={notification.id} mb={-2} px={4}>
          <NotificationCard
            onPress={() => onNotificationPress(notification)}
            title={notification.subject}
            content={content(notification)}
            isRead={!!notification.readAt}
          />
        </Box>
      ))}
    </ScrollView>
  );
};
