import { Device } from '../models/data';
import BaseService from './BaseService';

export class DeviceService extends BaseService {
  async getDevices() {
    return (await this.getJson<Device[]>('/devices')).data;
  }

  async createDevice(device: Partial<Device>) {
    return (await this.postJson<Device>('/devices', device)).data;
  }

  async deleteDevice(deviceId: string) {
    return (await this.deleteJson(`/devices/${deviceId}`)).data;
  }
}

export default new DeviceService();
