import { Box, Divider, FormControl, HStack, Heading, Text, VStack, WarningOutlineIcon } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import {
  useCurrentUser,
  useDateSelectOptions,
  useRaceSelectOptions,
  useSchoolAccountSelectOptions,
  useSponsorSelectOptions,
} from '../../hooks';
import { VolunteerReenrollmentData } from '../../store/registration/types';
import { nativeAlert } from '../../utils/ui';
import { volunteerReenrollmentFormSchema } from '../../validation/schemas/auth';
import { ValidationErrors } from '../../validation/types';
import { processValidationErrors } from '../../validation/utils';
import { Button } from '../ui';
import { CardContainer } from '../ui/cards/CardContainer';
import { Form, SelectInput, TextInput } from '../ui/forms';
import { CheckboxInput } from '../ui/forms/inputs/CheckboxInput';
import { FormProps } from '../ui/forms/types';

export interface VolunteerReenrollmentFormProps extends FormProps {
  onSubmit: (data: VolunteerReenrollmentData) => void;
}

export const VolunteerReenrollmentForm: React.FC<VolunteerReenrollmentFormProps> = React.memo(
  ({ disabled, loading, onSubmit }) => {
    const { user } = useCurrentUser();

    const { schoolAccountSelectOptions } = useSchoolAccountSelectOptions();
    const { sponsorSelectOptions } = useSponsorSelectOptions();
    const { dateOptions, monthOptions, yearOptions } = useDateSelectOptions();
    const { raceSelectOptions } = useRaceSelectOptions();

    const [schoolAccountSfid, setSchoolAccountSfid] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirthMonth, setDateOfBirthMonth] = useState('');
    const [dateOfBirthDay, setDateOfBirthDay] = useState('');
    const [dateOfBirthYear, setDateOfBirthYear] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(user?.registration?.dateOfBirth || '');
    const [race, setRace] = useState(user?.registration?.race || '');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [collegeAttended, setCollegeAttended] = useState(user?.registration?.collegeAttended || '');
    const [designation, setDesignation] = useState(user?.registration?.designation || '');
    const [hundredWomenInFinance, setHundredWomenInFinance] = useState(
      user?.registration?.hundredWomenInFinance || false,
    );

    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

    useEffect(() => {
      if (dateOfBirthDay && dateOfBirthMonth && dateOfBirthYear) {
        const dateString = `${dateOfBirthYear}-${dateOfBirthMonth.padStart(2, '0')}-${dateOfBirthDay.padStart(2, '0')}`;
        setDateOfBirth(dateString);
      }
    }, [dateOfBirthDay, dateOfBirthMonth, dateOfBirthYear]);

    const handleSubmit = useCallback(async () => {
      const data: VolunteerReenrollmentData = {
        schoolAccountSfid,
        firstName,
        lastName,
        dateOfBirth,
        race,
        company,
        title,
        collegeAttended,
        designation,
        hundredWomenInFinance,
      };

      try {
        setValidationErrors({});
        const validated = volunteerReenrollmentFormSchema.validateSync(data, { abortEarly: false });
        onSubmit(validated);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          if (err instanceof ValidationError) {
            setValidationErrors(processValidationErrors(err));
            nativeAlert('Validation Error', 'Please check the form for errors.');
          } else {
            throw err;
          }
        }
      }
    }, [
      onSubmit,
      schoolAccountSfid,
      firstName,
      lastName,
      dateOfBirth,
      race,
      company,
      title,
      collegeAttended,
      designation,
      hundredWomenInFinance,
    ]);

    return (
      <Form>
        <Box p={4}>
          <Heading mb={4}>Volunteer Registration</Heading>
          <CardContainer>
            <Box mb={8}>
              <Text>
                Fill out the form below to reenroll in the Rock The Street, Wall Street program for the upcoming school
                year.
              </Text>
            </Box>
            <Divider mb={8} />
            <VStack mb={4}>
              <SelectInput
                label="School"
                value={schoolAccountSfid}
                options={schoolAccountSelectOptions}
                onChange={setSchoolAccountSfid}
                disabled={disabled}
                loading={loading}
                error={validationErrors.schoolAccountSfid}
              />
              <TextInput
                value={firstName}
                onChange={setFirstName}
                label="First Name"
                disabled={disabled}
                loading={loading}
                error={validationErrors.firstName}
              />
              <TextInput
                value={lastName}
                onChange={setLastName}
                label="Last Name"
                disabled={disabled}
                loading={loading}
                error={validationErrors.lastName}
              />
              {!user?.registration?.dateOfBirth ? (
                <FormControl isInvalid={!!validationErrors.dateOfBirth}>
                  <FormControl.Label>Date of birth</FormControl.Label>
                  <HStack space={2}>
                    <Box flex={1.5}>
                      <SelectInput
                        label="Month"
                        placeholder="Month"
                        value={dateOfBirthMonth}
                        options={monthOptions}
                        onChange={setDateOfBirthMonth}
                        disabled={disabled}
                        loading={loading}
                        error={validationErrors.dateOfBirthMonth}
                      />
                    </Box>
                    <Box flex={1.25}>
                      <SelectInput
                        label="Date"
                        placeholder="Date"
                        value={dateOfBirthDay}
                        options={dateOptions}
                        onChange={setDateOfBirthDay}
                        disabled={disabled}
                        loading={loading}
                        error={validationErrors.dateOfBirthDay}
                      />
                    </Box>
                    <Box flex={1.25}>
                      <SelectInput
                        label="Year"
                        placeholder="Year"
                        value={dateOfBirthYear}
                        options={yearOptions}
                        onChange={setDateOfBirthYear}
                        disabled={disabled}
                        loading={loading}
                        error={validationErrors.dateOfBirthYear}
                      />
                    </Box>
                  </HStack>
                  <Box mt={-2} mb={2}>
                    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                      {validationErrors.dateOfBirth}
                    </FormControl.ErrorMessage>
                  </Box>
                </FormControl>
              ) : null}
              {!user?.registration?.race ? (
                <SelectInput
                  label="Race"
                  value={race}
                  options={raceSelectOptions}
                  onChange={setRace}
                  disabled={disabled}
                  loading={loading}
                  error={validationErrors.race}
                />
              ) : null}
              <SelectInput
                label="Firm (Company)"
                value={company}
                options={sponsorSelectOptions}
                onChange={setCompany}
                disabled={disabled}
                loading={loading}
                error={validationErrors.company}
              />
              <TextInput
                value={title}
                onChange={setTitle}
                label="Title"
                disabled={disabled}
                loading={loading}
                error={validationErrors.title}
              />
              {!user?.registration?.collegeAttended ? (
                <TextInput
                  value={collegeAttended}
                  onChange={setCollegeAttended}
                  label="Undergraduate University"
                  disabled={disabled}
                  loading={loading}
                  error={validationErrors.collegeAttended}
                />
              ) : null}
              {!user?.registration?.designation ? (
                <TextInput
                  value={designation}
                  onChange={setDesignation}
                  label="Designations (CFA/CFP/CAIA, etc)"
                  disabled={disabled}
                  loading={loading}
                  error={validationErrors.designation}
                />
              ) : null}
              {!user?.registration?.hundredWomenInFinance ? (
                <CheckboxInput
                  value={hundredWomenInFinance}
                  onChange={setHundredWomenInFinance}
                  label="100 Women in Finance"
                  hideLabel
                  disabled={disabled}
                  loading={loading}
                  error={validationErrors.hundredWomenInFinance}
                />
              ) : null}
            </VStack>
            <Button onPress={handleSubmit} disabled={disabled} loading={loading} text="Re-enroll Now" color="primary" />
          </CardContainer>
        </Box>
      </Form>
    );
  },
);
