import { Box } from 'native-base';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { isWeb, MAX_WEB_CONTAINER_WIDTH } from '../../utils';

export interface PageWrapperProps extends React.PropsWithChildren {
  containerStyle?: StyleProp<ViewStyle>;
}

export const PageWrapper: React.FC<PageWrapperProps> = ({ children, containerStyle }) => {
  return (
    <Box alignItems={isWeb ? 'center' : undefined} width="100%" height="100%" style={containerStyle}>
      <Box width="100%" height="100%" maxWidth={isWeb ? MAX_WEB_CONTAINER_WIDTH : undefined}>
        {children}
      </Box>
    </Box>
  );
};
