import { CommonActions } from '@react-navigation/native';
import { Box, ScrollView, Text, VStack } from 'native-base';
import React, { useCallback, useEffect } from 'react';
import { Button, PageWrapper, Section } from '../../components/ui';
import { CardContainer } from '../../components/ui/cards/CardContainer';
import { MenuStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActions } from '../../store/actions';
import { AuthStatus } from '../../store/auth/types';
import { nativeAlert } from '../../utils/ui';

export const DeleteAccountScreen: React.FC<MenuStackScreenWithNavProps<'DeleteAccount'>> = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const { currentDevice } = useAppSelector((state) => state.device);
  const { error: authError, loading: authLoading, status: authStatus } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (authError) {
      const error = authError;
      nativeAlert('Delete Account Error', `Could not delete your user account: ${error}`);
      dispatch(authActions.updateError({ error: null }));
    }
  }, [authError]);

  useEffect(() => {
    if (authStatus === AuthStatus.UNAUTHENTICATED) {
      navigation.dispatch(CommonActions.reset({ index: 0, routes: [{ name: 'Start' }] }));
    }
  }, [authStatus]);

  const confirmDelete = useCallback(() => {
    dispatch(authActions.deleteUser({ currentDeviceId: currentDevice?.id }));
  }, [currentDevice]);

  return (
    <ScrollView>
      <PageWrapper>
        <Box p={4}>
          <Section title="Confirm Delete" hideIcon>
            <CardContainer>
              <VStack space={4}>
                <Text>
                  After pressing "Confirm" below, we will mark your account for deletion and log you out of the app. You
                  will not be able to log back in, and you will have to register a new account to use the app in the
                  future.
                </Text>
                <Text>Would you like to continue deleting your account?</Text>
                <Box>
                  <Button
                    text="Confirm"
                    color="danger"
                    onPress={confirmDelete}
                    disabled={authLoading}
                    loading={authLoading}
                  />
                </Box>
              </VStack>
            </CardContainer>
          </Section>
        </Box>
      </PageWrapper>
    </ScrollView>
  );
};
