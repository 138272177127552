import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Student } from '../../models/data';
import { studentService } from '../../services';
import { commonActions } from '../commonActions';
import { StudentState } from './types';

const getStudent = createAsyncThunk<Student, { id: string }>('students/getStudent', async ({ id }) => {
  return studentService.getStudent(id);
});

const getStudents = createAsyncThunk('students/getStudents', async () => {
  return studentService.getStudents();
});

const initialState: StudentState = {
  currentStudent: {},
  students: [],
  loading: false,
  error: null,
};

export const studentSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setStudent(state, { payload: { student } }: PayloadAction<{ student: Student }>) {
      state.currentStudent = student;
    },

    setStudents(state, { payload: { students } }: PayloadAction<{ students: Student[] }>) {
      state.students = students;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStudents.fulfilled, (state, action) => {
        state.loading = false;
        state.students = action.payload;
      })
      .addCase(getStudents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load students.';
      });

    builder
      .addCase(getStudent.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStudent.fulfilled, (state, action) => {
        state.loading = false;
        state.currentStudent = action.payload;
      })
      .addCase(getStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load students.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });
  },
});

export const studentActions = { ...studentSlice.actions, getStudent, getStudents };

export type StudentSlice = {
  [studentSlice.name]: ReturnType<typeof studentSlice['reducer']>;
};
