import * as WebBrowser from 'expo-web-browser';
import { Linking, Platform } from 'react-native';
import { Campaign, Setting } from '../models/data';
import { colors } from '../styles';
import { nativeAlert } from './ui';

export const MAX_WEB_CONTAINER_WIDTH = 1200;
export const SMALL_SCREEN_SIZE_BREAKPOINT = 768;
export const isWeb = Platform.OS === 'web';
export const DRAWER_WIDTH = 240;
export const DRAWER_VISIBILITY_BREAKPOINT = SMALL_SCREEN_SIZE_BREAKPOINT;

export function openMaps(address: string) {
  let baseUrl = 'http://maps.google.com/maps?daddr=';

  if (Platform.OS === 'ios') {
    baseUrl = 'http://maps.apple.com/maps?daddr=';
  }

  Linking.openURL(`${baseUrl}${encodeURIComponent(address)}`).catch(() => {
    nativeAlert('Maps Error', 'Unable to open address in mapping application.');
  });
}

export function openPhone(phone: string) {
  Linking.openURL(`tel:${phone}`).catch(() => {
    nativeAlert('Dialing Error', 'Unable to initiate phone call.');
  });
}

export function openEmail(email: string) {
  Linking.openURL(`mailto:${email}?subject=Hello from Rock The Street, Wall Street`).catch(() => {
    nativeAlert('Email Error', 'Unable to launch email editor.');
  });
}

export function openUrl(url: string) {
  if (Platform.OS === 'web') {
    Linking.openURL(url);
  } else {
    WebBrowser.openBrowserAsync(url, {
      controlsColor: colors.primary,
    }).catch(() => {
      nativeAlert('Browser Error', 'Unable to open URL in browser.');
    });
  }
}

export function getValueForSlug(settings: Setting[], slug: string): string {
  const matches = settings.filter((setting) => {
    return setting.slug === slug;
  });

  return matches.length > 0 ? matches[0].value : '';
}

export const asyncDelay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const generateShortUUID = () => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let uuid = '';

  for (let i = 0; i < 6; i++) {
    uuid += chars[Math.floor(Math.random() * chars.length)];
  }

  return uuid;
};
