import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: APP_ENV,
  enableInExpoDevelopment: true,
  debug: SENTRY_DEBUG, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

import 'react-native-gesture-handler';

import { APP_ENV, SENTRY_DEBUG, SENTRY_DSN } from '@env';
import { NavigationContainerRef } from '@react-navigation/native';
import { useFonts } from 'expo-font';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import { NativeBaseProvider } from 'native-base';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AppState, AppStateStatus, View } from 'react-native';
import { Provider } from 'react-redux';
import { DialogProvider } from './src/contexts';
import { useImages } from './src/hooks';
import { useDebugMenu } from './src/hooks/useDebugMenu';
import { Nav, RootStackParamList } from './src/navigation';
import { eventBus, store, StoreEvents } from './src/store';
import { notificationActions } from './src/store/actions';
import { signOutUser } from './src/store/auth/slice';
import { theme } from './src/styles';
import { nativeAlert } from './src/utils/ui';

SplashScreen.preventAutoHideAsync();

export default function App() {
  const appState = useRef(AppState.currentState);
  const navRef = useRef<NavigationContainerRef<RootStackParamList>>(null);

  useDebugMenu(navRef);

  const [fontsLoaded] = useFonts({
    'Avenir-Regular': require('./assets/fonts/AvenirNextLTPro-Regular.otf'),
    'Avenir-Bold': require('./assets/fonts/AvenirNextLTPro-Bold.otf'),
    'Avenir-Italic': require('./assets/fonts/Avenir-MediumOblique.ttf'),
    'Avenir-Bold-Italic': require('./assets/fonts/Avenir-HeavyOblique.ttf'),
  });

  const { ready: imagesReady } = useImages();

  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const [shouldLogOut, setShouldLogOut] = useState(false);
  const [loggingOutInProgress, setLoggingOutInProgress] = useState(false);

  const handleAppStateChange = useCallback((nextAppState: AppStateStatus) => {
    if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
      console.log('App has come to the foreground!');
      const currentUser = store.getState().auth.currentUser;

      if (currentUser) {
        store.dispatch(notificationActions.getNotifications());
      }
    }

    appState.current = nextAppState;
    setAppStateVisible(appState.current);
  }, []);

  // App State
  useEffect(() => {
    const subscription = AppState.addEventListener('change', handleAppStateChange);
    return () => {
      subscription.remove();
    };
  }, []);

  // Notifications
  useEffect(() => {
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
      }),
    });

    const receivedSubscription = Notifications.addNotificationReceivedListener((notification) => {
      console.log('Received Notification', notification);

      const currentUser = store.getState().auth.currentUser;

      if (currentUser) {
        store.dispatch(notificationActions.getNotifications());
      }
    });

    const responseSubscription = Notifications.addNotificationResponseReceivedListener((response) => {
      console.log('Received response', response);
      const data = response.notification.request.content.data;
      const userNotificationId = data?.userNotificationId as string;
      if (userNotificationId) {
        navRef.current?.navigate('Notification', { id: userNotificationId });
      }
    });

    return () => {
      receivedSubscription.remove();
      responseSubscription.remove();
    };
  }, []);

  // Store
  useEffect(() => {
    const actionHandler = ({ action }: any) => {
      // console.log('App: actionHandler: action: ', action.type, action, navRef.current);
      if (!String(action.type).endsWith('rejected')) {
        return;
      }

      if (!String(action.error?.message).endsWith('401')) {
        return;
      }

      if (shouldLogOut) {
        return;
      }

      setShouldLogOut(true);
    };

    eventBus.on(StoreEvents.ACTION, actionHandler);

    return () => {
      eventBus.off(StoreEvents.ACTION, actionHandler);
    };
  }, []);

  useEffect(() => {
    if (loggingOutInProgress) {
      return;
    }

    if (shouldLogOut) {
      const perform = async () => {
        const currentDevice = store.getState().device.currentDevice;
        store.dispatch(signOutUser({ currentDeviceId: currentDevice?.id, includeBrowserSessionLogout: false }));
        await nativeAlert('Session Expired', 'Your session has expired. Please sign in again to continue.');

        setShouldLogOut(false);
        setLoggingOutInProgress(false);
      };
      setLoggingOutInProgress(true);
      perform();
    }
  }, [shouldLogOut]);

  const onLayoutRootView = useCallback(async () => {
    console.log('Fonts loaded?', fontsLoaded);
    console.log('Images loaded?', imagesReady);

    if (fontsLoaded && imagesReady) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded, imagesReady]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View onLayout={onLayoutRootView} style={{ width: '100%', height: '100%' }}>
      <Provider store={store}>
        <NativeBaseProvider theme={theme}>
          <DialogProvider>
            <Nav navRef={navRef} />
          </DialogProvider>
        </NativeBaseProvider>
      </Provider>
    </View>
  );
}
