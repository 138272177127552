import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userService } from '../../services';
import { authActions } from '../auth/slice';
import { commonActions } from '../commonActions';
import { State } from '../types';
import {
  ReenrollmentData,
  RegistrationData,
  RegistrationState,
  VolunteerReenrollmentData,
  VolunteerRegistrationData,
} from './types';

const submitRegistration = createAsyncThunk(
  'registrations/submitRegistration',
  async ({ registrationData }: { registrationData: RegistrationData | VolunteerRegistrationData }, thunkAPI) => {
    const { currentUser } = (thunkAPI.getState() as State).auth;

    if (!currentUser) {
      throw new Error('No current user');
    }

    await userService.submitRegistration(registrationData, currentUser);
    return thunkAPI.dispatch(authActions.fetchUser());
  },
);

const submitSpringEnrollment = createAsyncThunk('registrations/submitSpringEnrollment', async (_, thunkAPI) => {
  const { currentUser } = (thunkAPI.getState() as State).auth;

  if (!currentUser) {
    throw new Error('No current user');
  }

  await userService.submitSpringEnrollment(currentUser);
  return thunkAPI.dispatch(authActions.fetchUser());
});

const submitReenrollment = createAsyncThunk(
  'registrations/submitReenrollment',
  async ({ data }: { data: ReenrollmentData | VolunteerReenrollmentData }, thunkAPI) => {
    const { currentUser } = (thunkAPI.getState() as State).auth;

    if (!currentUser) {
      throw new Error('No current user');
    }

    await userService.submitReenrollment(currentUser, data);

    return thunkAPI.dispatch(authActions.fetchUser());
  },
);

const initialState: RegistrationState = {
  registrationData: null,
  volunteerRegistrationData: null,
  loading: false,
  error: null,
  registrationSubmitted: false,
};

export const registrationSlice = createSlice({
  name: 'registrations',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setRegistration(state, { payload: { registrationData } }: PayloadAction<{ registrationData: RegistrationData }>) {
      state.registrationData = registrationData;
    },

    setVolunteerRegistration(
      state,
      {
        payload: { volunteerRegistrationData },
      }: PayloadAction<{ volunteerRegistrationData: VolunteerRegistrationData }>,
    ) {
      state.volunteerRegistrationData = volunteerRegistrationData;
    },

    updateRegistrationSubmitted(
      state,
      { payload: { registrationSubmitted } }: PayloadAction<{ registrationSubmitted: boolean }>,
    ) {
      state.registrationSubmitted = registrationSubmitted;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitRegistration.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(submitRegistration.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(submitRegistration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to submit registration.';
      });

    builder
      .addCase(submitSpringEnrollment.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(submitSpringEnrollment.fulfilled, (state) => {
        state.loading = false;
        state.registrationSubmitted = true;
      })
      .addCase(submitSpringEnrollment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to submit spring enrollment.';
      });

    builder
      .addCase(submitReenrollment.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(submitReenrollment.fulfilled, (state) => {
        state.loading = false;
        state.registrationSubmitted = true;
      })
      .addCase(submitReenrollment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to submit reenrollment.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });
  },
});

export const registrationActions = {
  ...registrationSlice.actions,
  submitRegistration,
  submitReenrollment,
  submitSpringEnrollment,
};

export type RegistrationSlice = {
  [registrationSlice.name]: ReturnType<typeof registrationSlice['reducer']>;
};
