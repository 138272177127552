import { SettingSlugs } from '../../constants';
import { ImageAssetName, useImages, useSettings } from '../../hooks';
import { openUrl } from '../../utils';
import { Card } from '../ui';

export interface CareerCenterListListProps {}

export const CareerCenterList: React.FC<CareerCenterListListProps> = () => {
  const { findImageAssetByName } = useImages();
  const { getSettingValueByName } = useSettings();

  const CareerCenterUrl = getSettingValueByName(SettingSlugs.RTSWS_JOBS_URL);
  const image = findImageAssetByName(ImageAssetName.CAREER_CENTER);

  return (
    <Card
      title="Career Center"
      imageUrl={image?.localUri}
      imageAlt="Career Center"
      content="View internship and job opportunities from top finance firms around the world."
      actionText="See Job Opportunities"
      actionColor="primary"
      onActionPress={() => openUrl(CareerCenterUrl)}
    />
  );
};
