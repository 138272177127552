import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { accountService } from '../../services';
import { AccountState } from './types';

const getSchoolAccounts = createAsyncThunk('accounts/getSchoolAccounts', async () => {
  return accountService.getSchoolAccounts();
});

const getSponsorAccounts = createAsyncThunk('accounts/getSponsorAccounts', async () => {
  return accountService.getSponsorAccounts();
});

const initialState: AccountState = {
  schoolAccounts: [],
  sponsorAccounts: [],
  loading: false,
  error: null,
};

export const accountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setSchoolAccounts(state, { payload: { schoolAccounts } }: PayloadAction<{ schoolAccounts: [] }>) {
      state.schoolAccounts = schoolAccounts;
    },

    setSponsorAccounts(state, { payload: { sponsorAccounts } }: PayloadAction<{ sponsorAccounts: [] }>) {
      state.sponsorAccounts = sponsorAccounts;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSchoolAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolAccounts = action.payload;
      })
      .addCase(getSchoolAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load schools.';
      });

    builder
      .addCase(getSponsorAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSponsorAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.sponsorAccounts = action.payload;
      })
      .addCase(getSponsorAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load sponsors.';
      });
  },
});

export const accountActions = { ...accountSlice.actions, getSchoolAccounts, getSponsorAccounts };

export type AccountSlice = {
  [accountSlice.name]: ReturnType<typeof accountSlice['reducer']>;
};
