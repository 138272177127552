import { Box, Center, HStack, Pressable } from 'native-base';
import React, { useMemo } from 'react';
import { Dimensions } from 'react-native';
import { SettingSlugs } from '../../constants';
import { useSettings } from '../../hooks';
import { openUrl } from '../../utils';
import { Section } from '../ui';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  SnapchatIcon,
  ThreadsIcon,
  TikTokIcon,
  TwitterIcon,
  YouTubeIcon,
} from '../ui/icons';

interface SocialMediaLink {
  id: string;
  url: string;
  icon: React.ReactNode;
}

export interface SocialMediaLinksProps {}

export const SocialMediaLinks: React.FC<SocialMediaLinksProps> = () => {
  const { getSettingValueByName } = useSettings();

  const facebookUrl = useMemo(() => {
    return getSettingValueByName(SettingSlugs.FACEBOOK_URL);
  }, [getSettingValueByName]);

  const instagramUrl = useMemo(() => {
    return getSettingValueByName(SettingSlugs.INSTAGRAM_URL);
  }, [getSettingValueByName]);

  const twitterUrl = useMemo(() => {
    return getSettingValueByName(SettingSlugs.TWITTER_URL);
  }, [getSettingValueByName]);

  const youtubeUrl = useMemo(() => {
    return getSettingValueByName(SettingSlugs.YOUTUBE_URL);
  }, [getSettingValueByName]);

  const tiktokUrl = useMemo(() => {
    return getSettingValueByName(SettingSlugs.TIKTOK_URL);
  }, [getSettingValueByName]);

  const linkedInUrl = useMemo(() => {
    return getSettingValueByName(SettingSlugs.LINKEDIN_URL);
  }, [getSettingValueByName]);

  const snapChatUrl = useMemo(() => {
    return getSettingValueByName(SettingSlugs.SNAPCHAT_URL);
  }, [getSettingValueByName]);

  const threadsUrl = useMemo(() => {
    return getSettingValueByName(SettingSlugs.THREADS_URL);
  }, [getSettingValueByName]);

  const socialMediaLinks = useMemo(() => {
    const links: SocialMediaLink[] = [];
    const defaultSize = 48;

    if (facebookUrl) {
      links.push({
        id: 'facebook',
        url: facebookUrl,
        icon: <FacebookIcon size={defaultSize} />,
      });
    }

    if (instagramUrl) {
      links.push({
        id: 'instagram',
        url: instagramUrl,
        icon: <InstagramIcon size={defaultSize} />,
      });
    }

    if (twitterUrl) {
      links.push({
        id: 'twitter',
        url: twitterUrl,
        icon: <TwitterIcon size={defaultSize} />,
      });
    }

    if (youtubeUrl) {
      links.push({
        id: 'youtube',
        url: youtubeUrl,
        icon: <YouTubeIcon size={defaultSize} />,
      });
    }

    if (tiktokUrl) {
      links.push({
        id: 'tiktok',
        url: tiktokUrl,
        icon: <TikTokIcon size={defaultSize} />,
      });
    }

    if (linkedInUrl) {
      links.push({
        id: 'linkedin',
        url: linkedInUrl,
        icon: <LinkedInIcon size={defaultSize} />,
      });
    }

    if (snapChatUrl) {
      links.push({
        id: 'snapchat',
        url: snapChatUrl,
        icon: <SnapchatIcon size={defaultSize} />,
      });
    }

    if (threadsUrl) {
      links.push({
        id: 'threads',
        url: threadsUrl,
        icon: <ThreadsIcon size={36} color="black" />,
      });
    }

    return links;
  }, [facebookUrl, instagramUrl, twitterUrl, youtubeUrl, linkedInUrl, snapChatUrl, threadsUrl]);

  const windowWidth = Dimensions.get('window').width;
  const iconContainerWidth = windowWidth < 500 ? '33%' : '12%';
  return (
    <Section title="Social Media" hideIcon centerTitle>
      <HStack justifyContent="space-evenly" flexWrap="wrap">
        {socialMediaLinks.map((link) => (
          <Box key={link.id} width={iconContainerWidth} mt="5" alignItems="center">
            <Pressable onPress={() => openUrl(link.url)}>
              <Center h="64px" w="64px" background="white" borderRadius={10}>
                {link.icon}
              </Center>
            </Pressable>
          </Box>
        ))}
      </HStack>
    </Section>
  );
};
