import { configureStore } from '@reduxjs/toolkit';
import EventEmitter from 'events';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { accountSlice } from './account/slice';
import { appSlice } from './app/slice';
import { authSlice } from './auth/slice';
import { campaignSlice } from './campaign/slice';
import { contactSlice } from './contact/slice';
import monitorReducerEnhancer from './enhancers/monitorReducer';
import { gallerySlice } from './gallery/slice';
import { notificationSlice } from './notification/slice';
import { programEventSlice } from './programEvent/slice';
import { registrationSlice } from './registration/slice';
import { resourceSlice } from './resource/slice';
import { settingSlice } from './setting/slice';
import { studentSlice } from './student/slice';
// import crossResourceActionHandler from './mediator';
import { deviceSlice } from './device/slice';
import { feedSlice } from './feed/slice';
import appMiddleware from './middleware';

export const eventBus = new EventEmitter();

export enum StoreEvents {
  ACTION = 'action',
}

export const store = configureStore({
  reducer: {
    [appSlice.name]: appSlice.reducer,
    [accountSlice.name]: accountSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [campaignSlice.name]: campaignSlice.reducer,
    [contactSlice.name]: contactSlice.reducer,
    [deviceSlice.name]: deviceSlice.reducer,
    [feedSlice.name]: feedSlice.reducer,
    [gallerySlice.name]: gallerySlice.reducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [programEventSlice.name]: programEventSlice.reducer,
    [registrationSlice.name]: registrationSlice.reducer,
    [resourceSlice.name]: resourceSlice.reducer,
    [settingSlice.name]: settingSlice.reducer,
    [studentSlice.name]: studentSlice.reducer,
  },
  enhancers: [monitorReducerEnhancer],
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();

    if (process.env.NODE_ENV === 'development') {
      middleware.push(appMiddleware.logger);
    }

    middleware.push((store) => (next) => (action) => {
      const result = next(action);
      eventBus.emit(StoreEvents.ACTION, { action });
      return result;
    });

    return middleware;
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
