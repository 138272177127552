import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contact } from '../../models/data';
import { contactService } from '../../services';
import { commonActions } from '../commonActions';
import { ContactState } from './types';

const getContacts = createAsyncThunk('contacts/getContacts', async () => {
  return contactService.getContacts();
});

const initialState: ContactState = {
  currentContact: null,
  contacts: [],
  loading: false,
  error: null,
};

export const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setContacts(state, { payload: { contacts } }: PayloadAction<{ contacts: Contact[] }>) {
      state.contacts = contacts;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load contacts.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });
  },
});

export const contactActions = { ...contactSlice.actions, getContacts };

export type ContactSlice = {
  [contactSlice.name]: ReturnType<typeof contactSlice['reducer']>;
};
