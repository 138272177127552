import { VStack } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import { useAppSelector } from '../../store';
import { nativeAlert } from '../../utils/ui';
import { profileFormSchema } from '../../validation/schemas/menu';
import { ValidationErrors } from '../../validation/types';
import { processValidationErrors } from '../../validation/utils';
import { Button } from '../ui';
import { Form, TextInput } from '../ui/forms';
import { FormProps } from '../ui/forms/types';

export interface ProfileFormData {
  firstName: string;
  lastName: string;
  mobilePhone: string;
}

export interface ProfileFormProps extends FormProps {
  onSubmit: (data: ProfileFormData) => void;
}

export const ProfileForm: React.FC<ProfileFormProps> = ({ loading, disabled, onSubmit }) => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.firstName);
      setLastName(currentUser.lastName);
      setMobilePhone(currentUser.contact?.mobilePhone ?? '');
    }
  }, [currentUser]);

  const handleSubmit = useCallback(async () => {
    try {
      setValidationErrors({});
      const validated = profileFormSchema.validateSync({ firstName, lastName, mobilePhone }, { abortEarly: false });
      onSubmit(validated);
    } catch (err: any) {
      if (err instanceof ValidationError) {
        setValidationErrors(processValidationErrors(err));
        nativeAlert('Validation Error', 'Please check the form for errors.');
      } else {
        throw err;
      }
    }
  }, [onSubmit, firstName, lastName, mobilePhone]);

  return (
    <Form hasNavBar={false} extraScrollHeight={-96} resetOnBlur>
      <VStack>
        <TextInput
          value={firstName}
          onChange={(newValue) => setFirstName(newValue)}
          label="First Name"
          disabled={disabled}
          loading={loading}
          error={validationErrors.firstName}
        />
        <TextInput
          value={lastName}
          onChange={(newValue) => setLastName(newValue)}
          label="Last Name"
          disabled={disabled}
          loading={loading}
          error={validationErrors.lastName}
        />
        {/* TODO: need phone input */}
        <TextInput
          value={mobilePhone}
          onChange={(newValue) => setMobilePhone(newValue)}
          label="Mobile Phone"
          disabled={disabled}
          loading={loading}
          error={validationErrors.mobilePhone}
        />
        <Button onPress={() => handleSubmit()} loading={loading} disabled={disabled} text="Continue" color="primary" />
      </VStack>
    </Form>
  );
};
