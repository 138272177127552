import React from 'react';
import { Card } from './Card';
import { CardActionProps } from './CardAction';

export type LinkCardProps = CardActionProps & {
  title: string;
  url: string;
};

export const LinkCard: React.FC<LinkCardProps> = ({ title, url, ...restProps }) => {
  return <Card title={title} subtitle={url} actionLink={url} {...restProps} />;
};
