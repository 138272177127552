import { Box, Heading, Image, ScrollView, Text, VStack } from 'native-base';
import React from 'react';
import { AboutText } from '../../components/AboutText';
import { Button, PageWrapper } from '../../components/ui';
import { SettingSlugs } from '../../constants';
import { ImageAssetName, useImages, useSettings } from '../../hooks';
import { MenuStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { openUrl } from '../../utils';

export const AboutScreen: React.FC<MenuStackScreenWithNavProps<'About'>> = () => {
  const { getSettingValueByName } = useSettings();
  const { findImageAssetByName } = useImages();

  const rtswsUrl = getSettingValueByName(SettingSlugs.RTSWS_HOME_URL);
  const alumnaeUrl = getSettingValueByName(SettingSlugs.LINKEDIN_ALUMNI_URL);
  const donateUrl = getSettingValueByName(SettingSlugs.RTSWS_DONATE_URL);

  const image1 = findImageAssetByName(ImageAssetName.ABOUT_1);
  const image2 = findImageAssetByName(ImageAssetName.ABOUT_2);

  return (
    <ScrollView backgroundColor="white">
      <PageWrapper>
        <Box p={4}>
          <Box mb={4}>
            {image1?.localUri && (
              <Image
                borderRadius={10}
                style={{ aspectRatio: 3 / 2, width: '100%', height: undefined }}
                source={{ uri: image1?.localUri }}
                alt={'Large group of girls in a RTSWS session'}
              />
            )}
          </Box>
          <Box mb={4}>
            <AboutText />
          </Box>
          <Box mb={4}>
            {image2?.localUri && (
              <Image
                borderRadius={10}
                style={{ aspectRatio: 3 / 2, width: '100%', height: undefined }}
                source={{ uri: image2?.localUri }}
                alt={'Girls holding RTSWS shirts'}
              />
            )}
          </Box>
          <Box mb={4}>
            <Heading mb={4} size="sm">
              RTSWS has 4 components:
            </Heading>
            <VStack space={2}>
              <Text>1. Financial & Investment Literacy Workshops (Fall)</Text>
              <Text>2. Wall Street Experience Field Trip (Fall)</Text>
              <Text>3. Mentoring (Spring)</Text>
              <Text>4. Alumnae & Career Center</Text>
            </VStack>
          </Box>
          <VStack space={2}>
            <Button text={'RTSWS Alumnae'} onPress={() => openUrl(alumnaeUrl)} />
            <Button text={'Visit Our Website'} onPress={() => openUrl(rtswsUrl)} color="primary" />
            <Button text={'Donate to RTSWS'} onPress={() => openUrl(donateUrl)} color="secondary" />
          </VStack>
        </Box>
      </PageWrapper>
    </ScrollView>
  );
};
