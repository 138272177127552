import { Box, VStack } from 'native-base';
import React from 'react';
import { ActivityIndicator, Image } from 'react-native';
import { ImageAssetName, useImages } from '../hooks';
import { colors } from '../styles';

export interface SplashScreenProps {}

export const SplashScreen: React.FC<SplashScreenProps> = (props) => {
  const { findImageAssetByName } = useImages();

  const splashImage = findImageAssetByName(ImageAssetName.SPLASH);

  return (
    <VStack alignItems="center" justifyContent="center" flex={1}>
      <Box position="absolute" width="100%" height="100%" backgroundColor="white">
        {splashImage?.localUri && (
          <Image
            source={{ uri: splashImage?.localUri }}
            style={{ width: '100%', resizeMode: 'contain', height: '100%' }}
          />
        )}
      </Box>
      <Box position="absolute" top="75%">
        <ActivityIndicator color={colors.darkGrey} />
      </Box>
    </VStack>
  );
};
