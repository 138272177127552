import { Alert, Platform } from 'react-native';

export const nativeConfirm = async (title: string, message: string) => {
  if (Platform.OS === 'web') {
    return window.confirm(`${title}\n\n${message}`);
  }

  return new Promise((resolve) => {
    Alert.alert(title, message, [
      {
        text: 'Cancel',
        style: 'cancel',
        onPress: () => resolve(false),
      },
      {
        text: 'Okay',
        style: 'default',
        onPress: () => resolve(true),
      },
    ]);
  });
};

export const nativeAlert = async (title: string, message: string) => {
  if (Platform.OS === 'web') {
    return window.alert(`${title}\n\n${message}`);
  }

  return new Promise((resolve) => {
    Alert.alert(title, message, [
      {
        text: 'Okay',
        style: 'default',
        onPress: () => resolve(true),
      },
    ]);
  });
};

export const uiNotYetImplemented = () => {
  return nativeAlert('Not yet implemented', 'This feature is not yet implemented. Please check back later.');
};
