import { Box, Heading, ScrollView, Text, VStack } from 'native-base';
import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import { Button, PageWrapper } from '../../components/ui';
import { CardContainer } from '../../components/ui/cards/CardContainer';
import { useRegisterForNotifications, useRegisterForSmsNotifications } from '../../hooks';
import { AppStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActions } from '../../store/actions';
import { colors } from '../../styles';

export const NotificationsOnboardScreen: React.FC<AppStackScreenWithNavProps<'NotificationsOnboard'>> = ({
  navigation,
  route,
}) => {
  const { continueToSmsOnboard } = route.params;

  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const { registerForNotifications, markAsDone } = useRegisterForNotifications();
  const { markAsDone: markSmsAsDone } = useRegisterForSmsNotifications();

  const navToHome = useCallback(() => navigation.navigate((Platform.OS === 'web' ? 'Drawer' : 'Tabs') as any), []);
  const navToSmsOnboard = useCallback(() => navigation.navigate('SmsNotificationsOnboard'), []);

  const navToNext = useCallback(() => {
    if (continueToSmsOnboard) {
      navToSmsOnboard();
    } else {
      markSmsAsDone();
      navToHome();
    }
  }, [continueToSmsOnboard, markSmsAsDone, navToHome, navToSmsOnboard]);

  const decline = useCallback(async () => {
    await markAsDone();

    if (currentUser) {
      await dispatch(
        authActions.updateCurrentUser({
          ...currentUser,
          receivePushNotifications: false,
        }),
      ).unwrap();
    }

    navToNext();
  }, [currentUser, markAsDone, navToNext]);

  const requestPermissions = useCallback(async () => {
    const results = await registerForNotifications();

    if (results && currentUser) {
      await dispatch(
        authActions.updateCurrentUser({
          ...currentUser,
          receivePushNotifications: true,
        }),
      ).unwrap();
    }

    navToNext();
  }, [currentUser, navToNext]);

  return (
    <ScrollView>
      <PageWrapper>
        <Box p={4}>
          <Heading mb={4}>Notifications</Heading>
          <CardContainer>
            <VStack>
              <Text fontSize={16} mb={4}>
                Rock The Street, Wall Street sends important notifications about events and other information including
                the following:
              </Text>
              <VStack space={2} mb={4}>
                <Text fontSize={16}>• Sessions & Event Details</Text>
                <Text fontSize={16}>• Session Reminders</Text>
                <Text fontSize={16}>• Event Opportunities</Text>
                <Text fontSize={16}>• Mentorship Signup</Text>
                <Text fontSize={16}>• Curriculum</Text>
                <Text fontSize={16}>• Career Center</Text>
              </VStack>
              <Text fontSize={12} fontStyle="italic" mb={4} color={colors.darkGrey}>
                Your phone will request permission for push notifications when you select "Enable Notifications".
              </Text>
            </VStack>
            <VStack space={2}>
              <Button onPress={requestPermissions} text="Enable Push Notifications" color="primary" />
              <Button onPress={decline} text="Do Not Enable Push Notifications" />
            </VStack>
          </CardContainer>
        </Box>
      </PageWrapper>
    </ScrollView>
  );
};
