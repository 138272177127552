import { BlurView } from 'expo-blur';
import { groupBy } from 'lodash';
import moment from 'moment';
import { Box, Heading, SectionList } from 'native-base';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useActivityData, useCampaign, useScheduleItems } from '../../hooks';
import { Activity } from '../../models/view';
import { useAppDispatch } from '../../store';
import { campaignActions, programEventActions } from '../../store/actions';
import { colors } from '../../styles';
import { isWeb, MAX_WEB_CONTAINER_WIDTH } from '../../utils';
import { nativeAlert } from '../../utils/ui';
import { NoData } from '../NoData';
import { ActivityCard } from '../ui';

export interface ScheduleListItemProps {
  activity: Activity;
  onPress: () => void;
}

export const ScheduleListItem: React.FC<ScheduleListItemProps> = ({ activity, onPress }) => {
  const { date, time } = useActivityData(activity);

  return <ActivityCard title={activity.title} overline={date} content={time} onPress={onPress} />;
};

export interface ScheduleListProps {
  onItemPress: (item: Activity) => void;
}

export const ScheduleList: React.FC<ScheduleListProps> = ({ onItemPress }) => {
  const dispatch = useAppDispatch();
  const { campaign } = useCampaign();
  const { scheduleItems: items, loading, error } = useScheduleItems();
  const [handledError, setHandledError] = useState(true);

  useEffect(() => {
    if (error && !handledError) {
      setHandledError(true);
      nativeAlert('Loading Error', 'There was a problem loading your schedule. Please try again.');
    }
  }, [handledError, error]);

  const sortedItems = useMemo(() => {
    const now = moment()
      .tz(campaign?.timezone ?? 'UTC')
      .startOf('day');

    return items
      .filter((item) => {
        const startDate = moment(item.dateTime)
          .tz(campaign?.timezone ?? 'UTC')
          .startOf('day');
        return startDate.isSameOrAfter(now);
      })
      .sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime());
  }, [campaign, items]);

  const groupedItems = useMemo(() => {
    return Object.entries(
      groupBy(sortedItems, (item) => {
        return new Date(item.dateTime).toLocaleDateString('en-US', { month: 'long' });
      }),
    ).map(([month, items]) => {
      return {
        title: month,
        data: items,
      };
    });
  }, [sortedItems]);

  const refresh = useCallback(() => {
    dispatch(programEventActions.getProgramEvents());
    dispatch(campaignActions.getCampaign());

    setTimeout(() => {
      setHandledError(false);
    }, 1000);
  }, [dispatch]);

  return (
    <SectionList
      w="100%"
      h="100%"
      maxWidth={isWeb ? MAX_WEB_CONTAINER_WIDTH : undefined}
      contentContainerStyle={{ paddingBottom: 24 }}
      sections={groupedItems}
      keyExtractor={(item) => item.id}
      renderItem={({ item }) => (
        <Box px={4}>
          <ScheduleListItem activity={item} onPress={() => onItemPress(item)} />
        </Box>
      )}
      renderSectionHeader={({ section: { title } }) => (
        <Box px={4}>
          <BlurView style={{ width: '100%', height: 40, flex: 1, justifyContent: 'flex-end', paddingBottom: 10 }}>
            <Heading fontSize={12} fontWeight={'700'} color={colors.darkGrey} textTransform="uppercase">
              {title}
            </Heading>
          </BlurView>
        </Box>
      )}
      ListHeaderComponent={
        <Heading py={4} px={4}>
          Schedule
        </Heading>
      }
      ListEmptyComponent={<NoData px={4} message="No upcoming events. Check back soon..." />}
      onRefresh={refresh}
      refreshing={loading}
    />
  );
};
