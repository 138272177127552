import { IInputProps, Input } from 'native-base';
import React from 'react';
import { colors } from '../../../../styles';
import { FormControl, FormControlInputProps } from './FormControl';

interface TextInputProps extends FormControlInputProps {
  value: string;
  onChange: (newValue: string) => void;
  type?: 'text' | 'password';
  inputProps?: IInputProps;
}

export const DefaultEmailTextInputProps: Partial<IInputProps> = {
  keyboardType: 'email-address',
  autoComplete: 'email',
  textContentType: 'emailAddress',
  autoCorrect: false,
  autoCapitalize: 'none',
};

export const TextInput: React.FC<TextInputProps> = React.memo((props) => {
  const { value, onChange, label, disabled, inputProps, type = 'text' } = props;

  return (
    <FormControl {...props}>
      <Input
        // my="1"
        placeholder={label}
        w="100%"
        py={4}
        borderRadius={10}
        backgroundColor={colors.white}
        _focus={{
          borderColor: colors.primary,
        }}
        fontSize={14}
        placeholderTextColor={colors.darkGrey}
        type={type}
        value={value}
        onChangeText={onChange}
        {...inputProps}
        isDisabled={disabled}
      />
    </FormControl>
  );
});

export const PasswordInput: React.FC<TextInputProps> = React.memo((props) => {
  return <TextInput {...props} type="password" />;
});

export const EmailInput: React.FC<TextInputProps> = React.memo((props) => {
  return <TextInput {...props} inputProps={DefaultEmailTextInputProps} />;
});
