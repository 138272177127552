import { Box } from 'native-base';
import React from 'react';
import { SectionContainer, SectionContainerProps } from './SectionContainer';
import { SectionTitle, SectionTitleProps } from './SectionTitle';

export type SectionProps = SectionContainerProps &
  SectionTitleProps & {
    children: React.ReactNode;
  } & {
    titlePX?: number;
    titlePY?: number;
  };

export const Section: React.FC<SectionProps> = ({ children, titlePX, titlePY, ...restProps }) => {
  return (
    <SectionContainer {...restProps}>
      <Box px={titlePX} py={titlePY}>
        <SectionTitle {...restProps} mb={2} />
      </Box>
      {children}
    </SectionContainer>
  );
};
