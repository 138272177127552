import { Box, CloseIcon, Heading, HStack, IconButton, Text, VStack } from 'native-base';
import React, { useMemo } from 'react';
import { colors } from '../../../styles';
import { CardBodyProps } from './CardBody';
import { CardContainer } from './CardContainer';

export type AlertCardProps = CardBodyProps & {
  message: string | (() => React.ReactNode);
  title?: string;
  status?: 'success' | 'warning' | 'error' | 'info';
  canClose?: boolean;
  onClose?: () => void;
};

export const AlertCard: React.FC<AlertCardProps> = ({
  status = 'info',
  message,
  title,
  canClose = true,
  onClose = () => {},
}) => {
  const backgroundColor = useMemo(() => {
    switch (status) {
      case 'success':
        return colors.primaryLight;
      case 'warning':
        return colors.warningLight;
      case 'error':
        return colors.alertLight;
      case 'info':
      default:
        return colors.lighterGrey;
    }
  }, [status]);

  const accentColor = useMemo(() => {
    switch (status) {
      case 'success':
        return colors.primary;
      case 'warning':
        return colors.warning;
      case 'error':
        return colors.alert;
      case 'info':
      default:
        return colors.lightGrey;
    }
  }, [status]);

  const actionColor = useMemo(() => {
    switch (status) {
      case 'success':
        return colors.primary;
      case 'warning':
        return colors.warning;
      case 'error':
        return colors.alert;
      case 'info':
      default:
        return colors.darkGrey;
    }
  }, [status]);

  const renderMessage = () => {
    if (typeof message === 'string') {
      return (
        <Text flex={1} fontSize={13} color={colors.black}>
          {message}
        </Text>
      );
    }

    return <Box flex={1}>{message()}</Box>;
  };

  return (
    <CardContainer backgroundColor={backgroundColor}>
      <Box borderColor={accentColor} borderLeftWidth={3} pl={4}>
        <VStack space={2} flexShrink={1} w="100%">
          {title && <Heading size="xs">{title}</Heading>}
          <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
            {renderMessage()}
            {canClose && (
              <IconButton
                flex={0}
                variant="unstyled"
                onPress={onClose}
                _focus={{
                  borderWidth: 0,
                }}
                icon={<CloseIcon size={4} />}
                _icon={{
                  color: actionColor,
                }}
              />
            )}
          </HStack>
        </VStack>
      </Box>
    </CardContainer>
  );
};
