import { useMemo } from 'react';
import { REGISTRATION_OTHER } from '../../constants';
import { Sponsor } from '../../models/view';
import { useAppSelector } from '../../store';

export const useSponsorItems = () => {
  const { sponsorAccounts, loading, error } = useAppSelector((state) => state.accounts);

  const sponsors: Sponsor[] = useMemo(() => {
    return sponsorAccounts
      .map((sponsor) => ({
        id: sponsor.id,
        name: sponsor.name,
        imageUrl: sponsor.logoUrl,
        sfid: sponsor.sfid,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [sponsorAccounts]);

  return { sponsors, loading, error };
};

export const useSponsorSelectOptions = () => {
  const { sponsors } = useSponsorItems();

  const sponsorSelectOptions = useMemo(() => {
    return sponsors
      .map((sponsor) => ({
        label: sponsor.name,
        value: sponsor.sfid,
      }))
      .concat({
        label: 'Other',
        value: REGISTRATION_OTHER,
      });
  }, [sponsors]);

  return { sponsorSelectOptions };
};
