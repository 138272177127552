import { useMemo } from 'react';
import { SettingSlugs } from '../../constants';
import { ProfessionalNetwork } from '../../models/view';
import { useAppSelector } from '../../store';
import { useSettings } from './useSettings';
import { userIsStudent } from '../alerts/utils';

export const useProfessionalNetworks = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { settings, getSettingValueByName } = useSettings();

  const professionalNetworks: ProfessionalNetwork[] = useMemo(() => {
    const currentUserIsStudent = userIsStudent(currentUser);

    const linkedInUrlSlug = currentUserIsStudent
      ? SettingSlugs.LINKEDIN_ALUMNI_URL
      : SettingSlugs.LINKEDIN_VOLUNTEER_URL;

    return [
      {
        id: '1',
        type: 'linkedin',
        title: 'RTSWS Networking Group',
        url: getSettingValueByName(linkedInUrlSlug),
      },
      {
        id: '2',
        type: 'linkedin',
        title: 'Rock the Street, Wall Street',
        url: getSettingValueByName(SettingSlugs.LINKEDIN_URL),
      },
    ];
  }, [settings, currentUser]);

  return { professionalNetworks };
};
