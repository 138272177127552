import { Box, Divider, Heading, Text, VStack } from 'native-base';
import React, { useCallback, useState } from 'react';
import { ValidationError } from 'yup';
import { useReferrerSelectOptions, useSchoolAccountSelectOptions } from '../../hooks';
import { ReenrollmentData } from '../../store/registration/types';
import { nativeAlert } from '../../utils/ui';
import { reenrollmentFormSchema } from '../../validation/schemas/auth';
import { ValidationErrors } from '../../validation/types';
import { processValidationErrors } from '../../validation/utils';
import { Button, Form, SelectInput } from '../ui';
import { CardContainer } from '../ui/cards/CardContainer';
import { FormProps } from '../ui/forms/types';

export interface StudentReenrollmentFormProps extends FormProps {
  onSubmit: (data: ReenrollmentData) => void;
}

export const StudentReenrollmentForm: React.FC<StudentReenrollmentFormProps> = React.memo(
  ({ loading, disabled, onSubmit }) => {
    const { schoolAccountSelectOptions } = useSchoolAccountSelectOptions();
    const { referrerSelectOptions } = useReferrerSelectOptions();

    const [schoolAccountSfid, setSchoolAccountSfid] = useState('');
    const [referrer, setReferrer] = useState('');
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

    const handleSubmit = useCallback(async () => {
      const data: ReenrollmentData = {
        schoolAccountSfid,
        referrer,
      };

      try {
        setValidationErrors({});
        reenrollmentFormSchema.validateSync(data, { abortEarly: false });
        onSubmit(data);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          if (err instanceof ValidationError) {
            setValidationErrors(processValidationErrors(err));
            nativeAlert('Validation Error', 'Please check the form for errors.');
          } else {
            throw err;
          }
        }
      }
    }, [onSubmit, schoolAccountSfid, referrer]);

    return (
      <Form>
        <Box p={4}>
          <Heading mb={4}>Reenrollment</Heading>
          <CardContainer>
            <Box mb={8}>
              <Text>
                Fill out the form below to reenroll in the Rock The Street, Wall Street program for the upcoming school
                year.
              </Text>
            </Box>
            <Divider mb={8} />
            <Box mb={4}>
              <VStack mb={4}>
                <SelectInput
                  label="School"
                  value={schoolAccountSfid}
                  options={schoolAccountSelectOptions}
                  onChange={setSchoolAccountSfid}
                  disabled={disabled}
                  loading={loading}
                  error={validationErrors.schoolAccountSfid}
                />

                <SelectInput
                  label="How did you hear about RTSWS?"
                  value={referrer}
                  options={referrerSelectOptions}
                  onChange={setReferrer}
                  disabled={disabled}
                  loading={loading}
                  error={validationErrors.referrer}
                />
              </VStack>
              <Box>
                <Button
                  onPress={() => handleSubmit()}
                  disabled={disabled}
                  loading={loading}
                  text="Re-enroll Now"
                  color="primary"
                />
              </Box>
            </Box>
          </CardContainer>
        </Box>
      </Form>
    );
  },
);
