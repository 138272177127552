import { Button as NBButton } from 'native-base';
import React, { useMemo } from 'react';
import { colors } from '../../../styles';

export interface ButtonProps {
  text: string;
  color?: 'primary' | 'secondary' | 'default' | 'danger';
  disabled?: boolean;
  loading?: boolean;
  onPress?: () => void;
  transparent?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  text,
  color = 'default',
  onPress,
  disabled = false,
  loading = false,
  transparent = false,
}) => {
  const buttonColor = useMemo(() => {
    if (transparent) {
      return 'transparent';
    }

    switch (color) {
      case 'primary':
        return colors.primary;
      case 'secondary':
        return colors.secondary;
      case 'danger':
        return colors.dangerLight;
      case 'default':
      default:
        return colors.lightGrey;
    }
  }, [color, transparent]);

  const textColor = useMemo(() => {
    if (transparent) {
      switch (color) {
        case 'primary':
          return colors.primary;
        case 'secondary':
          return colors.secondary;
        case 'danger':
          return colors.danger;
        case 'default':
        default:
          return colors.black;
      }
    }

    switch (color) {
      case 'primary':
      case 'secondary':
        return colors.white;
      case 'danger':
        return colors.danger;
      case 'default':
      default:
        return colors.black;
    }
  }, [color, transparent]);

  return (
    <NBButton
      backgroundColor={buttonColor}
      _text={{ color: textColor, fontWeight: 700, fontSize: 14 }}
      onPress={onPress}
      borderRadius={10}
      padding={4}
      isDisabled={disabled}
      isLoading={loading}
    >
      {text}
    </NBButton>
  );
};
