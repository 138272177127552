import { Text } from 'native-base';
import React from 'react';

export interface FullWidthBoxTitleProps {
  title: string;
}

export const FullWidthBoxTitle: React.FC<FullWidthBoxTitleProps> = ({ title }) => {
  return (
    <Text textAlign="center" fontWeight="bold" mb={4}>
      {title}
    </Text>
  );
};
