import { FIREBASE_MESSAGING_VAPID_KEY } from '@env';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useCallback } from 'react';
import { Platform } from 'react-native';
import { createFirebaseApp } from '../../firebase';
import { DevicePlatform } from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { deviceActions, notificationActions } from '../../store/actions';
import { colors } from '../../styles';
import { nativeAlert } from './../../utils/ui';

interface UseRegisterForNotificationsParams {
  alertOnDenied: boolean;
}

interface UseRegisterForNotificationsOutput {
  needsToRegister: boolean;
  registerForNotifications: (
    params?: UseRegisterForNotificationsParams,
  ) => Promise<{ expoPushToken?: string; devicePushToken: string } | undefined>;
  markAsDone: () => Promise<void>;
}

const canUsePermissions = async () => {
  return Device.isDevice;
};

const requestPermissions = async () => {
  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;

  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }

  if (finalStatus !== 'granted') {
    return false;
  }

  return true;
};

export const useRegisterForNotifications = (): UseRegisterForNotificationsOutput => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const { needsToRegister } = useAppSelector((state) => state.notifications);

  const markAsDone = useCallback(async () => {
    dispatch(notificationActions.setNeedsToRegister({ value: false }));
  }, []);

  const registerForNotifications: UseRegisterForNotificationsOutput['registerForNotifications'] = useCallback(
    async ({ alertOnDenied } = { alertOnDenied: true }) => {
      markAsDone();

      if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: colors.primary,
        });
      }

      if (!(await canUsePermissions())) {
        console.warn('You must use physical device for Push Notifications');
        return;
      }

      const granted = await requestPermissions();

      if (!granted && alertOnDenied) {
        await nativeAlert('Push Notifications', 'You can enable push notifications in settings at a later time.');
        return;
      }

      if (Platform.OS === 'web') {
        const app = createFirebaseApp();
        const messaging = getMessaging(app);

        try {
          const token = await getToken(messaging, {
            vapidKey: FIREBASE_MESSAGING_VAPID_KEY,
          });

          onMessage(messaging, (payload) => {
            dispatch(notificationActions.getNotifications());

            navigator.serviceWorker.ready.then((registration) => {
              if (!payload.notification) {
                return;
              }

              const { title, body } = payload.notification;

              if (title && body) {
                registration.showNotification(title, {
                  body,
                });
              }
            });
          });

          dispatch(
            deviceActions.createDevice({
              expoPushToken: '', // can't send through null or undefined
              devicePushToken: token,
              platform: Platform.OS as DevicePlatform,
              name: Device.deviceName ?? undefined,
              brand: Device.brand ?? undefined,
              manufacturer: Device.manufacturer ?? undefined,
              modelName: Device.modelName ?? undefined,
              osName: Device.osName ?? undefined,
              osVersion: Device.osVersion ?? undefined,
            }),
          );
        } catch (error) {
          console.error('Could not get push token:', error);
        }
      } else {
        const { data: expoPushToken } = await Notifications.getExpoPushTokenAsync();
        const { data: devicePushToken } = await Notifications.getDevicePushTokenAsync();

        if (!expoPushToken || !devicePushToken) {
          await nativeAlert('Push Notifications', 'Failed to get device push token');
          return;
        }

        await dispatch(
          deviceActions.createDevice({
            expoPushToken,
            devicePushToken,
            platform: Platform.OS as DevicePlatform,
            name: Device.deviceName ?? undefined,
            brand: Device.brand ?? undefined,
            manufacturer: Device.manufacturer ?? undefined,
            modelName: Device.modelName ?? undefined,
            osName: Device.osName ?? undefined,
            osVersion: Device.osVersion ?? undefined,
          }),
        );

        return { expoPushToken, devicePushToken };
      }
    },
    [markAsDone],
  );

  return { markAsDone, needsToRegister, registerForNotifications };
};
