import { Box } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { StudentReenrollmentForm } from '../../components/auth/StudentReenrollmentForm';
import { AppStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { registrationActions } from '../../store/actions';
import { ReenrollmentData } from '../../store/registration/types';
import { nativeAlert } from '../../utils/ui';

export const StudentReenrollmentScreen: React.FC<AppStackScreenWithNavProps<'StudentReenrollment'>> = ({
  navigation,
}) => {
  const dispatch = useAppDispatch();

  const { error: registrationError } = useAppSelector((state) => state.registrations);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (registrationError) {
      dispatch(registrationActions.updateError({ error: null }));
      nativeAlert('Enrollment Unsuccessful', registrationError);
    }
  }, [registrationError]);

  const handleSubmit = useCallback(async (data: ReenrollmentData) => {
    setLoading(true);
    setDisabled(true);

    try {
      await dispatch(registrationActions.submitReenrollment({ data })).unwrap();
      await nativeAlert('Success!', 'Your re-enrollment has been submitted.');
      navigation.goBack();
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  }, []);

  return (
    <Box flex={1}>
      <StudentReenrollmentForm loading={loading} disabled={disabled} onSubmit={(data) => handleSubmit(data)} />
    </Box>
  );
};
