import { Ionicons } from '@expo/vector-icons';
import { Button, Divider, Icon, Link, ScrollView, Text, VStack } from 'native-base';
import React, { PropsWithChildren, useCallback } from 'react';
import { SettingSlugs } from '../../constants';
import { useSettings } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActions } from '../../store/actions';
import { colors } from '../../styles';
import { nativeConfirm } from '../../utils/ui';

interface DrawerItemProps extends PropsWithChildren {
  icon?: string;
  onPress?: () => void;
  href?: string;
  isExternal?: boolean;
  label?: string;
}

const DrawerItem: React.FC<DrawerItemProps> = ({ icon, label, href, onPress, children, isExternal = false }) => {
  const isHovered = href ? window.location.href.includes(href) : false;

  return (
    <Link href={href} w="100%" isExternal={isExternal}>
      <Button
        w="100%"
        variant="ghost"
        onPress={onPress}
        leftIcon={icon ? <Icon as={Ionicons} name={icon} color={colors.secondary} size="md" /> : undefined}
        justifyContent="flex-start"
        colorScheme="secondary"
        isHovered={isHovered}
      >
        {children ? (
          children
        ) : (
          <Text fontSize="sm" ml={1}>
            {label}
          </Text>
        )}
      </Button>
    </Link>
  );
};

export default function DrawerContent(props: any) {
  const dispatch = useAppDispatch();
  const { getSettingValueByName } = useSettings();

  const CareerCenterUrl = getSettingValueByName(SettingSlugs.RTSWS_JOBS_URL);
  const alumnaeUrl = getSettingValueByName(SettingSlugs.LINKEDIN_ALUMNI_URL);
  const rtswsUrl = getSettingValueByName(SettingSlugs.RTSWS_HOME_URL);

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const { currentDevice } = useAppSelector((state) => state.device);

  const signOut = useCallback(async () => {
    if (await nativeConfirm('Sign Out', 'Are you sure you want to sign out?')) {
      dispatch(authActions.signOutUser({ currentDeviceId: currentDevice?.id, includeBrowserSessionLogout: true }));
    }
  }, [dispatch, currentDevice]);

  return (
    <ScrollView {...props} style={{ minHeight: '100vh' }}>
      <VStack style={{ minHeight: '100vh', paddingBottom: '32px' }} justifyContent="space-between">
        <VStack m={2} space={1}>
          <DrawerItem icon="home-outline" label="Home" href="/home" />
          <DrawerItem icon="calendar-outline" label="Schedule" href="/schedule" />
          <DrawerItem icon="document-outline" label="Resources" href="/resources" />
          <Divider my={2} />
          <DrawerItem icon="notifications-outline" label="Notifications" href="/notifications" />
          <DrawerItem icon="briefcase-outline" label="Career Center" href={CareerCenterUrl} isExternal />
          <DrawerItem icon="briefcase-outline" label="Alumnae" href={alumnaeUrl} isExternal />
          <DrawerItem icon="people-outline" label="Contacts" href="/contacts" />
          <DrawerItem icon="ribbon-outline" label="Student CEO" href="/student-ceo" />
          <DrawerItem icon="information-circle-outline" label="About RTSWS" href="/about" />
          <DrawerItem icon="compass-outline" label="RTSWS Website" href={rtswsUrl} isExternal />
        </VStack>
        <VStack m={2} space={1} mb={4}>
          <Divider />
          <DrawerItem icon="settings-outline" href="/settings">
            <VStack ml={1} space={1}>
              <Text fontWeight="bold" fontSize="sm" isTruncated maxWidth="100%">
                {currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : ''}
              </Text>
              <Text isTruncated maxWidth="100%">
                {currentUser ? currentUser.email : ''}
              </Text>
            </VStack>
          </DrawerItem>
          <DrawerItem icon="log-out-outline" label="Logout" onPress={signOut}>
            <Text fontSize="sm" ml={1}>
              Logout
            </Text>
          </DrawerItem>
        </VStack>
      </VStack>
    </ScrollView>
  );
}
