import {
  API_URL,
  APP_ENV,
  AUTH0_API_AUDIENCE,
  AUTH0_CLIENT_ID_NATIVE,
  AUTH0_CLIENT_ID_WEB,
  AUTH0_DOMAIN,
  SENTRY_DEBUG,
  SENTRY_DSN,
} from '@env';
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as FileSystem from 'expo-file-system';
import * as MailComposer from 'expo-mail-composer';
import * as Updates from 'expo-updates';
import _ from 'lodash';
import { Box, ChevronUpIcon, Heading, HStack, Pressable, ScrollView, ShareIcon } from 'native-base';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import JSONTree from 'react-native-json-tree';
import { PageWrapper, TextInput } from '../../components/ui';
import { CardContainer } from '../../components/ui/cards/CardContainer';
import { AppStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppSelector } from '../../store';
import { nativeAlert, nativeConfirm } from '../../utils/ui';

const theme = {
  scheme: 'solarized',
  author: 'ethan schoonover (http://ethanschoonover.com/solarized)',
  base00: '#000000',
  base01: '#073642',
  base02: '#586e75',
  base03: '#657b83',
  base04: '#839496',
  base05: '#93a1a1',
  base06: '#eee8d5',
  base07: '#fdf6e3',
  base08: '#dc322f',
  base09: '#cb4b16',
  base0A: '#b58900',
  base0B: '#859900',
  base0C: '#2aa198',
  base0D: '#268bd2',
  base0E: '#6c71c4',
  base0F: '#d33682',
};

function deepLodashPick(obj: any, key: string, newObj = {}, path: string[] = []) {
  if (!key) {
    return obj;
  }

  for (let k in obj) {
    const value = obj[k];

    if (k.toLowerCase().includes(key.toLowerCase())) {
      _.set(newObj, [...path, k], value);
    }

    if (_.isObject(value)) {
      path.push(k);
      deepLodashPick(value, key, newObj, path);
      path.pop();
    }
  }

  return newObj;
}

export const DebugScreen: React.FC<AppStackScreenWithNavProps<'Debug'>> = ({ navigation }) => {
  const scrollRef = useRef<any>(null);
  const stateRaw = useAppSelector((state) => state);
  const { currentUser } = stateRaw.auth;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [logs, setLogs] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');

  const stateJson = JSON.parse(JSON.stringify(stateRaw, null, 2));
  const constantJson = JSON.parse(
    JSON.stringify(
      {
        constants: Constants,
        application: Application,
      },
      null,
      2,
    ),
  );
  const updateJson = JSON.parse(JSON.stringify(Updates, null, 2));

  const filteredStateJson = useMemo(() => {
    return deepLodashPick(stateJson, searchText);
  }, [stateJson, searchText]);

  const filteredConstantJson = useMemo(() => {
    return deepLodashPick(constantJson, searchText);
  }, [constantJson, searchText]);

  const filteredUpdateJson = useMemo(() => {
    return deepLodashPick(updateJson, searchText);
  }, [updateJson, searchText]);

  const scrollUp = useCallback(() => {
    scrollRef.current?.scrollTo({ x: 0, y: 0, animated: true });
  }, []);

  const share = useCallback(async () => {
    if (!(await MailComposer.isAvailableAsync())) {
      nativeAlert('Not Available', 'Mail is not available on this device.');
      return;
    }

    if (await nativeConfirm('Share Debug Info', 'Do you want to share debug information with the development team?')) {
      // store JSON in files
      const stateJsonFilePath = `${FileSystem.documentDirectory}state.json`;
      const constantJsonFilePath = `${FileSystem.documentDirectory}constants.json`;
      const updateJsonFilePath = `${FileSystem.documentDirectory}updates.json`;

      await Promise.all([
        FileSystem.writeAsStringAsync(stateJsonFilePath, JSON.stringify(stateJson, null, 2)),
        FileSystem.writeAsStringAsync(constantJsonFilePath, JSON.stringify(constantJson, null, 2)),
        FileSystem.writeAsStringAsync(updateJsonFilePath, JSON.stringify(updateJson, null, 2)),
      ]);

      // Create body. Contents are ENV vars for the app, plus device data.
      const env = {
        API_URL,
        APP_ENV,
        AUTH0_CLIENT_ID_NATIVE,
        AUTH0_CLIENT_ID_WEB,
        AUTH0_DOMAIN,
        AUTH0_API_AUDIENCE,
        SENTRY_DSN,
        SENTRY_DEBUG,
      };

      const body = `
        Environment:

        ${Object.entries(env)
          .map(([key, value]) => `• ${key}: ${value}`)
          .join('\n')}

        ---

        Device:

        ${Object.entries(JSON.parse(JSON.stringify(Device, null, 2)))
          .map(([key, value]) => `• ${key}: ${value}`)
          .join('\n')}
      `;

      // Send email with JSON data as attachments
      await MailComposer.composeAsync({
        recipients: ['gbartone@missioncloud.com', 'ccwittwer@missioncloud.com'],
        subject: `Debug Info from ${currentUser?.email}`,
        body,
        attachments: [stateJsonFilePath, constantJsonFilePath, updateJsonFilePath],
      });
    }
  }, []);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HStack alignItems="center">
          <Pressable onPress={scrollUp}>
            <Box py={4} px={2}>
              <ChevronUpIcon />
            </Box>
          </Pressable>
          <Pressable onPress={share}>
            <Box py={4} px={2}>
              <ShareIcon />
            </Box>
          </Pressable>
        </HStack>
      ),
    });
  }, []);

  return (
    <ScrollView ref={scrollRef}>
      <PageWrapper>
        <Box p={4}>
          <Box mb={4}>
            <Box mb={4}>
              <SegmentedControl
                values={['State', 'Constants', 'Updates']}
                selectedIndex={selectedIndex}
                onChange={(event) => {
                  setSelectedIndex(event.nativeEvent.selectedSegmentIndex);
                }}
              />
            </Box>
            <TextInput
              value={searchText}
              onChange={(newValue) => setSearchText(newValue)}
              label={'Search'}
              hideLabel
              inputProps={{ autoCapitalize: 'none', clearButtonMode: 'always', autoComplete: 'off' }}
            />
          </Box>
          <Box display={selectedIndex === 0 ? 'flex' : 'none'}>
            <Heading mb={4}>State</Heading>
            <CardContainer>
              <JSONTree data={filteredStateJson} invertTheme theme={theme} />
              {/* <Text>{JSON.stringify(logs)}</Text> */}
            </CardContainer>
          </Box>
          <Box display={selectedIndex === 1 ? 'flex' : 'none'}>
            <Heading mb={4}>Constants</Heading>
            <CardContainer>
              <JSONTree data={filteredConstantJson} invertTheme theme={theme} />
            </CardContainer>
          </Box>
          <Box display={selectedIndex === 2 ? 'flex' : 'none'}>
            <Heading mb={4}>Updates</Heading>
            <CardContainer>
              <JSONTree data={filteredUpdateJson} invertTheme theme={theme} />
            </CardContainer>
          </Box>
        </Box>
      </PageWrapper>
    </ScrollView>
  );
};
