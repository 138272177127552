import { ConfigContext, ExpoConfig } from '@expo/config';
import pkg from './package.json';

export const APP_CONFIG_SCHEME = 'rtswsapp';

const slug = 'rtsws-app';
const owner = 'rockthestreetwallstreet';

export default ({ config }: ConfigContext): ExpoConfig => ({
  ...config,
  name: 'Rock The Street, Wall Street',
  slug,
  owner,
  currentFullName: `@${owner}/${slug}`,
  originalFullName: `@${owner}/${slug}`,
  scheme: APP_CONFIG_SCHEME,
  version: pkg.version,
  orientation: 'portrait',
  icon: './assets/icon.png',
  userInterfaceStyle: 'light',
  splash: {
    image: './assets/splash.png',
    resizeMode: 'contain',
  },
  updates: {
    fallbackToCacheTimeout: 0,
    url: 'https://u.expo.dev/9e5396d9-7aea-4bb1-8c90-f69cbed1742d',
  },
  assetBundlePatterns: ['**/*'],
  ios: {
    bundleIdentifier: 'com.rockthestreetwallstreet.app.ios',
    buildNumber: pkg.rtsws.buildNumber.toString(),
    supportsTablet: true,
    entitlements: {
      'com.apple.developer.applesignin': ['Default'],
    },
    appStoreUrl: 'https://apps.apple.com/us/app/rock-the-street-wall-street/id6444662486',
  },
  android: {
    package: 'com.rockthestreetwallstreet.app.android',
    versionCode: pkg.rtsws.buildNumber,
    adaptiveIcon: {
      foregroundImage: './assets/adaptive-icon.png',
    },
    googleServicesFile: process.env.GOOGLE_SERVICES_JSON,
    playStoreUrl: 'https://play.google.com/store/apps/details?id=com.rockthestreetwallstreet.app.android',
  },
  web: {
    favicon: './assets/icon.png',
    backgroundColor: '#044959',
    description: 'Rock The Street, Wall Street',
    name: 'RTSWS',
    orientation: 'portrait',
    barStyle: 'black',
    preferRelatedApplications: true,
  },
  extra: {
    eas: {
      projectId: '9e5396d9-7aea-4bb1-8c90-f69cbed1742d',
    },
    rtsws: {
      buildNumber: pkg.rtsws.buildNumber,
    },
  },
  runtimeVersion: {
    policy: 'sdkVersion',
  },
  hooks: {
    postPublish: [
      {
        file: 'sentry-expo/upload-sourcemaps',
        config: {
          organization: process.env.SENTRY_ORGANIZATION,
          project: process.env.SENTRY_PROJECT,
          authToken: process.env.SENTRY_AUTH_TOKEN,
        },
      },
    ],
  },
  plugins: [
    'sentry-expo',
    [
      'expo-build-properties',
      {
        android: {
          enableProguardInReleaseBuilds: true,
          extraProguardRules: '-keep public class com.horcrux.svg.** {*;}',
        },
      },
    ],
  ],
});
