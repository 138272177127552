import { SettingSlugs } from './SettingSlugs';

/**
 * CANADA STUDENT CURRICULA
 */

const CURRICULUM_STUDENT_CANADA = {
  name: 'Canadian Student',
  curriculum_display_name: 'Canadian Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_CANADA,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_CANADA,
};

const CURRICULUM_STUDENT_CANADA_GREEN = {
  name: 'Canada Green Student',
  curriculum_display_name: 'Canada Green Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_CANADA_GREEN,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_CANADA_GREEN,
};

const CURRICULUM_STUDENT_CANADA_COPPER = {
  name: 'Canada Copper Student',
  curriculum_display_name: 'Canada Copper Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_CANADA_COPPER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_CANADA_COPPER,
};

const CURRICULUM_STUDENT_CANADA_SILVER = {
  name: 'Canada Silver Student',
  curriculum_display_name: 'Canada Silver Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_CANADA_SILVER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_CANADA_SILVER,
};

const CURRICULUM_STUDENT_CANADA_GOLD = {
  name: 'Canada Gold Student',
  curriculum_display_name: 'Canada Gold Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_CANADA_GOLD,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_CANADA_GOLD,
};

/**
 * US STUDENT CURRICULA
 */

const CURRICULUM_STUDENT_COPPER = {
  name: 'Copper Student',
  curriculum_display_name: 'Copper Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_COPPER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_COPPER,
};

const CURRICULUM_STUDENT_GREEN = {
  name: 'Green Student',
  curriculum_display_name: 'Green Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_GREEN,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_GREEN,
};

const CURRICULUM_STUDENT_SILVER = {
  name: 'Silver Student',
  curriculum_display_name: 'Silver Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_SILVER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_SILVER,
};

const CURRICULUM_STUDENT_GOLD = {
  name: 'Gold Student',
  curriculum_display_name: 'Gold Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_GOLD,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_GOLD,
};

/**
 * UK STUDENT CURRICULA
 */

const CURRICULUM_STUDENT_UK = {
  name: 'UK Student',
  curriculum_display_name: 'UK Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_UK,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_UK,
};

const CURRICULUM_STUDENT_UK_GREEN = {
  name: 'UK Green Student',
  curriculum_display_name: 'UK Green Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_UK_GREEN,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_UK_GREEN,
};

const CURRICULUM_STUDENT_UK_COPPER = {
  name: 'UK Copper Student',
  curriculum_display_name: 'UK Copper Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_UK_COPPER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_UK_COPPER,
};

const CURRICULUM_STUDENT_UK_SILVER = {
  name: 'UK Silver Student',
  curriculum_display_name: 'UK Silver Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_UK_SILVER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_UK_SILVER,
};

const CURRICULUM_STUDENT_UK_GOLD = {
  name: 'UK Gold Student',
  curriculum_display_name: 'UK Gold Student Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_STUDENT_UK_GOLD,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_STUDENT_UK_GOLD,
};

/**
 * CANADA INSTRUCTOR CURRICULA
 */

const CURRICULUM_INSTRUCTOR_CANADA = {
  name: 'Canadian Instructor',
  curriculum_display_name: 'Canadian Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_CANADA,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_CANADA,
};

const CURRICULUM_INSTRUCTOR_CANADA_GREEN = {
  name: 'Canada Green Instructor',
  curriculum_display_name: 'Canada Green Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_CANADA_GREEN,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_CANADA_GREEN,
};

const CURRICULUM_INSTRUCTOR_CANADA_COPPER = {
  name: 'Canada Copper Instructor',
  curriculum_display_name: 'Canada Copper Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_CANADA_COPPER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_CANADA_COPPER,
};

const CURRICULUM_INSTRUCTOR_CANADA_SILVER = {
  name: 'Canada Silver Instructor',
  curriculum_display_name: 'Canada Silver Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_CANADA_SILVER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_CANADA_SILVER,
};

const CURRICULUM_INSTRUCTOR_CANADA_GOLD = {
  name: 'Canada Gold Instructor',
  curriculum_display_name: 'Canada Gold Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_CANADA_GOLD,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_CANADA_GOLD,
};

/**
 * UK INSTRUCTOR CURRICULA
 */

const CURRICULUM_INSTRUCTOR_UK = {
  name: 'UK Instructor',
  curriculum_display_name: 'UK Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_UK,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_UK,
};

const CURRICULUM_INSTRUCTOR_UK_GREEN = {
  name: 'UK Green Instructor',
  curriculum_display_name: 'UK Green Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_UK_GREEN,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_UK_GREEN,
};

const CURRICULUM_INSTRUCTOR_UK_COPPER = {
  name: 'UK Copper Instructor',
  curriculum_display_name: 'UK Copper Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_UK_COPPER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_UK_COPPER,
};

const CURRICULUM_INSTRUCTOR_UK_SILVER = {
  name: 'UK Silver Instructor',
  curriculum_display_name: 'UK Silver Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_UK_SILVER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_UK_SILVER,
};

const CURRICULUM_INSTRUCTOR_UK_GOLD = {
  name: 'UK Gold Instructor',
  curriculum_display_name: 'UK Gold Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_UK_GOLD,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_UK_GOLD,
};

/**
 * US INSTRUCTOR CURRICULA
 */

const CURRICULUM_INSTRUCTOR_COPPER = {
  name: 'Copper Instructor',
  curriculum_display_name: 'Copper Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_COPPER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_COPPER,
};

const CURRICULUM_INSTRUCTOR_GREEN = {
  name: 'Green Instructor',
  curriculum_display_name: 'Green Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_GREEN,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_GREEN,
};

const CURRICULUM_INSTRUCTOR_SILVER = {
  name: 'Silver Instructor',
  curriculum_display_name: 'Silver Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_SILVER,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_SILVER,
};

const CURRICULUM_INSTRUCTOR_GOLD = {
  name: 'Gold Instructor',
  curriculum_display_name: 'Gold Instructor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_INSTRUCTOR_GOLD,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_INSTRUCTOR_GOLD,
};

const CURRICULUM_PROTEGE = {
  name: 'Protege',
  curriculum_display_name: 'Protege Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_PROTEGE,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_PROTEGE,
};

/**
 * US GENERAL CURRICULA
 */

const CURRICULUM_PROTEGEE = {
  name: 'Protegee',
  curriculum_display_name: 'Protegee Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_PROTEGEE,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_PROTEGEE,
};

const CURRICULUM_MENTOR = {
  name: 'Mentor',
  curriculum_display_name: 'Mentor Workbook',
  curriculum_password_slug: SettingSlugs.CURRICULUM_PASSWORD_MENTOR,
  curriculum_url_slug: SettingSlugs.CURRICULUM_URL_MENTOR,
};

export default [
  CURRICULUM_STUDENT_CANADA,
  CURRICULUM_STUDENT_CANADA_COPPER,
  CURRICULUM_STUDENT_CANADA_GREEN,
  CURRICULUM_STUDENT_CANADA_SILVER,
  CURRICULUM_STUDENT_CANADA_GOLD,

  CURRICULUM_STUDENT_COPPER,
  CURRICULUM_STUDENT_GREEN,
  CURRICULUM_STUDENT_SILVER,
  CURRICULUM_STUDENT_GOLD,

  CURRICULUM_STUDENT_UK,
  CURRICULUM_STUDENT_UK_COPPER,
  CURRICULUM_STUDENT_UK_GREEN,
  CURRICULUM_STUDENT_UK_SILVER,
  CURRICULUM_STUDENT_UK_GOLD,

  CURRICULUM_INSTRUCTOR_CANADA,
  CURRICULUM_INSTRUCTOR_CANADA_COPPER,
  CURRICULUM_INSTRUCTOR_CANADA_GREEN,
  CURRICULUM_INSTRUCTOR_CANADA_SILVER,
  CURRICULUM_INSTRUCTOR_CANADA_GOLD,

  CURRICULUM_INSTRUCTOR_COPPER,
  CURRICULUM_INSTRUCTOR_GREEN,
  CURRICULUM_INSTRUCTOR_SILVER,
  CURRICULUM_INSTRUCTOR_GOLD,

  CURRICULUM_INSTRUCTOR_UK,
  CURRICULUM_INSTRUCTOR_UK_COPPER,
  CURRICULUM_INSTRUCTOR_UK_GREEN,
  CURRICULUM_INSTRUCTOR_UK_SILVER,
  CURRICULUM_INSTRUCTOR_UK_GOLD,

  CURRICULUM_PROTEGE,
  CURRICULUM_PROTEGEE,
  CURRICULUM_MENTOR,
];
