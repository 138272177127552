import SegmentedControl from '@react-native-segmented-control/segmented-control';
import { Box } from 'native-base';
import React, { useCallback, useState } from 'react';
import { NotificationsList } from '../../components/notifications/NotificationsList';
import { PageWrapper, Section } from '../../components/ui';
import { Notification } from '../../models/view';
import { AppStackScreenWithNavProps } from '../../navigation/AppNavigation';

export const NotificationsScreen: React.FC<AppStackScreenWithNavProps<'Notifications'>> = ({ navigation, route }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleNotificationPress = useCallback((notification: Notification) => {
    return navigation.navigate('Notification', { id: notification.id });
  }, []);

  return (
    <PageWrapper>
      <Box p={4} mb={4}>
        <SegmentedControl
          values={['All', 'Unread', 'Read']}
          selectedIndex={selectedIndex}
          onChange={(event) => {
            setSelectedIndex(event.nativeEvent.selectedSegmentIndex);
          }}
        />
      </Box>
      <Box display={selectedIndex === 0 ? 'flex' : 'none'}>
        <Section titlePX={4} title={'All Notifications'} hideIcon>
          <NotificationsList filter="all" onNotificationPress={handleNotificationPress} />
        </Section>
      </Box>
      <Box display={selectedIndex === 1 ? 'flex' : 'none'}>
        <Section titlePX={4} title={'Unread Notifications'} hideIcon>
          <NotificationsList filter="unread" onNotificationPress={handleNotificationPress} />
        </Section>
      </Box>
      <Box display={selectedIndex === 2 ? 'flex' : 'none'}>
        <Section titlePX={4} title={'Read Notifications'} hideIcon>
          <NotificationsList filter="read" onNotificationPress={handleNotificationPress} />
        </Section>
      </Box>
    </PageWrapper>
  );
};
