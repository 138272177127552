import { Box, Heading, ScrollView } from 'native-base';
import React from 'react';
import { CareerCenterList } from '../../components/home/CareerCenterList';
import { AdditionalLinksList } from '../../components/resources/AdditionalLinksList';
import { CurriculumLinks } from '../../components/resources/CurriculumLinks';
import { ProfessionalNetworksList } from '../../components/resources/ProfessionalNetworksList';
import { SocialMediaLinks } from '../../components/resources/SocialMediaLinks';
import { PageWrapper, Section } from '../../components/ui';
import { useCampaign } from '../../hooks';
import { ResourcesStackScreenWithNavProps } from '../../navigation/AppNavigation';

export const ResourcesScreen: React.FC<ResourcesStackScreenWithNavProps<'Resources'>> = () => {
  const { campaign } = useCampaign();

  return (
    <ScrollView>
      <PageWrapper>
        <Box p={4}>
          <Heading mb={4}>Resources</Heading>
          {campaign && (
            <Section title="Course Curriculum" hideIcon>
              <CurriculumLinks />
            </Section>
          )}
          <Section title="Professional Networks" hideIcon>
            <ProfessionalNetworksList />
          </Section>
          <Section title="Employment" hideIcon>
            <CareerCenterList />
          </Section>
          <AdditionalLinksList />
          <SocialMediaLinks />
        </Box>
      </PageWrapper>
    </ScrollView>
  );
};
