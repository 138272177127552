import { Box, Divider, FormControl, Heading, Text, VStack } from 'native-base';
import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ValidationError } from 'yup';
import { useAppSelector } from '../../store';
import { RegistrationData } from '../../store/registration/types';
import { nativeAlert } from '../../utils/ui';
import { parentAgreementFormSchema } from '../../validation/schemas/auth';
import { ValidationErrors } from '../../validation/types';
import { processValidationErrors } from '../../validation/utils';
import { Button } from '../ui';
import { CardContainer } from '../ui/cards/CardContainer';
import { EmailInput, Form, TextInput } from '../ui/forms';
import { CheckboxInput } from '../ui/forms/inputs/CheckboxInput';
import { FormProps } from '../ui/forms/types';

interface ParentAgreementFormProps extends FormProps {
  onSubmit: (data: RegistrationData) => void;
}

const styles = StyleSheet.create({
  releaseHeader: {
    fontSize: 14,
    textAlign: 'center',
  },
  releaseSectionTitle: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  releaseText: {
    fontSize: 12,
    marginTop: 5,
    textAlign: 'justify',
  },
});

export const ParentAgreementForm: React.FC<ParentAgreementFormProps> = React.memo(({ loading, disabled, onSubmit }) => {
  const [parentFirstName, setParentFirstName] = useState('');
  const [parentLastName, setParentLastName] = useState('');
  const [parentEmail, setParentEmail] = useState('');
  const [parentMobilePhone, setParentMobilePhone] = useState('');
  const [parentAcknowledged, setParentAcknowledged] = useState(false);

  const registrationState = useAppSelector((state) => state.registrations);
  const { registrationData } = registrationState;

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  const handleSubmit = useCallback(async () => {
    if (!registrationData) {
      throw Error('Registration error');
    }

    const data: RegistrationData = {
      ...registrationData,
      parentFirstName,
      parentLastName,
      parentEmail,
      parentMobilePhone,
      parentAcknowledged,
      parentAcknowledgementTimestamp: Date.now(),
    };

    try {
      setValidationErrors({});
      parentAgreementFormSchema.validateSync(data, { abortEarly: false });
      onSubmit(data);
    } catch (err: any) {
      if (err instanceof ValidationError) {
        if (err instanceof ValidationError) {
          setValidationErrors(processValidationErrors(err));
          nativeAlert('Validation Error', 'Please check the form for errors.');
        } else {
          throw err;
        }
      }
    }
  }, [onSubmit, parentFirstName, parentLastName, parentEmail, parentMobilePhone, parentAcknowledged]);

  return (
    <Form>
      <Box p={4}>
        <Heading mb={4}>Student Registration</Heading>
        <CardContainer>
          <Box mb={8}>
            <Text>This section to be completed by a parent or legal guardian.</Text>
          </Box>
          <Divider mb={8} />
          <Box mb={4}>
            <Text style={styles.releaseHeader}>STUDENT​ ​RELEASE - PARENT SIGNATURE</Text>
            <Text style={styles.releaseText}>
              In consideration for the financial education instruction, which may include field trips and mentoring
              (“Instruction”), to be provided by Rock The Street, Wall Street, a Tennessee non-profit organization, and
              its present and former affiliates, subsidiaries, parents, successors, predecessors, directors, officers,
              owners, board members, agents, assignees, estates, heirs, volunteers and employees (“RTSWS”), and for
              other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, on
              behalf of the above-named Student, I __________________________, the Undersigned Parent or Legal Guardian,
              hereby acknowledge that, on behalf of myself, the Student and any person claiming through Student
              (collectively referred to sometimes as the “Releasing Parties”), I understand and expressly agree as
              follows:
            </Text>

            <Text style={styles.releaseText}>
              <Text style={styles.releaseSectionTitle}>1. Release: Financial Information​.</Text> The financial
              information will be discussed in the Instruction and Student will be participating in financial learning
              activities. In consideration of the opportunity for Student to receive the Instruction, I, the Undersigned
              Parent or Legal Guardian, on behalf of Student and any person claiming through Student, hereby releases
              RTSWS from any responsibility or liability for any harm, including without limitation, personal injury and
              damage to or loss of personal property (including financial loss), that Student and/or any of the
              Releasing Parties may incur as a result of Student’s receipt of, reliance on, and/or participation in the
              Instruction or any principles, teaching or advice learned therein, regardless of whether such harm is the
              result of RTSWS’ negligence.
            </Text>
            <Text style={styles.releaseText}>
              <Text style={styles.releaseSectionTitle}>2. Release: Field Trips​.</Text> RTSWS may organize field trips
              supervised by RTSWS staff as part of the Instruction. In consideration of the opportunity for Student to
              participate in any such field trips, I, the Undersigned Parent or Legal Guardian, on behalf of Student and
              any person claiming through Student, hereby releases RTSWS from any responsibility or liability for any
              harm, including without limitation, personal injury and damage to or loss of personal property (including
              financial loss), that Student and/or any of the Releasing Parties may incur as a result of Student’s
              participation in such field trips, regardless of whether such harm is a result of RTSWS’ negligence,
              whether ordinary or gross.
            </Text>
            <Text style={styles.releaseText}>
              <Text style={styles.releaseSectionTitle}>3. Consent and Release: Mode of Instruction.</Text> RTSWS may
              provide the Instruction and/or any one or more organized field trips or mentoring opportunities it offers
              in person, virtually, utilizing technology capable of providing the Instruction and/or a virtual field
              trip or a combination of both. RTSWS will utilize best practices whenever it utilizes virtual Instruction,
              field trips and/or mentoring (“Virtual Learning Experience”) to ensure that the Student’s RTSWS Virtual
              Learning Experience is secure. I, the Undersigned Parent or Legal Guardian, understand that to promote
              RTSWS’s security measures, the Student must utilize current versions of any browser, application or
              platform which RTSWS utilizes, and I agree to ensure that Student downloads any such updates as they are
              issued. I understand that despite RTSWS’s best efforts, it is impossible for it to ensure that Student’s
              Virtual Learning Experience will be free from unsolicited information, including, without limitation,
              information that is offensive and inappropriate. Further, I understand that the Family Educational Rights
              and Privacy Act (FERPA) establishes certain rights for parents regarding the privacy of their child’s
              educational record and that video recordings/streams such as Zoom, Google Classroom, and other platforms
              that may be utilized for the Virtual Learning Experience may be considered education records under certain
              circumstances and may require parental consent if a minor is under the age of 18. I, the Undersigned
              Parent or Legal Guardian expressly consent to RTSWS’s use, release and provision of access to any of
              Student’s education records protected by FERPA to RTSWS Virtual Learning Experience participants.
              Additionally, I, the Undersigned Parent or Legal Guardian, on behalf of Student and any person claiming
              through Student hereby release RTSWS from any responsibility or liability for harm of any nature that
              Student and/or any of the Releasing Parties may incur as a result of Student’s participation in RTSWS’s
              Virtual Learning Experience, including, without limitation, the release of any of Student’s personal
              information or educational records.
            </Text>

            <Text style={styles.releaseText}>
              <Text style={styles.releaseSectionTitle}>4. Hold Harmless.</Text> I, the Undersigned Parent or Legal
              Guardian shall fully release RTSWS and hold RTSWS harmless from and against any and all judgments,
              settlement payments, fines, sanctions and all other costs, liabilities, obligations and expenses
              (including, but not limited to, attorneys’ fees and expenses) incurred by RTSWS in connection with any
              action, suit, claim, allegation or proceeding (whether prosecution, defense, or otherwise) that is
              brought, made or threatened by any individual or entity in connection with any dispute or potential
              dispute arising directly or indirectly from (i) the Instruction provided by RTSWS to Student, (ii) any
              instructional materials or manuals provided to Student by RTSWS in connection with the Instruction, (iii)
              the Student’s implementation of principles and teachings learned during the Instruction, or (iv) Student’s
              use of technology.
            </Text>
            <Text style={styles.releaseText}>
              <Text style={styles.releaseSectionTitle}>5. Release:​ ​ ​LinkedIn​ ​Profile.​</Text> RTSWS requires each
              participating Student age of 16 or older to set up a LinkedIn profile as part of the educational program.
              The RTSWS LinkedIn group is a private group for the participants in the RTSWS program. The required
              information for this LinkedIn profile is an email address and name. In consideration of the opportunity
              for Student to participate in this education program, the Undersigned Parent or Legal Guardian, on behalf
              of Student and person claiming through Student, hereby releases RTSWS from any responsibility or liability
              for any harm, including without limitation, personal injury and damage to or loss of personal property
              (including financial loss), that Student or any of the Releasing Parties may incur as a result of Student
              obtaining a LinkedIn account.
            </Text>

            <Text style={styles.releaseText}>
              <Text style={styles.releaseSectionTitle}>6. Release:​ ​Mentoring​ ​Program.​</Text> Student may elect to
              participate in an additional program that pairs the Student with a Mentor. This mentoring program is a
              voluntary program that provides the Student with a female mentor who is a financial professional with the
              intent of providing career guidance for those Students interested in business careers. In consideration of
              the opportunity for Student to participate in any such mentoring program, the Undersigned Parent or Legal
              Guardian, on behalf of Student and any person claiming through Student, hereby releases RTSWS from any
              responsibility or liability for any harm, including without limitation, personal injury and damage to or
              loss of personal property (including financial loss), that Student or any of the Releasing Parties may
              incur as a result of Student’s participation in such mentoring programs, regardless of whether such harm
              is a result of RTSWS’ negligence, whether ordinary or gross.
            </Text>

            <Text style={styles.releaseText}>
              <Text style={styles.releaseSectionTitle}>7. Use of Name, Likeness or Image​.</Text> The Undersigned Parent
              or Legal Guardian acknowledges that Student may be photographed or videotaped during Instruction. The
              Undersigned Parent or Legal Guardian, on behalf of Student, hereby releases all rights to any such
              photographs or video footage and hereby agrees that RTSWS, its sponsors and third-party media may, without
              any compensation to, or additional authorization from Student and/or the Undersigned Parent or Legal
              Guardian, use Student’s name, likeness or image in connection with RTSWS’ organization and sponsors
              support of RTSWS or promotion thereof.
            </Text>
          </Box>
          <Box mb={4}>
            <Text style={styles.releaseHeader}>PARENTAL ACKNOWLEDGEMENT </Text>
            <Text style={styles.releaseText}>
              The Undersigned (each individually “Parent” and collectively “Parents”) hereby warrants and represents
              that he/she is the biological parent and/or legal guardian of _________________________ (“Minor”) and has
              the full right, power, and authority to execute this acknowledgement and fulfill all of Parent’s
              obligations contained herein. The performance by Minor is hereby guaranteed by Parent, who agrees on
              his/her own behalf and on Minor’s behalf, to the above Release and to the participation of the Minor in
              the Instruction, and shall indemnify and hold RTSWS harmless from any loss, cost or expense in the event
              of or attempt at disaffirmance of the above Release by the Minor. In addition, Parent hereby releases,
              waives, and discharges RTSWS from any and all liability, claims, demands, actions and causes of action
              whatsoever arising out of or in relation to any loss, damage, or injury (including death) that may be
              sustained by the Minor or to any property owned by the Parent or by the Minor, however caused, in relation
              to the Instruction. The Undersigned Parent and/or Legal Guardian has read, understands, agrees and accepts
              full responsibility with the statements recited above and agrees to review the above statements and
              responsibilities with Student.
            </Text>
          </Box>
          <Box mb={4}>
            <Heading size="md">Parent Agreement</Heading>
            <VStack mb={4}>
              <FormControl pt="4" pb="2">
                <CheckboxInput
                  value={parentAcknowledged}
                  onChange={setParentAcknowledged}
                  label="I accept the above statement."
                  hideLabel
                  disabled={disabled}
                  loading={loading}
                  error={validationErrors.parentAcknowledged}
                />
              </FormControl>

              <TextInput
                value={parentFirstName}
                onChange={setParentFirstName}
                label="Parent First Name"
                disabled={disabled}
                loading={loading}
                error={validationErrors.parentFirstName}
              />
              <TextInput
                value={parentLastName}
                onChange={setParentLastName}
                label="Parent Last Name"
                disabled={disabled}
                loading={loading}
                error={validationErrors.parentLastName}
              />
              <EmailInput
                value={parentEmail}
                onChange={setParentEmail}
                label="Parent Email"
                disabled={disabled}
                loading={loading}
                error={validationErrors.parentEmail}
              />
              {/* TODO: Need phone input */}
              <TextInput
                value={parentMobilePhone}
                onChange={setParentMobilePhone}
                label="Parent Mobile Phone"
                disabled={disabled}
                loading={loading}
                error={validationErrors.parentMobilePhone}
                helperText="UK Users: enter country code +44."
              />
            </VStack>
            <Box>
              <Button
                onPress={() => handleSubmit()}
                disabled={disabled}
                loading={loading}
                text="Save and Continue"
                color="primary"
              />
            </Box>
          </Box>
        </CardContainer>
      </Box>
    </Form>
  );
});
