import { Box, FormControl, VStack } from 'native-base';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ValidationError } from 'yup';
import { useAppSelector } from '../../store';
import { nativeAlert } from '../../utils/ui';
import { settingsFormSchema } from '../../validation/schemas/menu';
import { ValidationErrors } from '../../validation/types';
import { processValidationErrors } from '../../validation/utils';
import { Button, CheckboxInput } from '../ui';
import { FormLabel } from '../ui/forms/FormLabel';
import { FormProps } from '../ui/forms/types';
import { userIsVolunteer } from '../../hooks/alerts/utils';

export interface SettingsFormData {
  receivePushNotifications: boolean;
  receiveSmsNotifications: boolean;
  receiveScheduleNotifications: boolean;
}

export interface SettingsFormProps extends FormProps {
  onSubmit: (data: SettingsFormData) => void;
  onDeleteAccountPress: () => void;
}

export const SettingsForm: FC<SettingsFormProps> = ({ loading, disabled, onSubmit, onDeleteAccountPress }) => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const [receivePushNotifications, setReceivePushNotifications] = useState(false);
  const [receiveSmsNotifications, setReceiveSmsNotifications] = useState(false);
  const [receiveScheduleNotifications, setReceiveScheduleNotifications] = useState(true);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    if (currentUser) {
      setReceivePushNotifications(currentUser.receivePushNotifications);
      setReceiveSmsNotifications(currentUser.receiveSmsNotifications);
      setReceiveScheduleNotifications(!currentUser.automatedNotificationsDisabled ?? true);
    }
  }, [currentUser]);

  const isVolunteer = useMemo(() => userIsVolunteer(currentUser), [currentUser]);

  const handleSubmit = useCallback(async () => {
    try {
      setValidationErrors({});
      const validated = settingsFormSchema.validateSync(
        {
          receivePushNotifications,
          receiveSmsNotifications,
          receiveScheduleNotifications,
        },
        { abortEarly: false },
      );
      onSubmit(validated);
    } catch (err: any) {
      if (err instanceof ValidationError) {
        setValidationErrors(processValidationErrors(err));
        nativeAlert('Validation Error', 'Please check the form for errors.');
      } else {
        throw err;
      }
    }
  }, [onSubmit, receivePushNotifications, receiveSmsNotifications, receiveScheduleNotifications]);

  return (
    <VStack>
      <FormControl mb={4}>
        <FormControl.Label>
          <FormLabel label="Notification Methods" />
        </FormControl.Label>
        <CheckboxInput
          value={receiveSmsNotifications}
          onChange={(newValue) => setReceiveSmsNotifications(newValue)}
          label="Receive SMS Notifications"
          hideLabel
          disabled={disabled}
          loading={loading}
          error={validationErrors.receiveSmsNotifications}
        />
        <CheckboxInput
          value={receivePushNotifications}
          onChange={(newValue) => setReceivePushNotifications(newValue)}
          label="Receive Push Notifications"
          hideLabel
          disabled={disabled}
          loading={loading}
          error={validationErrors.receivePushNotifications}
        />
        {isVolunteer && (receivePushNotifications || receiveSmsNotifications) ? (
          <>
            <FormControl.Label mt={4}>
              <FormLabel label="Notification Type" />
            </FormControl.Label>
            <CheckboxInput
              value={receiveScheduleNotifications}
              onChange={(newValue) => setReceiveScheduleNotifications(newValue)}
              label="Receive Schedule Notifications"
              hideLabel
              disabled={disabled}
              loading={loading}
              error={validationErrors.receiveScheduleNotifications}
            />
          </>
        ) : null}
      </FormControl>

      <Box mb={2}>
        <Button
          onPress={onDeleteAccountPress}
          loading={loading}
          disabled={disabled}
          text="Delete Account"
          color="danger"
        />
      </Box>
      <Box>
        <Button onPress={() => handleSubmit()} loading={loading} disabled={disabled} text="Save" color="primary" />
      </Box>
    </VStack>
  );
};
