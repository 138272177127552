import { Ionicons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { LabelPosition } from '@react-navigation/bottom-tabs/lib/typescript/src/types';
import { createDrawerNavigator, DrawerNavigationOptions, DrawerNavigationProp } from '@react-navigation/drawer';
import {
  CompositeNavigationProp,
  CompositeScreenProps,
  NavigatorScreenParams,
  useNavigation,
} from '@react-navigation/native';
import {
  createNativeStackNavigator,
  NativeStackNavigationOptions,
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from '@react-navigation/native-stack';
import { Pressable, Text } from 'native-base';
import React, { useMemo } from 'react';
import { Image, Platform, useWindowDimensions, View } from 'react-native';
import DrawerContent from '../components/ui/DrawerContent';
import { DrawerHeaderIcon } from '../components/ui/DrawerHeaderIcon';
import { useUnreadNotifications } from '../hooks/data/useNotifications';
import { SpringEnrollmentScreen } from '../screens/auth/SpringEnrollmentScreen';
import { StudentReenrollmentScreen } from '../screens/auth/StudentReenrollmentScreen';
import { VolunteerReenrollmentScreen } from '../screens/auth/VolunteerReenrollmentScreen';
import { HomeScreen } from '../screens/home/HomeScreen';
import { SponsorsScreen } from '../screens/home/SponsorsScreen';
import { LoadingScreen } from '../screens/LoadingScreen';
import { AboutScreen } from '../screens/menu/AboutScreen';
import { ContactScreen } from '../screens/menu/ContactScreen';
import { ContactsScreen } from '../screens/menu/ContactsScreen';
import { DebugScreen } from '../screens/menu/DebugScreen';
import { DeleteAccountScreen } from '../screens/menu/DeleteAccountScreen';
import { MenuScreen } from '../screens/menu/MenuScreen';
import { SettingsScreen } from '../screens/menu/SettingsScreen';
import { StudentCEOScreen } from '../screens/menu/StudentCEOScreen';
import { NotificationScreen } from '../screens/notifications/NotificationScreen';
import { NotificationsOnboardScreen } from '../screens/notifications/NotificationsOnboardScreen';
import { NotificationsScreen } from '../screens/notifications/NotificationsScreen';
import { SmsNotificationsOnboardScreen } from '../screens/notifications/SmsNotificationsOnboardScreen';
import { ResourcesScreen } from '../screens/resources/ResourcesScreen';
import { EventScreen } from '../screens/schedule/EventScreen';
import { ScheduleScreen } from '../screens/schedule/ScheduleScreen';
import { colors } from '../styles';
import { DRAWER_VISIBILITY_BREAKPOINT, DRAWER_WIDTH, isWeb } from '../utils';
import { defaultInnerDrawerPageOptions, defaultInnerTabPageOptions, optionsWithCustomOnboardHeader } from './shared';

export type ModalScreenParamList = {
  Event: { id: string };
  Notifications: undefined;
  Notification: { id: string };
  SpringEnrollment: undefined;
  StudentReenrollment: undefined;
  VolunteerReenrollment: undefined;
};

export type AppStackParamList = {
  Loading: undefined;
  NotificationsOnboard: { continueToSmsOnboard: boolean };
  SmsNotificationsOnboard: undefined;
  Tabs: NavigatorScreenParams<TabStackParamList>;
  Drawer: NavigatorScreenParams<DrawerStackParamList>;
  RootMenu: undefined;
  Debug: undefined;
} & ModalScreenParamList;

export type AppStackScreenWithNavProps<T extends keyof AppStackParamList> = NativeStackScreenProps<
  AppStackParamList,
  T
>;

export type DrawerStackParamList = {
  HomeStack: NavigatorScreenParams<HomeStackParamList>;
  ScheduleStack: NavigatorScreenParams<ScheduleStackParamList>;
  ResourcesStack: NavigatorScreenParams<ResourcesStackParamList>;
  MenuStack: NavigatorScreenParams<MenuStackParamList>;
} & ModalScreenParamList;

export type DrawerStackNavigationProp<T extends keyof DrawerStackParamList> = CompositeNavigationProp<
  DrawerNavigationProp<DrawerStackParamList, T>,
  NativeStackNavigationProp<AppStackParamList>
>;

export type TabStackParamList = {
  HomeStack: NavigatorScreenParams<HomeStackParamList>;
  ScheduleStack: NavigatorScreenParams<ScheduleStackParamList>;
  ResourcesStack: NavigatorScreenParams<ResourcesStackParamList>;
  MenuStack: NavigatorScreenParams<MenuStackParamList>;
};

export type TabStackNavigationProp<T extends keyof TabStackParamList> = CompositeNavigationProp<
  NativeStackNavigationProp<TabStackParamList, T>,
  NativeStackNavigationProp<AppStackParamList>
>;

export type HomeStackParamList = {
  Home: undefined;
  Sponsors: undefined;
};

export type HomeStackScreenWithNavProps<T extends keyof HomeStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<HomeStackParamList, T>,
  NativeStackScreenProps<AppStackParamList>
>;

export type ScheduleStackParamList = {
  Schedule: undefined;
};

export type ScheduleStackScreenWithNavProps<T extends keyof ScheduleStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<ScheduleStackParamList, T>,
  NativeStackScreenProps<AppStackParamList>
>;

export type ResourcesStackParamList = {
  Resources: undefined;
};

export type ResourcesStackScreenWithNavProps<T extends keyof ResourcesStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<ResourcesStackParamList, T>,
  NativeStackScreenProps<AppStackParamList>
>;

export type MenuStackParamList = {
  Menu: undefined;
  Settings: undefined;
  Contact: { id: string };
  Contacts: undefined;
  About: undefined;
  StudentCEO: undefined;
  Debug: undefined;
  DeleteAccount: undefined;
};

export type MenuStackScreenWithNavProps<T extends keyof MenuStackParamList> = CompositeScreenProps<
  NativeStackScreenProps<MenuStackParamList, T>,
  NativeStackScreenProps<AppStackParamList>
>;

const defaultTabStackScreenOptions: NativeStackNavigationOptions & DrawerNavigationOptions = {
  headerLeft: () => {
    const dimensions = useWindowDimensions();

    return isWeb && dimensions.width < DRAWER_VISIBILITY_BREAKPOINT ? <DrawerHeaderIcon /> : null;
  },
  headerTitle: () => {
    const dimensions = useWindowDimensions();
    const isSmallScreen = dimensions.width < DRAWER_VISIBILITY_BREAKPOINT;
    const drawerWidth = isSmallScreen ? 0 : DRAWER_WIDTH;
    const rightOffset = 80 + 32; // 80 is the width of the header buttons, 32 is the title margin

    return Platform.select({
      default: (
        <View style={{ width: '80%', height: 35 }}>
          <Image
            source={require('../../assets/images/homepage_background_white.png')}
            style={{ width: '100%', resizeMode: 'contain', height: 35 }}
          />
        </View>
      ),
      android: (
        <View style={{ width: '100%', height: 35 }}>
          <Image
            source={require('../../assets/images/homepage_background_white.png')}
            style={{ width: '100%', resizeMode: 'contain', height: 35 }}
          />
        </View>
      ),
      web: (
        <View style={{ width: `calc(100vw - ${drawerWidth}px - ${rightOffset}px)`, height: 35 }}>
          <Image
            source={require('../../assets/images/homepage_background_white.png')}
            style={{ width: '100%', resizeMode: 'contain', height: 35 }}
          />
        </View>
      ),
    });
  },
  headerRight: () => {
    const { notifications } = useUnreadNotifications();
    const navigation = useNavigation<TabStackNavigationProp<'HomeStack'>>();

    const hasUnread = useMemo(() => notifications.length > 0, [notifications]);

    return (
      <View style={Platform.select({ web: { marginRight: 12 } })}>
        <Pressable onPress={() => navigation.navigate('Notifications')}>
          <Ionicons name="notifications-outline" size={24} color={colors.primary} />
          {hasUnread && (
            <View
              style={{
                backgroundColor: colors.alert,
                width: 10,
                height: 10,
                position: 'absolute',
                right: 0,
                borderRadius: 5,
              }}
            />
          )}
        </Pressable>
      </View>
    );
  },
  headerTitleStyle: {
    fontFamily: 'Avenir-Bold',
  },
  headerBackTitleStyle: {
    fontFamily: 'Avenir-Regular',
  },
};

const HomeStack = createNativeStackNavigator<HomeStackParamList>();
const HomeStackScreen: React.FC = () => {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen name="Home" component={HomeScreen} options={defaultTabStackScreenOptions} />
      <HomeStack.Screen
        name="Sponsors"
        component={SponsorsScreen}
        options={{ ...defaultInnerTabPageOptions, headerTitle: 'Sponsors' }}
      />
    </HomeStack.Navigator>
  );
};

const ScheduleStack = createNativeStackNavigator<ScheduleStackParamList>();
const ScheduleStackScreen: React.FC = () => {
  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen name="Schedule" component={ScheduleScreen} options={defaultTabStackScreenOptions} />
    </ScheduleStack.Navigator>
  );
};

const ResourcesStack = createNativeStackNavigator<ResourcesStackParamList>();
const ResourcesStackScreen: React.FC = () => {
  return (
    <ResourcesStack.Navigator>
      <ResourcesStack.Screen name="Resources" component={ResourcesScreen} options={defaultTabStackScreenOptions} />
    </ResourcesStack.Navigator>
  );
};

const MenuStack = createNativeStackNavigator<MenuStackParamList>();
const MenuStackScreen: React.FC = () => {
  return (
    <MenuStack.Navigator>
      <MenuStack.Screen name="Menu" component={MenuScreen} options={defaultInnerTabPageOptions} />
      <MenuStack.Screen name="Settings" component={SettingsScreen} options={defaultInnerTabPageOptions} />
      <MenuStack.Screen name="Contact" component={ContactScreen} options={defaultInnerTabPageOptions} />
      <MenuStack.Screen name="Contacts" component={ContactsScreen} options={defaultInnerTabPageOptions} />
      <MenuStack.Screen name="About" component={AboutScreen} options={defaultInnerTabPageOptions} />
      <MenuStack.Screen
        name="StudentCEO"
        component={StudentCEOScreen}
        options={{ ...defaultInnerTabPageOptions, title: 'Student CEO' }}
      />
      <MenuStack.Screen name="Debug" component={DebugScreen} options={defaultInnerTabPageOptions} />
      <MenuStack.Screen
        name="DeleteAccount"
        component={DeleteAccountScreen}
        options={{ ...defaultInnerTabPageOptions, title: 'Delete Your Account' }}
      />
    </MenuStack.Navigator>
  );
};

const renderTabBarLabel =
  (label: string) =>
  ({ focused, position }: { focused: boolean; position: LabelPosition }) =>
    (
      <Text
        fontSize={11}
        color={focused ? colors.secondary : colors.darkGrey}
        fontWeight={focused ? 'bold' : 'normal'}
        marginLeft={position === 'beside-icon' ? 5 : 0}
        marginTop={position === 'beside-icon' ? 2 : 0}
      >
        {label}
      </Text>
    );

const renderTabBarIcon =
  (name: string) =>
  ({ size, color }: { size: number; color: string }) =>
    <Ionicons name={name as any} size={24} color={color} />;

const TabsStack = createBottomTabNavigator<TabStackParamList>();
const Tabs: React.FC = () => {
  return (
    <TabsStack.Navigator
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: colors.primary,
        tabBarInactiveTintColor: colors.secondary,
      }}
    >
      <TabsStack.Screen
        name="HomeStack"
        component={HomeStackScreen}
        options={{
          tabBarLabel: renderTabBarLabel('Home'),
          tabBarIcon: renderTabBarIcon('home-outline'),
        }}
      />
      <TabsStack.Screen
        name="ScheduleStack"
        component={ScheduleStackScreen}
        options={{ tabBarLabel: renderTabBarLabel('Schedule'), tabBarIcon: renderTabBarIcon('calendar-outline') }}
      />
      <TabsStack.Screen
        name="ResourcesStack"
        component={ResourcesStackScreen}
        options={{ tabBarLabel: renderTabBarLabel('Resources'), tabBarIcon: renderTabBarIcon('document-text-outline') }}
      />
      <TabsStack.Screen
        name="MenuStack"
        component={MenuStackScreen}
        options={{ tabBarLabel: renderTabBarLabel('Menu'), tabBarIcon: renderTabBarIcon('menu-outline') }}
        listeners={(params) => ({
          tabPress: (e) => {
            e.preventDefault();
            params.navigation.navigate('RootMenu');
          },
        })}
      />
    </TabsStack.Navigator>
  );
};

const Drawer = createDrawerNavigator<DrawerStackParamList>();

function DrawerComponent() {
  const dimensions = useWindowDimensions();

  return (
    <Drawer.Navigator
      drawerContent={(props) => <DrawerContent {...props} />}
      screenOptions={{
        drawerHideStatusBarOnOpen: true,
        drawerType: dimensions.width >= DRAWER_VISIBILITY_BREAKPOINT ? 'permanent' : 'slide',
        drawerStyle: {
          width: DRAWER_WIDTH,
        },
      }}
    >
      <Drawer.Screen name="HomeStack" component={HomeStackScreen} options={{ headerShown: false }} />
      <Drawer.Screen name="ScheduleStack" component={ScheduleStackScreen} options={{ headerShown: false }} />
      <Drawer.Screen name="ResourcesStack" component={ResourcesStackScreen} options={{ headerShown: false }} />
      <Drawer.Screen name="MenuStack" component={MenuStackScreen} options={{ headerShown: false }} />
      <Drawer.Screen name="Event" component={EventScreen} options={defaultInnerDrawerPageOptions} />
      <Drawer.Screen name="Notifications" component={NotificationsScreen} options={defaultInnerDrawerPageOptions} />
      <Drawer.Screen name="Notification" component={NotificationScreen} options={defaultInnerDrawerPageOptions} />
      <Drawer.Screen
        name="SpringEnrollment"
        component={SpringEnrollmentScreen}
        options={{ ...defaultTabStackScreenOptions, title: 'Spring Enrollment' }}
      />
      <Drawer.Screen
        name="StudentReenrollment"
        component={StudentReenrollmentScreen}
        options={{ ...defaultTabStackScreenOptions, title: 'Student Re-enrollment' }}
      />
      <Drawer.Screen
        name="VolunteerReenrollment"
        component={VolunteerReenrollmentScreen}
        options={{ ...defaultTabStackScreenOptions, title: 'Volunteer Re-enrollment' }}
      />
    </Drawer.Navigator>
  );
}

const AppStack = createNativeStackNavigator<AppStackParamList>();

export const AppNavigation: React.FC = () => {
  return (
    <AppStack.Navigator initialRouteName="Loading">
      <AppStack.Group screenOptions={{ animation: 'fade', headerShown: false }}>
        <AppStack.Screen name="Loading" component={LoadingScreen} />
      </AppStack.Group>
      <AppStack.Group screenOptions={{ headerShown: false }}>
        <AppStack.Screen
          name="NotificationsOnboard"
          component={NotificationsOnboardScreen}
          options={optionsWithCustomOnboardHeader}
        />
        <AppStack.Screen
          name="SmsNotificationsOnboard"
          component={SmsNotificationsOnboardScreen}
          options={optionsWithCustomOnboardHeader}
        />
      </AppStack.Group>
      {isWeb ? (
        <AppStack.Screen name="Drawer" component={DrawerComponent} options={{ headerShown: false }} />
      ) : (
        <AppStack.Screen
          name="Tabs"
          component={Tabs}
          options={{ headerShown: false, gestureEnabled: false, headerLeft: () => <View /> }}
        />
      )}
      {!isWeb ? (
        <AppStack.Group screenOptions={{ presentation: 'modal' }}>
          <AppStack.Screen name="Event" component={EventScreen} options={defaultInnerTabPageOptions} />
          <AppStack.Screen name="Notifications" component={NotificationsScreen} options={defaultInnerTabPageOptions} />
          <AppStack.Screen name="Notification" component={NotificationScreen} options={defaultInnerTabPageOptions} />
          <AppStack.Screen
            name="SpringEnrollment"
            component={SpringEnrollmentScreen}
            options={{ ...defaultInnerTabPageOptions, title: 'Spring Enrollment' }}
          />
          <AppStack.Screen
            name="StudentReenrollment"
            component={StudentReenrollmentScreen}
            options={{ ...defaultInnerTabPageOptions, title: 'Student Re-enrollment' }}
          />
          <AppStack.Screen
            name="VolunteerReenrollment"
            component={VolunteerReenrollmentScreen}
            options={{ ...defaultInnerTabPageOptions, title: 'Volunteer Re-enrollment' }}
          />
          <AppStack.Screen name="RootMenu" component={MenuStackScreen} options={{ headerShown: false }} />
          <MenuStack.Screen name="Debug" component={DebugScreen} options={defaultInnerTabPageOptions} />
        </AppStack.Group>
      ) : null}
    </AppStack.Navigator>
  );
};
