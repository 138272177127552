import { startCase } from 'lodash';
import { ValidationError } from 'yup';
import { ValidationErrors } from './types';

export const processValidationErrors = (err: ValidationError): ValidationErrors => {
  return err.inner.reduce((acc, error) => {
    if (!error.path) return acc;

    const existing = acc[error.path];

    // Format the validation error with replace() global RegEx instead of replaceAll() due to Android compatibility
    const regex = new RegExp(error.path, 'g');
    const message = error.message.replace(regex, startCase(error.path));

    const errors = existing ? [...existing, message] : [message];

    return { ...acc, [error.path]: errors };
  }, {} as Record<string, any>);
};
