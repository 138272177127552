import { useMemo } from 'react';
import { SelectInputOption } from '../../components/ui';

export const useGenderIdentificationSelectOptions = () => {
  const genderIdentificationSelectOptions: SelectInputOption[] = useMemo(() => {
    return [
      {
        label: 'I identify as female.',
        value: 'i_identify_as_female',
      },
      {
        label: 'I identify as non-binary',
        value: 'i_identify_as_non_binary',
      },
      {
        label: 'I do not identify as female',
        value: 'i_do_not_identify_as_female',
      },
    ];
  }, []);

  return { genderIdentificationSelectOptions };
};
