export enum RegistrationTypes {
  STUDENT = 'student',
  VOLUNTEER = 'volunteer',
}

export enum RegistrationStatus {
  PENDING = 'pending',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export const REGISTRATION_OTHER = 'other';

export enum Race {
  REGISTRATION_RACE_AMERICAN_INDIAN = 'American Indian or Alaska Native',
  REGISTRATION_RACE_ASIAN = 'Asian',
  REGISTRATION_RACE_BLACK = 'Black or African American',
  REGISTRATION_RACE_HISPANIC = 'Hispanic or Latinx',
  REGISTRATION_RACE_NATIVE_HAWAIIAN = 'Native Hawaiian or Other Pacific Islander',
  REGISTRATION_RACE_WHITE = 'White',
  REGISTRATION_RACE_TWO_OR_MORE = 'Two or More Races',
  REGISTRATION_RACE_OTHER_OR_NOT_LISTED = 'Other/Not Listed',
  REGISTRATION_RACE_NO_DISCLOSURE = 'Choose Not to Disclose',
}
