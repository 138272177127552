import { Box } from 'native-base';
import React, { useMemo } from 'react';
import { FeedItem } from '../../models/view';
import { Card, LinkCard } from '../ui';

export interface FeedItemListProps {
  feedItems: FeedItem[];
}

export interface FeedItemListItemProps {
  feedItem: FeedItem;
}

export const FeedItemListItem: React.FC<FeedItemListItemProps> = ({ feedItem }) => {
  const isLink = useMemo(() => {
    return feedItem.actionLink && !feedItem.description && feedItem.actionLinkType === 'URL';
  }, [feedItem]);

  if (isLink) {
    return (
      <LinkCard actionText={feedItem.actionText || 'Click Here'} title={feedItem.title} url={feedItem.actionLink} />
    );
  }

  return (
    <Card
      title={feedItem.title}
      imageUrl={feedItem.imageUrl}
      imageAlt={feedItem.title}
      content={feedItem.description}
      actionLink={feedItem.actionLink}
      actionLinkType={feedItem.actionLinkType}
      actionText={feedItem.actionText || 'Read More'}
      actionColor={feedItem.actionColor}
    />
  );
};

export const FeedItemList: React.FC<FeedItemListProps> = ({ feedItems }) => {
  return (
    <Box>
      {feedItems.map((feedItem) => (
        <FeedItemListItem key={feedItem.id} feedItem={feedItem} />
      ))}
    </Box>
  );
};
