import { Flex, Image, Stack, Text } from 'native-base';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { Sponsor } from '../../models/view';
import { colors } from '../../styles';
import { DRAWER_WIDTH, isWeb, MAX_WEB_CONTAINER_WIDTH, SMALL_SCREEN_SIZE_BREAKPOINT } from '../../utils';

const SponsorLogo: React.FC<SponsorItemProps> = ({ sponsor }) => {
  return <Image height="90%" width="90%" source={{ uri: sponsor.imageUrl }} alt={sponsor.name} resizeMode="contain" />;
};

const SponsorName: React.FC<SponsorItemProps> = ({ sponsor }) => {
  return (
    <Stack alignItems="center" justifyContent="center" height="100%" width="100%">
      <Text fontSize={24} fontWeight="bold" color={colors.black} textAlign="center">
        {sponsor.name}
      </Text>
    </Stack>
  );
};

export interface SponsorItemProps {
  sponsor: Sponsor;
  gap?: number;
}

export const SponsorItem: React.FC<SponsorItemProps> = ({ sponsor, gap = 24 }) => {
  const { width: screenWidth } = useWindowDimensions();
  const isSmall = screenWidth < SMALL_SCREEN_SIZE_BREAKPOINT;
  const size =
    (() => {
      if (isSmall) {
        return screenWidth / 2;
      }

      if (isWeb) {
        if (screenWidth >= MAX_WEB_CONTAINER_WIDTH + DRAWER_WIDTH) {
          return MAX_WEB_CONTAINER_WIDTH / 4;
        } else {
          return (screenWidth - DRAWER_WIDTH) / 4;
        }
      }

      return screenWidth / 4;
    })() - gap;

  return (
    <Flex
      width="100%"
      maxWidth={size}
      height={size}
      maxHeight={size}
      mb="4"
      justifyContent="center"
      alignItems="center"
    >
      {sponsor.imageUrl ? <SponsorLogo sponsor={sponsor} /> : <SponsorName sponsor={sponsor} />}
    </Flex>
  );
};
