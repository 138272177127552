import { useMemo } from 'react';
import { SelectInputOption } from '../../components/ui';
import { REGISTRATION_OTHER } from '../../constants';
import { School } from '../../models/view';
import { useAppSelector } from '../../store';

export const useSchoolAccounts = () => {
  const { schoolAccounts: schoolAccountsData, loading, error } = useAppSelector((state) => state.accounts);

  const schoolAccounts: School[] = useMemo(() => {
    return schoolAccountsData
      .map((school) => ({
        id: school.id,
        name: school.name,
        imageUrl: school.logoUrl,
        sfid: school.sfid,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [schoolAccountsData]);

  return { schoolAccounts, loading, error };
};

export const useSchoolAccountSelectOptions = () => {
  const { schoolAccounts } = useSchoolAccounts();

  const schoolAccountSelectOptions: SelectInputOption[] = useMemo(() => {
    return schoolAccounts
      .map((school) => ({
        label: school.name,
        value: school.sfid,
      }))
      .concat({
        label: 'Other',
        value: REGISTRATION_OTHER,
      });
  }, [schoolAccounts]);

  return { schoolAccountSelectOptions };
};
