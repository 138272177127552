import { useMemo } from 'react';
import { SelectInputOption } from '../../components/ui';

export const useDateSelectOptions = () => {
  const monthOptions: SelectInputOption[] = useMemo(() => {
    return [
      {
        label: 'January',
        value: '1',
      },
      {
        label: 'February',
        value: '2',
      },
      {
        label: 'March',
        value: '3',
      },
      {
        label: 'April',
        value: '4',
      },
      {
        label: 'May',
        value: '5',
      },
      {
        label: 'June',
        value: '6',
      },
      {
        label: 'July',
        value: '7',
      },
      {
        label: 'August',
        value: '8',
      },
      {
        label: 'September',
        value: '9',
      },
      {
        label: 'October',
        value: '10',
      },
      {
        label: 'November',
        value: '11',
      },
      {
        label: 'December',
        value: '12',
      },
    ];
  }, []);

  const dateOptions: SelectInputOption[] = useMemo(() => {
    return Array.from({ length: 31 }, (_, i) => {
      return {
        label: String(i + 1),
        value: String(i + 1),
      };
    });
  }, []);

  const yearOptions: SelectInputOption[] = useMemo(() => {
    const year = new Date().getFullYear();

    return Array.from({ length: 100 }, (_, i) => {
      return {
        label: String(year - i),
        value: String(year - i),
      };
    });
  }, []);

  return { monthOptions, dateOptions, yearOptions };
};
