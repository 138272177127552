export enum ProgramTeams {
  PROGRAM_TEAM_ONE = 'Program Team 1',
  PROGRAM_TEAM_TWO = 'Program Team 2',
  PROGRAM_TEAM_THREE = 'Program Team 3',
  PROGRAM_TEAM_FOUR = 'Program Team 4',
  PROGRAM_TEAM_FIVE = 'Program Team 5',
  PROGRAM_TEAM_SIX = 'Program Team 6',
  PROGRAM_TEAM_SEVEN = 'Program Team 7',
  PROGRAM_TEAM_EIGHT = 'Program Team 8',
}
