import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Setting } from '../../models/data';
import { settingService } from '../../services';
import { commonActions } from '../commonActions';
import { SettingState } from './types';

const getSettings = createAsyncThunk('settings/getSettings', async () => {
  return settingService.getSettings();
});

const initialState: SettingState = {
  currentSetting: null,
  settings: [],
  loading: false,
  error: null,
};

export const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setSetting(state, { payload: { setting } }: PayloadAction<{ setting: Setting }>) {
      state.currentSetting = setting;
    },

    setSettings(state, { payload: { settings } }: PayloadAction<{ settings: Setting[] }>) {
      state.settings = settings;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load settings.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });
  },
});

export const settingActions = { ...settingSlice.actions, getSettings };

export type SettingSlice = {
  [settingSlice.name]: ReturnType<typeof settingSlice['reducer']>;
};
