import { Box, Heading, HStack, Image, Modal, Text, VStack } from 'native-base';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RegistrationStatus, RoleNames } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActions } from '../../store/actions';
import { Button, CheckboxInput } from '../ui';

export const WelcomeMessage: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const [open, setOpen] = useState(true);
  const [turnOff, setTurnOff] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  const dismiss = useCallback(() => {
    setOpen(false);

    if (!currentUser) {
      return;
    }

    if (turnOff) {
      dispatch(authActions.updateCurrentUser({ ...currentUser, showWelcome: false }));
    }
  }, [currentUser, dispatch, turnOff]);

  const firstName = useMemo(() => {
    return currentUser?.firstName || '';
  }, [currentUser]);

  const registrationPending = useMemo(() => {
    return currentUser?.registration?.status === RegistrationStatus.PENDING;
  }, [currentUser]);

  const logoUrl = useMemo(() => {
    switch (currentUser?.role.name) {
      case RoleNames.STUDENT:
        return currentUser?.registeredSchoolAccount?.logoUrl;
      case RoleNames.VOLUNTEER:
        return currentUser?.companyAccount?.logoUrl;
    }
  }, [currentUser]);

  useEffect(() => {
    if (!logoUrl) {
      setLoadingImage(false);
    } else {
      const loadImage = async () => {
        setLoadingImage(true);

        try {
          await Image.prefetch(logoUrl);
          setImageLoaded(true);
        } catch (err) {
          // Do nothing
        } finally {
          setLoadingImage(false);
        }
      };

      loadImage();
    }
  }, [logoUrl]);

  if (!currentUser) {
    return null;
  }

  if (loadingImage) {
    return null;
  }

  if (!currentUser.showWelcome) {
    return null;
  }

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} size="lg">
      <Modal.Content>
        <Modal.Body borderWidth={0}>
          <VStack>
            <Heading textAlign="center" mb={4}>
              Welcome to Rock The Street, Wall Street, {firstName}!
            </Heading>
            {registrationPending && (
              <Text textAlign="center" mb={4}>
                Your registration is being processed.
              </Text>
            )}
            {logoUrl && imageLoaded && (
              <HStack
                alignItems="center"
                justifyContent="center"
                alignSelf="center"
                style={{ width: 200, height: 200 }}
                mb={4}
              >
                <Image width="100%" height="100%" source={{ uri: logoUrl }} resizeMode="contain" alt="Welcome Logo" />
              </HStack>
            )}
            <Box mb={4}>
              <CheckboxInput value={turnOff} onChange={setTurnOff} label="Don't Show This Again" hideLabel center />
            </Box>
            <Box w="100%">
              <Button text="Close" onPress={dismiss} color="primary" />
            </Box>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
