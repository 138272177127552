import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { RedirectScreen } from '../screens/auth/RedirectScreen';
import { DebugScreen } from '../screens/menu/DebugScreen';
import { StartScreen } from '../screens/StartScreen';
import { defaultInnerTabPageOptions } from './shared';

export type AuthStackParamList = {
  Redirect: undefined;
  Start: undefined;
  Debug: undefined;
};

export type AuthStackScreenWithNavProps<T extends keyof AuthStackParamList> = NativeStackScreenProps<
  AuthStackParamList,
  T
>;

const AuthStack = createNativeStackNavigator<AuthStackParamList>();

export const AuthNavigation = () => {
  return (
    <AuthStack.Navigator initialRouteName="Start" screenOptions={{ gestureEnabled: false }}>
      <AuthStack.Group screenOptions={{ animation: 'fade', headerShown: false }}>
        <AuthStack.Screen name="Redirect" component={RedirectScreen} options={{ headerShown: false }} />
        <AuthStack.Screen name="Start" component={StartScreen} options={{ headerShown: false }} />
      </AuthStack.Group>
      <AuthStack.Group screenOptions={{ presentation: 'modal' }}>
        <AuthStack.Screen name="Debug" component={DebugScreen} options={defaultInnerTabPageOptions} />
      </AuthStack.Group>
    </AuthStack.Navigator>
  );
};
