import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from './types';

const initialState: AppState = {
  loading: true,
  appState: 'active',
  intentionallyBackgrounded: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    updateAppState(
      state,
      { payload: { newValue } }: PayloadAction<{ newValue: 'active' | 'inactive' | 'background' }>,
    ) {
      state.appState = newValue;
    },

    updateIntentionallyBackgrounded(state, { payload: { newValue } }: PayloadAction<{ newValue: boolean }>) {
      state.intentionallyBackgrounded = newValue;
    },
  },
});

export const appActions = appSlice.actions;

export type AppSlice = {
  [appSlice.name]: ReturnType<typeof appSlice['reducer']>;
};
