import { Box } from 'native-base';
import React from 'react';
import { useAdditionalLinks } from '../../hooks/data/useAdditionalLinks';
import { Link } from '../../models/view';
import { colors } from '../../styles';
import { AltLinkCard, Section } from '../ui';

export interface AdditionalLinksListItemProps {
  link: Link;
}

export const AdditionalLinksListItem: React.FC<AdditionalLinksListItemProps> = ({ link }) => {
  return <AltLinkCard iconName={link.iconName} title={link.title} url={link.url} iconColor={colors.primary} />;
};

export interface AdditionalLinksListProps {}

export const AdditionalLinksList: React.FC<AdditionalLinksListProps> = () => {
  const { additionalLinks } = useAdditionalLinks();

  if (additionalLinks.length === 0) {
    return null;
  }

  return (
    <Section title="Additional Links" hideIcon>
      <Box>
        {additionalLinks.map((link) => (
          <Box mb={-2} key={link.id}>
            <AdditionalLinksListItem link={link} />
          </Box>
        ))}
      </Box>
    </Section>
  );
};
