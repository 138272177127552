import { useMemo } from 'react';
import { CurrentUser } from '../../models/view';
import { useAppSelector } from '../../store';

export const useCurrentUser = () => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const user: CurrentUser = useMemo(() => {
    return {
      id: currentUser?.id ?? '',
      firstName: currentUser?.firstName ?? '',
      lastName: currentUser?.lastName ?? '',
      email: currentUser?.email ?? '',
      registration: {
        race: currentUser?.registration?.race ?? '',
        dateOfBirth: currentUser?.registration?.dateOfBirth ?? '',
        collegeAttended: currentUser?.registration?.collegeAttended ?? '',
        designation: currentUser?.registration?.designation ?? '',
        hundredWomenInFinance: currentUser?.registration?.hundredWomenInFinance ?? false,
      },
    };
  }, [currentUser]);

  return { user };
};
