import * as yup from 'yup';
import { yupPhone } from '../../rules';
import { emailField } from './common';

export const studentRegistrationFormSchema = yup.object({
  type: yup.string().required(),
  schoolAccountSfid: yup.string().required('Please select a school'),
  firstName: yup.string().required(),
  middleInitial: yup.string(),
  lastName: yup.string().required(),
  mobilePhone: yupPhone.string().phone().formatPhone().required(),
  email: emailField,
  alternateEmail: yup.string().email('Please provide a valid email address'),
  addressLine1: yup.string().required(),
  addressLine2: yup.string(),
  addressCity: yup.string().required(),
  addressState: yup.string().required(),
  addressStateOther: yup.string().test(
    'address-state-other-required',
    () => `Required when State is "Other"`,
    (value, context) => {
      return context.parent.addressState?.toLowerCase() !== 'other' || !!value;
    },
  ),
  addressCountry: yup.string().required(),
  addressZip: yup.string().required(),
  dateOfBirth: yup
    .string()
    .required()
    .test('at-least-13-years-old', 'Must be at least 13 years old', (value) => {
      if (!value) {
        return false;
      }

      const dateOfBirth = new Date(value);
      const thirteenYearsAgo = new Date();
      thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 13);

      return dateOfBirth <= thirteenYearsAgo;
    }),
  graduationYear: yup.string().required(),
  genderIdentification: yup.string().required(),
  race: yup.string().required(),
  referrer: yup.string().required(),
});
