import { Box } from 'native-base';
import React from 'react';
import { ContactsList } from '../../components/menu/ContactsList';
import { MenuStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { isWeb } from '../../utils';

export const ContactsScreen: React.FC<MenuStackScreenWithNavProps<'Contacts'>> = ({ navigation }) => {
  return (
    <Box flex={1} alignItems={isWeb ? 'center' : undefined}>
      <ContactsList onItemPress={(contact) => navigation.navigate('Contact', { id: contact.id })} />
    </Box>
  );
};
