import { Ionicons } from '@expo/vector-icons';
import { Box, Heading, Pressable, Stack, Text } from 'native-base';
import React from 'react';
import { ActivityIndicator } from 'react-native';
import { IconTitle, PageWrapper } from '../../components/ui';
import { CardContainer } from '../../components/ui/cards/CardContainer';
import { useContact } from '../../hooks';
import { MenuStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { colors } from '../../styles';
import { openEmail, openPhone } from '../../utils';

export const ContactScreen: React.FC<MenuStackScreenWithNavProps<'Contact'>> = ({ navigation, route }) => {
  const { contact } = useContact(route.params?.id);

  if (!contact) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <ActivityIndicator color={colors.darkGrey} />
      </Stack>
    );
  }

  return (
    <PageWrapper>
      <Box p={4}>
        <Box mb={4}>
          <Heading>
            {contact?.firstName} {contact?.lastName}
          </Heading>
          {contact?.title && <Heading size="sm">{contact?.title}</Heading>}
        </Box>
        <Box>
          {contact?.email && (
            <Box mb={-2}>
              <Pressable onPress={() => openEmail(contact.email)}>
                <CardContainer>
                  <IconTitle
                    title="Email"
                    variant="title"
                    mb={0}
                    renderIcon={(color) => <Ionicons name="mail-outline" color={color} size={20} />}
                  />
                  <Text ml={7}>{contact?.email}</Text>
                </CardContainer>
              </Pressable>
            </Box>
          )}
          {contact?.phone && (
            <Box mb={-2}>
              <Pressable onPress={() => openPhone(contact.phone)}>
                <CardContainer>
                  <IconTitle
                    title="Phone"
                    variant="title"
                    mb={0}
                    renderIcon={(color) => <Ionicons name="phone-portrait-outline" color={color} size={20} />}
                  />
                  <Text ml={7}>{contact?.phone}</Text>
                </CardContainer>
              </Pressable>
            </Box>
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};
