import { Box, FormControl, Heading, HStack, VStack, WarningOutlineIcon } from 'native-base';
import React, { useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import { RegistrationTypes } from '../../constants/Registration';
import {
  useDateSelectOptions,
  useGenderIdentificationSelectOptions,
  useRaceSelectOptions,
  useSchoolAccountSelectOptions,
  useSponsorSelectOptions,
  useStateSelectOptions,
} from '../../hooks';
import { useCountrySelectOptions } from '../../hooks/data/useCountryData';
import { useAppSelector } from '../../store';
import { VolunteerRegistrationData } from '../../store/registration/types';
import { nativeAlert } from '../../utils/ui';
import { volunteerRegistrationFormSchema } from '../../validation/schemas/auth';
import { ValidationErrors } from '../../validation/types';
import { processValidationErrors } from '../../validation/utils';
import { Button } from '../ui';
import { CardContainer } from '../ui/cards/CardContainer';
import { EmailInput, Form, SelectInput, TextInput } from '../ui/forms';
import { CheckboxInput } from '../ui/forms/inputs/CheckboxInput';
import { FormProps } from '../ui/forms/types';

export interface VolunteerRegistrationFormProps extends FormProps {
  onSubmit: (data: VolunteerRegistrationData) => void;
}

export const VolunteerRegistrationForm: React.FC<VolunteerRegistrationFormProps> = React.memo(
  ({ disabled, loading, onSubmit }) => {
    const authState = useAppSelector((state) => state.auth);
    const { currentUser } = authState;

    const { schoolAccountSelectOptions } = useSchoolAccountSelectOptions();
    const { sponsorSelectOptions } = useSponsorSelectOptions();
    const { stateSelectOptions } = useStateSelectOptions();
    const { countrySelectOptions } = useCountrySelectOptions();
    const { dateOptions, monthOptions, yearOptions } = useDateSelectOptions();
    const { genderIdentificationSelectOptions } = useGenderIdentificationSelectOptions();
    const { raceSelectOptions } = useRaceSelectOptions();

    const [schoolAccountSfid, setSchoolAccountSfid] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobilePhone, setMobilePhone] = useState('');
    const [alternateEmail, setAlternateEmail] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [addressCity, setAddressCity] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressStateOther, setAddressStateOther] = useState('');
    const [addressCountry, setAddressCountry] = useState('');
    const [addressZip, setAddressZip] = useState('');
    const [dateOfBirthMonth, setDateOfBirthMonth] = useState('');
    const [dateOfBirthDay, setDateOfBirthDay] = useState('');
    const [dateOfBirthYear, setDateOfBirthYear] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [designation, setDesignation] = useState('');
    const [hundredWomenInFinance, setHundredWomenInFinance] = useState(false);
    const [collegeAttended, setCollegeAttended] = useState('');
    const [race, setRace] = useState('');
    const [genderIdentification, setGenderIdentification] = useState('');

    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

    useEffect(() => {
      if (dateOfBirthDay && dateOfBirthMonth && dateOfBirthYear) {
        const dateString = `${dateOfBirthYear}-${dateOfBirthMonth.padStart(2, '0')}-${dateOfBirthDay.padStart(2, '0')}`;
        setDateOfBirth(dateString);
      }
    }, [dateOfBirthDay, dateOfBirthMonth, dateOfBirthYear]);

    const handleSubmit = () => {
      const data: VolunteerRegistrationData = {
        type: RegistrationTypes.VOLUNTEER,
        email: currentUser ? currentUser.email : '',
        schoolAccountSfid,
        firstName,
        lastName,
        mobilePhone,
        alternateEmail,
        addressLine1,
        addressLine2,
        addressCity,
        addressState,
        addressStateOther,
        addressCountry,
        addressZip,
        dateOfBirth,
        race,
        company,
        designation,
        title,
        hundredWomenInFinance,
        collegeAttended,
        genderIdentification,
      };

      try {
        setValidationErrors({});
        const validated = volunteerRegistrationFormSchema.validateSync(data, { abortEarly: false });
        onSubmit(validated);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          if (err instanceof ValidationError) {
            setValidationErrors(processValidationErrors(err));
            nativeAlert('Validation Error', 'Please check the form for errors.');
          } else {
            throw err;
          }
        }
      }
    };

    return (
      <Form>
        <Box p={4}>
          <Heading mb={4}>Volunteer Registration</Heading>
          <CardContainer>
            <VStack mb={4}>
              <Heading size="md">Basic Information</Heading>
              <SelectInput
                label="School Name"
                value={schoolAccountSfid}
                options={schoolAccountSelectOptions}
                onChange={setSchoolAccountSfid}
                disabled={disabled}
                loading={loading}
                error={validationErrors.schoolAccountSfid}
              />
              <TextInput
                value={firstName}
                onChange={setFirstName}
                label="First Name"
                disabled={disabled}
                loading={loading}
                error={validationErrors.firstName}
              />
              <TextInput
                value={lastName}
                onChange={setLastName}
                label="Last Name"
                disabled={disabled}
                loading={loading}
                error={validationErrors.lastName}
              />
            </VStack>
            <VStack mb={4}>
              <Heading size="md">Contact Details</Heading>
              {/* TODO: Need a PhoneInput */}
              <TextInput
                value={mobilePhone}
                onChange={setMobilePhone}
                label="Mobile Phone"
                disabled={disabled}
                loading={loading}
                error={validationErrors.mobilePhone}
                helperText="UK Users: enter country code +44.  By providing your phone number, you agree to receive SMS notifications from Rock The Street, Wall Street. Message and data rates may apply."
              />
              <EmailInput
                value={alternateEmail}
                onChange={setAlternateEmail}
                label="Alternate Email Address"
                disabled={disabled}
                loading={loading}
                error={validationErrors.alternateEmail}
              />
              <TextInput
                value={addressLine1}
                onChange={setAddressLine1}
                label="Address Line 1"
                disabled={disabled}
                loading={loading}
                error={validationErrors.addressLine1}
              />
              <TextInput
                value={addressLine2}
                onChange={setAddressLine2}
                label="Address Line 2"
                disabled={disabled}
                loading={loading}
                error={validationErrors.addressLine2}
              />
              <TextInput
                value={addressCity}
                onChange={setAddressCity}
                label="City"
                disabled={disabled}
                loading={loading}
                error={validationErrors.addressCity}
              />
              <HStack space={2}>
                <Box flex={2}>
                  <SelectInput
                    label="State"
                    value={addressState}
                    options={stateSelectOptions}
                    onChange={setAddressState}
                    disabled={disabled}
                    loading={loading}
                    error={validationErrors.addressState}
                  />
                </Box>
                <Box flex={1}>
                  <TextInput
                    value={addressZip}
                    onChange={setAddressZip}
                    label="Zip Code"
                    disabled={disabled}
                    loading={loading}
                    error={validationErrors.addressZip}
                  />
                </Box>
              </HStack>
              {addressState === 'other' ? (
                <TextInput
                  value={addressStateOther}
                  onChange={setAddressStateOther}
                  label="State/Province/Region"
                  disabled={disabled}
                  loading={loading}
                  error={validationErrors.addressStateOther}
                />
              ) : null}
              <SelectInput
                label="Country"
                value={addressCountry}
                options={countrySelectOptions}
                onChange={setAddressCountry}
                disabled={disabled}
                loading={loading}
                error={validationErrors.addressCountry}
              />
            </VStack>
            <VStack mb={4}>
              <Heading size="md">Additional Information</Heading>
              <FormControl isInvalid={!!validationErrors.dateOfBirth}>
                <FormControl.Label>Date of birth</FormControl.Label>
                <HStack space={2}>
                  <Box flex={1.5}>
                    <SelectInput
                      label="Month"
                      placeholder="Month"
                      value={dateOfBirthMonth}
                      options={monthOptions}
                      onChange={setDateOfBirthMonth}
                      disabled={disabled}
                      loading={loading}
                      error={validationErrors.dateOfBirthMonth}
                    />
                  </Box>
                  <Box flex={1.25}>
                    <SelectInput
                      label="Date"
                      placeholder="Date"
                      value={dateOfBirthDay}
                      options={dateOptions}
                      onChange={setDateOfBirthDay}
                      disabled={disabled}
                      loading={loading}
                      error={validationErrors.dateOfBirthDay}
                    />
                  </Box>
                  <Box flex={1.25}>
                    <SelectInput
                      label="Year"
                      placeholder="Year"
                      value={dateOfBirthYear}
                      options={yearOptions}
                      onChange={setDateOfBirthYear}
                      disabled={disabled}
                      loading={loading}
                      error={validationErrors.dateOfBirthYear}
                    />
                  </Box>
                </HStack>
                <Box mt={-2} mb={2}>
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    {validationErrors.dateOfBirth}
                  </FormControl.ErrorMessage>
                </Box>
              </FormControl>
              <SelectInput
                label="Race"
                value={race}
                options={raceSelectOptions}
                onChange={setRace}
                disabled={disabled}
                loading={loading}
                error={validationErrors.race}
              />
              <SelectInput
                label="Gender Identification"
                value={genderIdentification}
                options={genderIdentificationSelectOptions}
                onChange={setGenderIdentification}
                disabled={disabled}
                loading={loading}
                error={validationErrors.genderIdentification}
              />
              <SelectInput
                label="Firm (Company)"
                value={company}
                options={sponsorSelectOptions}
                onChange={setCompany}
                disabled={disabled}
                loading={loading}
                error={validationErrors.company}
              />
              <TextInput
                value={title}
                onChange={setTitle}
                label="Title"
                disabled={disabled}
                loading={loading}
                error={validationErrors.title}
              />
              <TextInput
                value={collegeAttended}
                onChange={setCollegeAttended}
                label="Undergraduate University"
                disabled={disabled}
                loading={loading}
                error={validationErrors.collegeAttended}
              />
              <TextInput
                value={designation}
                onChange={setDesignation}
                label="Designations (CFA/CFP/CAIA, etc)"
                disabled={disabled}
                loading={loading}
                error={validationErrors.designation}
              />
              <CheckboxInput
                value={hundredWomenInFinance}
                onChange={setHundredWomenInFinance}
                label="100 Women in Finance"
                hideLabel
                disabled={disabled}
                loading={loading}
                error={validationErrors.hundredWomenInFinance}
              />
            </VStack>
            <Button
              onPress={handleSubmit}
              disabled={disabled}
              loading={loading}
              text="Complete Registration"
              color="primary"
            />
          </CardContainer>
        </Box>
      </Form>
    );
  },
);
