import { useCallback, useEffect, useState } from 'react';
import { userIsStudent, userIsVolunteer } from '../alerts/utils';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActions } from '../../store/actions';
import { AuthStatus } from '../../store/auth/types';
import { isWeb } from '../../utils';
import { useDataLoader } from '../data';

export interface UseSessionStartParams {
  onSessionStartWithRole: () => void;
  onSessionStartWithoutRole: () => void;
  onNoSession: () => void;
  onSessionEnded: () => void;
}

export const useSessionStart = ({
  onSessionStartWithRole,
  onSessionStartWithoutRole,
  onNoSession,
  onSessionEnded,
}: UseSessionStartParams) => {
  const dispatch = useAppDispatch();

  const { load: loadData, preAuthLoad } = useDataLoader();
  const { loading: authLoading, currentUser, status: authStatus } = useAppSelector((state) => state.auth);

  const [dataLoading, setDataLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const startSession = useCallback(async () => {
    await preAuthLoad();
    await dispatch(authActions.refreshSession()).unwrap();
  }, []);

  const loadDataAndNavigate = useCallback(async () => {
    if (!currentUser || dataLoaded) {
      return;
    }

    setDataLoading(true);
    setDataLoaded(true);

    await loadData();

    if (userIsStudent(currentUser) || userIsVolunteer(currentUser)) {
      onSessionStartWithRole();
    } else {
      onSessionStartWithoutRole();
    }

    setDataLoading(false);
  }, [currentUser, dataLoaded, onSessionStartWithRole, onSessionStartWithoutRole]);

  useEffect(() => {
    if (authLoading || dataLoading || dataLoaded) {
      return;
    }

    if ([AuthStatus.IDLE, AuthStatus.AUTHENTICATING].includes(authStatus)) {
      return;
    }

    if (authStatus === AuthStatus.AUTHENTICATED && currentUser) {
      loadDataAndNavigate();
    } else {
      onNoSession();
    }
  }, [authLoading, dataLoading, dataLoaded, currentUser, authStatus, onNoSession]);

  useEffect(() => {
    if (!dataLoaded) {
      return;
    }

    if (authStatus !== AuthStatus.UNAUTHENTICATED) {
      return;
    }

    if (isWeb) {
      window.location.href = '/';
    } else {
      setDataLoaded(false);
      onSessionEnded();
    }
  }, [authStatus, dataLoaded, onSessionEnded]);

  return { startSession };
};
