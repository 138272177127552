import { APP_ENV } from '@env';
import * as Updates from 'expo-updates';
import { Box, HStack, Text, VStack } from 'native-base';
import React, { useCallback, useEffect } from 'react';
import { Image, TouchableOpacity, useWindowDimensions } from 'react-native';
import { AboutText } from '../components/AboutText';
import { Button, PageWrapper } from '../components/ui';
import { ImageAssetName, useAppVersion, useImages } from '../hooks';
import { AuthStackScreenWithNavProps } from '../navigation/AuthNavigation';
import { useAppDispatch, useAppSelector } from '../store';
import { authActions } from '../store/actions';
import { isWeb, openUrl, SMALL_SCREEN_SIZE_BREAKPOINT } from '../utils';
import { nativeAlert } from '../utils/ui';

export const StartScreen: React.FC<AuthStackScreenWithNavProps<'Start'>> = ({ navigation }) => {
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const isSmall = screenWidth < SMALL_SCREEN_SIZE_BREAKPOINT;

  const dispatch = useAppDispatch();
  const { loading, error: authError } = useAppSelector((state) => state.auth);
  const { findImageAssetByName } = useImages();

  const splashImage = findImageAssetByName(ImageAssetName.SPLASH);
  const appStoreBadge = findImageAssetByName(ImageAssetName.APP_STORE_BADGE);
  const playStoreBadge = findImageAssetByName(ImageAssetName.PLAY_STORE_BADGE);

  useEffect(() => {
    if (authError) {
      if (authError.includes('412')) {
        nativeAlert('Account Deleted', 'Your account has been deleted. Please register a new account.');
      }
      dispatch(authActions.updateError({ error: null }));
    }
  }, [authError]);

  const handleLogin = useCallback(async () => {
    dispatch(authActions.signInUser());
  }, [dispatch]);

  const version = useAppVersion();

  return (
    <PageWrapper containerStyle={{ backgroundColor: 'white' }}>
      <Box position="absolute" width="100%" height="100%" backgroundColor="white">
        {splashImage?.localUri && (
          <Image
            source={{ uri: splashImage?.localUri }}
            style={{ width: '100%', resizeMode: 'contain', height: '100%' }}
          />
        )}
      </Box>
      <Box position="absolute" top="60%" px={10}>
        <AboutText fontSize={15} textAlign="center" />
      </Box>
      <VStack space={2} position="absolute" w="100%" bottom={4} p={4}>
        <Button disabled={loading} loading={loading} onPress={handleLogin} text="Log In" color="primary" />
        {isWeb && (
          <VStack
            space={4}
            p={4}
            position={isSmall ? 'absolute' : undefined}
            top={isSmall ? 'calc(-75vh)' : undefined}
            left={isSmall ? '0px' : undefined}
            width={isSmall ? '100vw' : undefined}
          >
            <HStack alignItems="center" justifyContent="center" space={4}>
              {appStoreBadge?.localUri && (
                <TouchableOpacity
                  onPress={() => openUrl('https://apps.apple.com/us/app/rock-the-street-wall-street/id6444662486')}
                  style={{ width: 150, height: 50 }}
                >
                  <Image
                    source={{ uri: appStoreBadge?.localUri }}
                    style={{ height: '100%', width: '100%' }}
                    resizeMode="center"
                  />
                </TouchableOpacity>
              )}

              {playStoreBadge?.localUri && (
                <TouchableOpacity
                  onPress={() =>
                    openUrl('https://play.google.com/store/apps/details?id=com.rockthestreetwallstreet.app.android')
                  }
                  style={{ width: 170, height: 50 }}
                >
                  <Image
                    source={{ uri: playStoreBadge?.localUri }}
                    style={{ height: '100%', width: '100%' }}
                    resizeMode="center"
                  />
                </TouchableOpacity>
              )}
            </HStack>
            <Text textAlign="center">Download our new native mobile app!</Text>
          </VStack>
        )}
        <HStack space={2} justifyContent="center">
          <Text color="gray.400" fontSize={12} fontStyle="italic" textAlign="center">
            Environment: {APP_ENV}
          </Text>
          {!isWeb && (
            <>
              <Text color="gray.400" fontSize={12} fontStyle="italic" textAlign="center">
                Version: {version}
              </Text>
              <Text color="gray.400" fontSize={12} fontStyle="italic" textAlign="center">
                Revision: {Updates.updateId?.slice(0, 8) ?? 'local'}
              </Text>
            </>
          )}
        </HStack>
      </VStack>
    </PageWrapper>
  );
};
