import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GalleryItem } from '../../models/data';
import { galleryItemService } from '../../services';
import { commonActions } from '../commonActions';
import { GalleryState } from './types';

const getGalleryItems = createAsyncThunk('galleryItems/getGalleryItems', async () => {
  return galleryItemService.getGalleryItems();
});

const initialState: GalleryState = {
  galleryItems: [],
  loading: false,
  error: null,
};

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setGalleryItems(state, { payload: { galleryItems } }: PayloadAction<{ galleryItems: GalleryItem[] }>) {
      state.galleryItems = galleryItems;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGalleryItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGalleryItems.fulfilled, (state, action) => {
        state.loading = false;
        state.galleryItems = action.payload;
      })
      .addCase(getGalleryItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load gallery.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });
  },
});

export const galleryActions = { ...gallerySlice.actions, getGalleryItems };

export type GalleryItemSlice = {
  [gallerySlice.name]: ReturnType<typeof gallerySlice['reducer']>;
};
