import { Ionicons } from '@expo/vector-icons';
import { Box, ChevronRightIcon, HStack, Pressable } from 'native-base';
import React from 'react';

export interface ListItemProps {
  children: React.ReactNode;
  onPress?: () => void;
  borderBottomWidth?: number;
  mb?: number;
  mt?: number;
  iconName?: string;
  iconColor?: string;
  hideIcon?: boolean;
  disabled?: boolean;
}

export const ListItem: React.FC<ListItemProps> = ({
  children,
  onPress,
  borderBottomWidth,
  mb,
  mt,
  iconName,
  iconColor,
  hideIcon = false,
  disabled = false,
}) => {
  return (
    <Pressable
      onPress={onPress}
      borderBottomWidth={borderBottomWidth ?? 0.5}
      borderColor="gray.200"
      mb={mb ?? 0}
      mt={mt ?? 0}
      isDisabled={disabled}
    >
      <Box py={2.5}>
        <HStack alignItems="center" justifyContent="space-between">
          {children}
          {!hideIcon &&
            (iconName ? (
              <Ionicons name={iconName as any} color={iconColor ?? 'gray.500'} size={20} />
            ) : (
              <ChevronRightIcon color="gray.200" />
            ))}
        </HStack>
      </Box>
    </Pressable>
  );
};
