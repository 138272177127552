import { Box, Divider, HStack, ScrollView, Text, VStack } from 'native-base';
import React from 'react';
import { Button, PageWrapper } from '../../components/ui';
import { MenuStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { openUrl } from '../../utils';

export const StudentCEOScreen: React.FC<MenuStackScreenWithNavProps<'StudentCEO'>> = ({ navigation, route }) => {
  return (
    <ScrollView>
      <PageWrapper>
        <Box p={4}>
          <VStack space={4} mb={4}>
            <HStack>
              <Text fontWeight="bold" fontSize={16}>
                Stu•dent CEO
              </Text>
              <Text fontSize={16}> • /ˈst(y)oodnt/ /ˌsēˌēˈō/</Text>
            </HStack>
            <HStack>
              <Text fontStyle="italic" fontSize={16}>
                noun
              </Text>
              <Text fontSize={16}> : A high school student who serves as a leader for their RTSWS cohort.</Text>
            </HStack>
            <HStack>
              <Text fontStyle="italic" fontSize={16}>
                CEO
              </Text>
              <Text fontSize={16}> : Chief Executive Officer</Text>
            </HStack>
          </VStack>

          <Divider mb={4} />

          <VStack space={4} mb={4}>
            <Text fontSize={16}>
              Each RTSWS cohort has 1-2 Student CEOs who lead their peers as a liaison between their school and RTSWS.
              This position is an excellent opportunity for students to elevate their leadership experience (or get it
              started) and network with other student leaders from across the world. Student CEOs are changemakers and
              integral to growing more financially independent young women in their communities.
            </Text>
          </VStack>

          <Box>
            <Button
              text={'Visit Student CEO'}
              onPress={() => openUrl('https://rockthestreetwallstreet.com/student-ceo/')}
              color="primary"
            />
          </Box>
        </Box>
      </PageWrapper>
    </ScrollView>
  );
};
