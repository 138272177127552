import { Box, Divider, Heading, Text, VStack } from 'native-base';
import { FC, useCallback, useMemo } from 'react';
import { Button } from '../ui';
import { CardContainer } from '../ui/cards/CardContainer';
import { Form } from '../ui/forms';
import { FormProps } from '../ui/forms/types';
import { useAppSelector } from '../../store';
import { userIsStudent, userIsVolunteer } from '../../hooks/alerts/utils';

export interface SpringEnrollmentFormProps extends FormProps {
  onSubmit: () => void;
}

export const SpringEnrollmentForm: FC<SpringEnrollmentFormProps> = ({ loading, disabled, onSubmit }) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const handleSubmit = useCallback(async () => {
    onSubmit();
  }, [onSubmit]);

  const isStudentUser = useMemo(() => userIsStudent(currentUser), [currentUser]);
  const isVolunteerUser = useMemo(() => userIsVolunteer(currentUser), [currentUser]);

  return (
    <Form>
      <Box p={4}>
        <Heading mb={4}>Spring Enrollment</Heading>
        <CardContainer>
          {!isStudentUser && !isVolunteerUser ? (
            <Text>
              Something went wrong attempting Spring Enrollment. Please contact RTSWS support at
              questions@rockthestreetwallstreet.com.
            </Text>
          ) : (
            <>
              <VStack space={4} mb={8}>
                {isStudentUser ? (
                  <>
                    <Text>
                      Enroll here to participate in the Rock The Street, Wall Street Spring Mentorship program.
                    </Text>
                    <Text>
                      You will be meeting five times between January and April at your school with female financial
                      professionals who will coach you on interview skills, resume building, self advocacy, building a
                      LinkedIn profile, and more!
                    </Text>
                  </>
                ) : isVolunteerUser ? (
                  <Text>
                    Sign up here to volunteer as a mentor in the Rock The Street, Wall Street Spring Mentorship Program.
                  </Text>
                ) : null}
              </VStack>

              <Button
                onPress={() => handleSubmit()}
                disabled={disabled}
                loading={loading}
                text="Enroll Now"
                color="primary"
              />
            </>
          )}
        </CardContainer>
      </Box>
    </Form>
  );
};
