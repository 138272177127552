import { Box, Heading, Text } from 'native-base';
import React from 'react';
import { colors } from '../../../styles';

export interface CardTitleProps {
  title: string;
  subtitle?: string;
  overline?: string;
  titleFontWeight?: string;
  titleMargin?: number | string;
}

export const CardTitle: React.FC<CardTitleProps> = ({
  titleMargin,
  overline,
  subtitle,
  title,
  titleFontWeight = '700',
}) => {
  return (
    <Box mb={titleMargin ?? 4}>
      {overline && (
        <Text color={colors.darkGrey} fontSize={13}>
          {overline}
        </Text>
      )}
      <Heading color={colors.black} fontSize={18} fontWeight={titleFontWeight}>
        {title}
      </Heading>
      {subtitle && (
        <Text color={colors.darkGrey} fontSize={16}>
          {subtitle}
        </Text>
      )}
    </Box>
  );
};
