import { GalleryItem } from '../models/data';
import BaseService from './BaseService';

export class GalleryItemService extends BaseService {
  async getGalleryItems() {
    return (await this.getJson<GalleryItem[]>('/gallery')).data;
  }
}

export default new GalleryItemService();
