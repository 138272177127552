import { useCallback, useRef, useState } from 'react';

export type DeferredPromise<DeferType> = {
  resolve: (value: DeferType) => void;
  reject: (value: unknown) => void;
  promise: Promise<DeferType>;
};

export const useDeferredPromise = <DeferType>() => {
  const [_, setX] = useState(0); // Using state to force re-render
  const deferRef = useRef<DeferredPromise<DeferType>>();

  const defer = useCallback(() => {
    const deferred = {} as DeferredPromise<DeferType>;

    const promise = new Promise<DeferType>((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });

    deferred.promise = promise;
    deferRef.current = deferred;

    setX((x) => x + 1);

    return deferRef.current;
  }, []);

  return { defer, deferRef: deferRef.current };
};
