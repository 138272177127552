import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Resource } from '../../models/data';
import { resourceService } from '../../services';
import { commonActions } from '../commonActions';
import { ResourceState } from './types';

const getResources = createAsyncThunk('resources/getResources', async () => {
  return resourceService.getResources();
});

const initialState: ResourceState = {
  currentResource: null,
  resources: [],
  loading: false,
  error: null,
};

export const resourceSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setResource(state, { payload: { resource } }: PayloadAction<{ resource: Resource }>) {
      state.currentResource = resource;
    },

    setResources(state, { payload: { resources } }: PayloadAction<{ resources: Resource[] }>) {
      state.resources = resources;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResources.pending, (state) => {
        state.loading = true;
      })
      .addCase(getResources.fulfilled, (state, action) => {
        state.loading = false;
        state.resources = action.payload;
      })
      .addCase(getResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load resources.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });
  },
});

export const resourceActions = { ...resourceSlice.actions, getResources };

export type ResourceSlice = {
  [resourceSlice.name]: ReturnType<typeof resourceSlice['reducer']>;
};
