import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../store';
import { nativeAlert } from '../../utils/ui';
import { useDataLoader } from '../data';

export interface UseRegistrationSubmitParams<T> {
  onSubmit: (data: T) => Promise<any>;
  onDone: () => void;
}

export const useRegistrationSubmit = <T>({ onSubmit, onDone }: UseRegistrationSubmitParams<T>) => {
  const { load: loadData } = useDataLoader();
  const { loading: registrationLoading, error: registrationError } = useAppSelector((state) => state.registrations);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [watchRegistrationProgress, setWatchRegistrationProgress] = useState(false);

  useEffect(() => {
    if (!watchRegistrationProgress) {
      return;
    }

    if (registrationLoading) {
      return;
    }

    if (registrationError) {
      nativeAlert('Registration Error', `There was a problem completing your registration: ${registrationError}`);
      setWatchRegistrationProgress(false);
      return;
    }

    setWatchRegistrationProgress(false);

    const load = async () => {
      setLoading(true);
      setDisabled(true);
      await loadData();
      onDone();

      // Not setting loading or disabled to false here because the app will be navigating away
    };

    load();
  }, [registrationLoading, watchRegistrationProgress, registrationError, onDone]);

  const handleSubmit = useCallback(
    async (data: T) => {
      setLoading(true);
      setDisabled(true);

      try {
        setWatchRegistrationProgress(true);
        await onSubmit(data);
      } catch (error) {
        console.warn('Error submitting registration', error);
      } finally {
        setLoading(false);
        setDisabled(false);
      }
    },
    [onSubmit],
  );

  return { handleSubmit, loading, disabled };
};
