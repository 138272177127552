import moment, { Moment } from 'moment-timezone';
import { RoleNames } from '../../constants';
import { Campaign, Contact, ProgramEvent, User } from '../../models/data';

export const shouldShowBackgroundCheckAlert = (contact: Contact) => {
  if (!contact.bgCheckDate) {
    return true;
  }

  const todayMoment: Moment = moment().tz('UTC').startOf('day');
  const julyFirstDayOfYear: number = todayMoment.isLeapYear() ? 183 : 182;

  let earliestValidYear: number;

  if (todayMoment.dayOfYear() < julyFirstDayOfYear) {
    // If today is before the start of the current year's fiscal year, set the earliest valid year to 3 years ago.
    earliestValidYear = todayMoment.subtract(3, 'years').year();
  } else {
    // If today is after the start of the current year's fiscal year, set the earliest valid year to 2 years ago.
    earliestValidYear = todayMoment.subtract(2, 'years').year();
  }

  // Earliest valid moment is July 1 (fiscal year beginning) of the earliest valid year
  const earliestValidMoment = moment(earliestValidYear + '-07-01', 'YYYY-MM-DD')
    .tz('UTC')
    .startOf('day');
  const bgCheckMoment = moment(contact.bgCheckDate);

  return bgCheckMoment.isBefore(earliestValidMoment);
};

export const shouldShowTrainingAlert = (contact: Contact) => {
  return !contact.trainingCompleted;
};

const isItAtLeast60MinutesAfterTheEventStartTime = (event?: ProgramEvent, timeZone?: string) => {
  if (!event) {
    return false;
  }

  const timezone = timeZone || 'UTC';
  const momentStartDateTime = moment(event.startDateTime).tz(timezone);
  const momentStartDateTimePlusSixtyMinutes = momentStartDateTime.clone().add(60, 'minutes');
  const momentNow = moment().tz(timezone);

  return momentStartDateTime.isSameOrBefore(momentNow) && momentStartDateTimePlusSixtyMinutes.isSameOrAfter(momentNow);
};

export const shouldShowPreAssessmentAlert = (campaign: Campaign, event?: ProgramEvent) => {
  return isItAtLeast60MinutesAfterTheEventStartTime(event, campaign.timezone);
};

export const shouldShowPostAssessmentAlert = (campaign: Campaign, event?: ProgramEvent) => {
  return isItAtLeast60MinutesAfterTheEventStartTime(event, campaign.timezone);
};

export const shouldShowProgramSurveyAlert = (campaign: Campaign) => {
  if (!campaign.fieldTripDate) {
    return false;
  }

  const timezone = campaign.timezone || 'UTC';
  const momentFieldTripDate = moment(campaign.fieldTripDate).tz(timezone);

  return momentFieldTripDate.isSame(new Date(), 'day');
};

export const shouldShowReenrollmentAlert = (currentUser: User, registrationSubmitted: boolean) => {
  return currentUser.eligibleForReenrollment && !registrationSubmitted;
};

export const shouldShowSpringEnrollmentAlert = (currentUser: User, campaign: Campaign | null) => {
  // Must be a student or volunteer
  if (!userIsStudent(currentUser) && !userIsVolunteer(currentUser)) {
    return false;
  }

  // Must not be mentor interested
  if (userIsMentorInterested(currentUser)) {
    return false;
  }

  if (campaign) {
    if (userIsStudent(currentUser)) {
      // Student campaigns must not be protege
      if (campaignIsProtege(campaign)) return false;
    } else if (userIsVolunteer(currentUser)) {
      // Volunteer campaigns must not be mentor
      if (campaignIsMentor(campaign)) return false;
    }

    // If campaign name is present, check if today is after October of the current campaign year
    if (campaign.name && !todayIsAfterOctoberOfCurrentCampaignYear(campaign)) {
      return false;
    }
  } else {
    // If campaign is not present, check if today is after April 1 of the current year
    if (!todayIsBeforeAprilOfCurrentYear(campaign)) {
      return false;
    }
  }

  return true;
};

export const shouldShowSpringEnrollmentConfirmationAlert = (currentUser: User, campaign: Campaign) => {
  // Must be a student
  if (!userIsStudent(currentUser)) {
    return false;
  }

  // Must be mentor interested
  if (!userIsMentorInterested(currentUser)) {
    return false;
  }

  // If campaign is Protege, check if today is after campaign second volunteer shift
  if (campaignIsProtege(campaign) && todayIsAfterTheCampaignSecondVolunteerShift(campaign)) {
    return false;
  }

  return true;
};

export const userIsStudent = (currentUser?: User | null) => {
  return currentUser?.role.name === RoleNames.STUDENT;
};

export const userIsVolunteer = (currentUser?: User | null) => {
  return currentUser?.role.name === RoleNames.VOLUNTEER;
};

const userIsMentorInterested = (currentUser: User) => {
  return currentUser.contact?.mentorInterested === 'Yes';
};

// NOTE: This is actually just checking to see that the current month is one of the following months:
// Nov, Dec, Jan, Feb, Mar, Apr, May
const todayIsAfterOctoberOfCurrentCampaignYear = (campaign: Campaign) => {
  const timezone = campaign.timezone || 'UTC';
  const momentToday = moment().tz(timezone);
  const acceptableMonths = [0, 1, 2, 3, 4, 10, 11];

  return acceptableMonths.includes(momentToday.month());
};

const todayIsBeforeAprilOfCurrentYear = (campaign: Campaign | null) => {
  const timezone = campaign && campaign.timezone ? campaign.timezone : 'UTC';
  const currentYear = moment().tz(timezone).year();
  const momentCurrentYearAprilFirst = moment(`${currentYear}-04-01`).tz(timezone);
  const momentToday = moment().tz(timezone);

  return momentCurrentYearAprilFirst.isAfter(momentToday);
};

// const todayIsAfterMayOfCurrentYear = (campaign: Campaign | null) => {
//   const timezone = campaign && campaign.timezone ? campaign.timezone : 'UTC';
//   const currentYear = moment().tz(timezone).year();
//   const momentCurrentYearJuneFirst = moment(`${currentYear}-06-01`).tz(timezone);
//   const momentToday = moment().tz(timezone);

//   return momentCurrentYearJuneFirst.isSameOrBefore(momentToday);
// };

const campaignIsProtege = (campaign: Campaign) => {
  return getCampaignNameElement(campaign, 'program') === 'Protege';
};

const campaignIsMentor = (campaign: Campaign) => {
  return getCampaignNameElement(campaign, 'program') === 'Mentors';
};

const getCampaignNameElement = (campaign: Campaign, element: string) => {
  const nameRegexPattern = /^(\w{4})\/(.+)\/(.+),.([SVPM]{1}\w+$)/i;
  const elements = campaign.name.match(nameRegexPattern);

  if (!elements) return '';

  switch (element) {
    case 'year':
      return elements[1] || '';
    case 'city':
      return elements[2] || '';
    case 'school':
      return elements[3] || '';
    case 'program':
      return elements[4] || '';
    default:
      break;
  }

  return '';
};

const todayIsAfterTheCampaignSecondVolunteerShift = (campaign: Campaign) => {
  //// • the current date is before the date of the current campaign's second volunteer shift date
  const timezone = campaign?.timezone || 'UTC';
  const momentToday = moment().tz(timezone);
  const unacceptableMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  return unacceptableMonths.includes(momentToday.month());
};
