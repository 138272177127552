import React from 'react';
import { useHomeFeedItems } from '../../hooks';
import { NoData } from '../NoData';
import { FeedItemList } from './FeedItemsList';

export interface HomeFeedItemsListProps {}

export const HomeFeedItemsList: React.FC<HomeFeedItemsListProps> = (props) => {
  const { homeFeedItems } = useHomeFeedItems();

  if (homeFeedItems.length === 0) {
    return <NoData py={4} message="No feed data available." />;
  }

  return <FeedItemList feedItems={homeFeedItems} />;
};
