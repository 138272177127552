import { Box, Heading, HStack, SunIcon } from 'native-base';
import React, { useMemo } from 'react';
import { colors } from '../../styles';

export interface IconTitleProps {
  title: string;
  variant?: 'default' | 'title' | 'info';
  renderIcon?: (color: string) => React.ReactNode;
  hideIcon?: boolean;
  centerTitle?: boolean;
  mb?: number | string;
}

export const IconTitle: React.FC<IconTitleProps> = ({
  title,
  variant = 'default',
  renderIcon,
  hideIcon = false,
  centerTitle = false,
  mb,
}) => {
  const fontWeight = useMemo(() => {
    switch (variant) {
      case 'default':
        return '500';
      case 'title':
      case 'info':
      default:
        return '700';
    }
  }, [variant]);

  const fontSize = useMemo(() => {
    switch (variant) {
      default:
      case 'default':
        return 16;
      case 'title':
        return 12;
    }
  }, [variant]);

  const color = useMemo(() => {
    switch (variant) {
      case 'title':
        return colors.darkGrey;
      case 'default':
      default:
        return colors.black;
    }
  }, [variant]);

  const icon = useMemo(() => {
    if (renderIcon) {
      return renderIcon(colors.primary);
    }

    return <SunIcon />;
  }, [renderIcon]);

  const textTransform = useMemo(() => {
    switch (variant) {
      case 'default':
      case 'info':
        return 'none';
      case 'title':
      default:
        return 'uppercase';
    }
  }, [variant]);

  if (title === '') {
    return <></>;
  }

  return (
    <HStack alignItems="center" mb={mb ?? '4'} justifyContent={centerTitle ? 'center' : 'flex-start'}>
      {!hideIcon && <Box>{icon}</Box>}
      <Heading
        ml={hideIcon ? 0 : 2}
        mt={1}
        color={color}
        fontWeight={fontWeight}
        fontSize={fontSize}
        textTransform={textTransform}
        backgroundColor="red"
      >
        {title}
      </Heading>
    </HStack>
  );
};
