import { Box, Pressable } from 'native-base';
import React from 'react';
import { colors } from '../../../styles';
import { CardBody, CardBodyProps } from './CardBody';
import { CardContainer } from './CardContainer';
import { CardTitle, CardTitleProps } from './CardTitle';

export type NotificationCardProps = CardTitleProps &
  CardBodyProps & {
    onPress?: () => void;
    isRead?: boolean;
  };

export const NotificationCard: React.FC<NotificationCardProps> = (props) => {
  return (
    <Pressable onPress={props.onPress}>
      <CardContainer>
        <Box borderColor={colors.alert} borderLeftWidth={props.isRead ? 0 : 3} pl={props.isRead ? 0 : 4}>
          <CardTitle {...props} titleFontWeight={props.isRead ? '400' : '700'} />
          <CardBody {...props} contentColor={colors.darkGrey} contentFontSize={13} contentMargin={0} />
        </Box>
      </CardContainer>
    </Pressable>
  );
};
