import { useCallback } from 'react';
import { userIsStudent, userIsVolunteer } from '../alerts/utils';
import { useAppDispatch, useAppSelector } from '../../store';
import * as actions from '../../store/actions';

export const useDataLoader = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);

  const preAuthLoad = useCallback(async () => {
    const actionsToDispatch: any[] = [
      actions.notificationActions.getNeedsToRegister(),
      actions.notificationActions.getNeedsToRegisterForSms(),
    ];

    await Promise.allSettled(actionsToDispatch.map((action) => dispatch(action).unwrap()));
  }, [dispatch]);

  const load = useCallback(async () => {
    const actionsToDispatch: any[] = [
      actions.accountActions.getSponsorAccounts(),
      actions.accountActions.getSchoolAccounts(),
      actions.settingActions.getSettings(),
      actions.galleryActions.getGalleryItems(),
      actions.feedActions.getFeedItems(),
      actions.deviceActions.getDevices(),
    ];

    if (currentUser?.role) {
      if (userIsStudent(currentUser) || userIsVolunteer(currentUser)) {
        actionsToDispatch.push(
          actions.campaignActions.getCampaign(),
          actions.contactActions.getContacts(),
          actions.notificationActions.getNotifications(),
          actions.programEventActions.getProgramEvents(),
          actions.resourceActions.getResources(),
        );
      }

      if (userIsVolunteer(currentUser)) {
        actionsToDispatch.push(actions.studentActions.getStudents());
      }
    }

    await Promise.allSettled(actionsToDispatch.map((action) => dispatch(action).unwrap()));
  }, [currentUser]);

  return { load, preAuthLoad };
};
