import * as yup from 'yup';

export const volunteerReenrollmentFormSchema = yup.object().shape({
  schoolAccountSfid: yup.string().required('Please select a school'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  dateOfBirth: yup.string().required('Required'),
  company: yup.string().required('Required'),
  title: yup.string(),
  designation: yup.string(),
  hundredWomenInFinance: yup.boolean().required(),
  collegeAttended: yup.string(),
  race: yup.string().required('Required'),
});
