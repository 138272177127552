import { useAssets } from 'expo-asset';
import { useCallback, useMemo } from 'react';

export enum ImageAssetName {
  SPLASH = 'splash',
  LOGO = 'homepage_background_white',
  CAREER_CENTER = 'career-center',
  ABOUT_1 = 'RTSWS_DFW_076',
  ABOUT_2 = 'girls-rock-banner_lg',
  APP_STORE_BADGE = 'Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917',
  PLAY_STORE_BADGE = 'google-play-badge',
}

export const useImages = () => {
  const [images] = useAssets([
    require('../../../assets/splash.png'),
    require('../../../assets/images/career-center.jpg'),
    require('../../../assets/images/homepage_background_white.png'),
    require('../../../assets/images/RTSWS_DFW_076.jpg'),
    require('../../../assets/images/girls-rock-banner_lg.jpg'),
    require('../../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png'),
    require('../../../assets/images/google-play-badge.svg'),
  ]);

  const ready = useMemo(() => images?.every((image) => image.downloaded) ?? false, [images]);

  const findImageAssetByName = useCallback(
    (name: ImageAssetName) => {
      return images?.find((image) => image.name.startsWith(name));
    },
    [images],
  );

  return { images, ready, findImageAssetByName };
};
