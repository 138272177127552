import { APP_ENV } from '@env';
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import * as Updates from 'expo-updates';
import { Box, Heading, ScrollView, Text, VStack } from 'native-base';
import React, { useCallback, useState } from 'react';
import { Platform } from 'react-native';
import { ProfileForm, ProfileFormData } from '../../components/menu/ProfileForm';
import { SettingsForm, SettingsFormData } from '../../components/menu/SettingsForm';
import { Button, PageWrapper } from '../../components/ui';
import { CardContainer } from '../../components/ui/cards/CardContainer';
import { useAppVersion } from '../../hooks';
import { MenuStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActions } from '../../store/actions';
import { isWeb } from '../../utils';
import { nativeAlert } from '../../utils/ui';

export const SettingsScreen: React.FC<MenuStackScreenWithNavProps<'Settings'>> = ({ navigation }) => {
  const dispatch = useAppDispatch();

  const { currentUser } = useAppSelector((state) => state.auth);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileDisabled, setProfileDisabled] = useState(false);
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [settingsDisabled, setSettingsDisabled] = useState(false);

  const version = useAppVersion();

  const handleProfileSubmit = useCallback(
    async (data: ProfileFormData) => {
      if (!currentUser) {
        return;
      }

      setProfileLoading(true);
      setProfileDisabled(true);

      try {
        await dispatch(
          authActions.updateCurrentUser({
            ...currentUser,
            firstName: data.firstName,
            lastName: data.lastName,
            contactMobilePhone: data.mobilePhone,
          }),
        ).unwrap();

        nativeAlert('Success', 'Profile updated!');
      } catch (error) {
        console.error(error);
        nativeAlert('Error', 'Unable to update profile.');
      } finally {
        setProfileLoading(false);
        setProfileDisabled(false);
      }
    },
    [currentUser],
  );

  const handleSettingsSubmit = useCallback(
    async (data: SettingsFormData) => {
      if (!currentUser) {
        return;
      }

      setSettingsLoading(true);
      setSettingsDisabled(true);

      try {
        await dispatch(
          authActions.updateCurrentUser({
            ...currentUser,
            receivePushNotifications: data.receivePushNotifications,
            receiveSmsNotifications: data.receiveSmsNotifications,
            automatedNotificationsDisabled: !data.receiveScheduleNotifications,
          }),
        ).unwrap();

        nativeAlert('Success', 'Settings updated!');
      } catch (error) {
        console.error(error);
        nativeAlert('Error', 'Unable to update settings.');
      } finally {
        setSettingsLoading(false);
        setSettingsDisabled(false);
      }
    },
    [currentUser],
  );

  const handleDeleteAccount = useCallback(async () => {
    navigation.navigate('DeleteAccount');
  }, []);

  return (
    <ScrollView>
      <PageWrapper>
        <Box p={4} flex={1} height="100%">
          <Box mb={6} flex={0} flexBasis={Platform.OS === 'web' ? 'min-content' : undefined}>
            <SegmentedControl
              values={['Profile', 'Settings']}
              selectedIndex={selectedIndex}
              onChange={(event) => {
                setSelectedIndex(event.nativeEvent.selectedSegmentIndex);
              }}
            />
          </Box>
          <Box
            display={selectedIndex === 0 ? 'flex' : 'none'}
            flex={0}
            flexBasis={Platform.OS === 'web' ? 'min-content' : undefined}
          >
            <Heading mb={4}>User Profile</Heading>
            <CardContainer>
              <ProfileForm onSubmit={handleProfileSubmit} disabled={profileDisabled} loading={profileLoading} />
            </CardContainer>
          </Box>
          <Box
            display={selectedIndex === 1 ? 'flex' : 'none'}
            flex={0}
            flexBasis={Platform.OS === 'web' ? 'min-content' : undefined}
          >
            <Heading mb={4}>Settings</Heading>
            <CardContainer>
              <SettingsForm
                onSubmit={handleSettingsSubmit}
                onDeleteAccountPress={handleDeleteAccount}
                disabled={settingsDisabled}
                loading={settingsLoading}
              />
            </CardContainer>
          </Box>
          <Box display={selectedIndex === 1 ? 'flex' : 'none'}>
            <VStack>
              <Text color="gray.400" fontSize={12} fontStyle="italic" textAlign="center">
                Environment: {APP_ENV}
              </Text>
              {!isWeb && (
                <>
                  <Text color="gray.400" fontSize={12} fontStyle="italic" textAlign="center">
                    Version: {version}
                  </Text>
                  <Text color="gray.400" fontSize={12} fontStyle="italic" textAlign="center">
                    Revision: {Updates.updateId?.slice(0, 8) ?? 'local'}
                  </Text>
                </>
              )}
            </VStack>
            {(APP_ENV === 'local' || APP_ENV === 'development') && (
              <Box flexGrow={1} justifyContent="flex-end" mb={4}>
                <Button text="Debug" onPress={() => navigation.navigate('Debug')} />
              </Box>
            )}
          </Box>
        </Box>
      </PageWrapper>
    </ScrollView>
  );
};
