import { useMemo } from 'react';
import { SelectInputOption } from '../../components/ui';
import { countries as countryData } from '../../constants';

export const useCountrySelectOptions = () => {
  const countrySelectOptions = useMemo((): SelectInputOption[] => {
    return countryData.map((state) => ({
      label: state.label,
      value: state.value,
    }));
  }, [countryData]);

  return { countrySelectOptions };
};
