export enum FeedItemLocation {
  HOME = 'HOME',
}

export enum FeedItemActionLinkType {
  URL = 'URL',
  NAV = 'NAV',
}

export enum FeedItemActionColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  DANGER = 'danger',
}

export interface FeedItem {
  id: string;
  title: string;
  location: FeedItemLocation;
  actionLinkType: FeedItemActionLinkType;
  actionLink: string;
  description?: string;
  imageUrl?: string;
  actionText?: string;
  actionColor?: FeedItemActionColor;
  created_at: string;
  updated_at: string;
  position: number;
}
