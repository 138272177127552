import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema<TType, TContext, TOut> {
    phone(countryCode?: CountryCode): this;
    formatPhone(countryCode?: CountryCode): this;
  }
}

function runParsePhoneNumberWithCountryCode(value: string, countryCode: CountryCode): any {
  try {
    return parsePhoneNumber(value, countryCode);
  } catch (e) {
    return null;
  }
}

yup.addMethod(yup.string, 'phone', function phone(countryCode: CountryCode = 'US') {
  return this.test('validate-phone-number', `\${path} is not a valid US/UK/CA phone number`, (value) => {
    if (!value && value !== '') {
      return false;
    }

    if (value === '') {
      return true;
    }

    try {
      // https://gitlab.com/catamphetamine/libphonenumber-js#using-phone-number-validation-feature

      const usParsed = parsePhoneNumber(value, 'US');

      if (usParsed.isValid()) {
        return true;
      }

      const gbParsed = parsePhoneNumber(value, 'GB');

      if (gbParsed.isValid()) {
        return true;
      }

      const caParsed = parsePhoneNumber(value, 'CA');

      if (caParsed.isValid()) {
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  });
});

yup.addMethod(yup.string, 'formatPhone', function formatPhone(countryCode: CountryCode = 'US') {
  return this.transform((value) => {
    if (!value && value !== '') {
      return false;
    }

    if (value === '') {
      return value;
    }

    try {
      const usParsed = parsePhoneNumber(value, 'US');

      if (usParsed.isValid()) {
        return usParsed.number;
      }

      const gbParsed = parsePhoneNumber(value, 'GB');

      if (gbParsed.isValid()) {
        return gbParsed.number;
      }

      const caParsed = parsePhoneNumber(value, 'CA');

      if (caParsed.isValid()) {
        return caParsed.number;
      }

      return value;

      // https://gitlab.com/catamphetamine/libphonenumber-js#using-phone-number-validation-feature
    } catch (e) {
      return value;
    }
  });
});

export const yupPhone = yup;
