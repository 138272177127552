import React, { useMemo } from 'react';
import { Platform } from 'react-native';
import { SvgXml } from 'react-native-svg';
import { IconProps } from './types';

export interface SvgIconProps extends IconProps {
  svgText: string;
  replaceFillsWithColor?: boolean;
}

export const SvgIcon: React.FC<SvgIconProps> = ({ svgText, color, size, replaceFillsWithColor = false }) => {
  const src = useMemo(() => {
    return replaceFillsWithColor ? svgText.replace(/fill="[^"]+"/g, `fill="${color}"`) : svgText;
  }, [svgText, replaceFillsWithColor, color]);

  return Platform.OS === 'web' ? (
    <img src={`data:image/svg+xml;utf8,${encodeURIComponent(src)}`} width={size} height={size} />
  ) : (
    <SvgXml xml={src} width={size} height={size} fill={color} />
  );
};
