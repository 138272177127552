import { Box, Text } from 'native-base';
import React from 'react';

export interface NoDataProps {
  message?: string;
  p?: number | string;
  px?: number | string;
  py?: number | string;
}

export const NoData: React.FC<NoDataProps> = ({ p, px, py, message }) => {
  return (
    <Box p={p} px={px} py={py}>
      <Text>{message || 'No data could be found. Pull down to refresh.'}</Text>
    </Box>
  );
};
