import { Box } from 'native-base';
import { FC, useCallback, useEffect, useState } from 'react';
import { SpringEnrollmentForm } from '../../components/auth/SpringEnrollmentForm';
import { AppStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { registrationActions } from '../../store/actions';
import { nativeAlert } from '../../utils/ui';

export const SpringEnrollmentScreen: FC<AppStackScreenWithNavProps<'SpringEnrollment'>> = ({ navigation }) => {
  const dispatch = useAppDispatch();

  const { error: registrationError } = useAppSelector((state) => state.registrations);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (registrationError) {
      dispatch(registrationActions.updateError({ error: null }));
      nativeAlert('Spring Enrollment Unsuccessful', registrationError);
    }
  }, [registrationError]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    setDisabled(true);

    try {
      await dispatch(registrationActions.submitSpringEnrollment()).unwrap();
      await nativeAlert('Success!', 'Spring enrollment has been submitted.');
      navigation.goBack();
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  }, []);

  return (
    <Box flex={1}>
      <SpringEnrollmentForm loading={loading} disabled={disabled} onSubmit={() => handleSubmit()} />
    </Box>
  );
};
