import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Campaign } from '../../models/data';
import { campaignService } from '../../services';
import { commonActions } from '../commonActions';
import { CampaignState } from './types';

const getCampaign = createAsyncThunk('campaigns/getCampaign', async () => {
  return campaignService.getCampaign();
});

const initialState: CampaignState = {
  currentCampaign: null,
  campaigns: [],
  loading: false,
  error: null,
};

export const campaignSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setCampaign(state, { payload: { currentCampaign } }: PayloadAction<{ currentCampaign: Campaign }>) {
      state.currentCampaign = currentCampaign;
    },

    setCampaigns(state, { payload: { campaigns } }: PayloadAction<{ campaigns: Campaign[] }>) {
      state.campaigns = campaigns;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCampaign = action.payload;
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load campaign.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });
  },
});

export const campaignActions = { ...campaignSlice.actions, getCampaign };

export type CampaignSlice = {
  [campaignSlice.name]: ReturnType<typeof campaignSlice['reducer']>;
};
