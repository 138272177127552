import { Box, Heading, Text, VStack } from 'native-base';
import React, { useCallback, useState } from 'react';
import { Button } from '../../components/ui';
import { CardContainer } from '../../components/ui/cards/CardContainer';
import { RegistrationStackScreenWithNavProps } from '../../navigation/RegistrationNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActions } from '../../store/actions';
import { colors } from '../../styles';
import { nativeConfirm } from '../../utils/ui';

export const SelectRoleScreen: React.FC<RegistrationStackScreenWithNavProps<'SelectRole'>> = ({ navigation }) => {
  const dispatch = useAppDispatch();

  const { status: authStatus } = useAppSelector((state) => state.auth);
  const { currentDevice } = useAppSelector((state) => state.device);

  const [signingOut, setSigningOut] = useState(false);

  const signOut = useCallback(async () => {
    if (await nativeConfirm('Sign Out', 'Are you sure you want to sign out?')) {
      setSigningOut(true);
      dispatch(authActions.signOutUser({ currentDeviceId: currentDevice?.id, includeBrowserSessionLogout: true }));
    }
  }, [dispatch, navigation, currentDevice]);

  return (
    <Box p={4}>
      <Heading mb={4}>Select a Role</Heading>
      <CardContainer>
        <Text fontSize={16} mb={4}>
          Please select your role for the Rock The Street, Wall Street program.
        </Text>
        <Text fontSize={12} fontStyle="italic" mb={4} color={colors.darkGrey}>
          Note: If you are a School Champion, please register as a Student.
        </Text>
        <VStack space={2} mb={8}>
          <Button
            onPress={() => navigation.navigate('StudentRegistration')}
            text="I am a Student"
            color="primary"
            disabled={signingOut}
          />
          <Button
            onPress={() => navigation.navigate('VolunteerRegistration')}
            text="I am a Volunteer"
            color="primary"
            disabled={signingOut}
          />
        </VStack>
        <VStack>
          <Button onPress={signOut} text="Sign Out" disabled={signingOut} />
        </VStack>
      </CardContainer>
    </Box>
  );
};
