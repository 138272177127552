import { Box } from 'native-base';
import React, { useCallback, useState } from 'react';
import { StudentRegistrationForm } from '../../components/auth/StudentRegistrationForm';
import { RegistrationStackScreenWithNavProps } from '../../navigation/RegistrationNavigation';
import { useAppDispatch } from '../../store';
import { registrationActions } from '../../store/actions';
import { RegistrationData } from '../../store/registration/types';

export const StudentRegistrationScreen: React.FC<RegistrationStackScreenWithNavProps<'StudentRegistration'>> = ({
  navigation,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = useCallback(async (data: RegistrationData) => {
    setLoading(true);
    setDisabled(true);

    try {
      dispatch(registrationActions.setRegistration({ registrationData: data }));
      navigation.navigate('ParentAgreement');
    } catch (error) {
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  }, []);

  return (
    <Box flex={1}>
      <StudentRegistrationForm loading={loading} disabled={disabled} onSubmit={(data) => handleSubmit(data)} />
    </Box>
  );
};
