import { Box, Flex, Heading, ScrollView } from 'native-base';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { SponsorItem } from '../../components/sponsors/SponsorItem';
import { PageWrapper } from '../../components/ui';
import { CardContainer } from '../../components/ui/cards/CardContainer';
import { useSponsorItems } from '../../hooks';
import { HomeStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { isWeb, SMALL_SCREEN_SIZE_BREAKPOINT } from '../../utils';

export const SponsorsScreen: React.FC<HomeStackScreenWithNavProps<'Sponsors'>> = ({ navigation, route }) => {
  const { sponsors } = useSponsorItems();
  const { width: screenWidth } = useWindowDimensions();
  const isSmall = screenWidth < SMALL_SCREEN_SIZE_BREAKPOINT;
  const gap = (() => {
    if (isWeb) {
      if (isSmall) {
        return 48;
      }
    }

    return 32;
  })();

  return (
    <ScrollView>
      <PageWrapper>
        <Box w="100%" p="4">
          <Heading mb="4">Sponsors</Heading>
          <CardContainer>
            <Flex w="100%" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
              {sponsors.map((sponsor) => (
                <SponsorItem key={sponsor.id} sponsor={sponsor} gap={gap} />
              ))}
            </Flex>
          </CardContainer>
        </Box>
      </PageWrapper>
    </ScrollView>
  );
};
