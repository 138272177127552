import { useMemo } from 'react';
import { SelectInputOption } from '../../components/ui';
import { Race } from '../../constants';

export const useRaceSelectOptions = () => {
  const raceSelectOptions = useMemo((): SelectInputOption[] => {
    return Object.values(Race).map((r) => ({
      label: r,
      value: r,
    }));
  }, []);

  return { raceSelectOptions };
};
