export enum DevicePlatform {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
  MACOS = 'macos',
  WINDOWS = 'windows',
}

export interface Device {
  id: string;
  userId: string;
  expoPushToken: string;
  devicePushToken: string;
  platform: DevicePlatform;
  name?: string;
  brand?: string;
  manufacturer?: string;
  modelName?: string;
  osName?: string;
  osVersion?: string;
  created_at: string;
  updated_at: string;
}
