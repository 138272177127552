import { useIsFocused, useNavigationState } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useRegisterForNotifications, useRegisterForSmsNotifications } from '../hooks';
import { AppStackScreenWithNavProps } from '../navigation/AppNavigation';
import { useAppSelector } from '../store';
import { AuthStatus } from '../store/auth/types';
import { isWeb } from '../utils';
import { SplashScreen } from './SplashScreen';

export const LoadingScreen: React.FC<AppStackScreenWithNavProps<'Loading'>> = ({ navigation }) => {
  const isFocused = useIsFocused();
  const { status: authStatus } = useAppSelector((state) => state.auth);
  const { needsToRegister, registerForNotifications } = useRegisterForNotifications();
  const { needsToRegisterForSms } = useRegisterForSmsNotifications();
  const routes = useNavigationState((state) => state.routes);
  const numRoutes = routes.length;

  useEffect(() => {
    if (authStatus !== AuthStatus.AUTHENTICATED) {
      return;
    }

    if (!isWeb && !isFocused) {
      return;
    }

    setTimeout(() => {
      if (needsToRegister) {
        navigation.navigate('NotificationsOnboard', { continueToSmsOnboard: true });
      } else {
        if (needsToRegisterForSms) {
          navigation.navigate('SmsNotificationsOnboard');
        } else {
          if (numRoutes === 1) {
            // meaning only this screen is on the stack
            navigation.navigate((Platform.OS === 'web' ? 'Drawer' : 'Tabs') as any);
          }
        }

        // Always register for notifications when the session starts
        registerForNotifications({ alertOnDenied: false });
      }
    }, 200);
  }, [authStatus, isFocused, needsToRegister, needsToRegisterForSms, navigation, numRoutes, registerForNotifications]);

  return <SplashScreen />;
};
