import { MaterialIcons } from '@expo/vector-icons';
import { HStack, Icon, Pressable, Text } from 'native-base';
import React, { useCallback, useMemo } from 'react';
import { openUrl } from '../../../utils';
import { CardContainer } from './CardContainer';

export type AltLinkCardProps = {
  title: string;
  url: string;
  icon?: React.ReactNode;
  iconName?: string;
  iconColor?: string;
};

export const AltLinkCard: React.FC<AltLinkCardProps> = ({ icon, iconName, title, url, iconColor }) => {
  const handlePress = useCallback(async () => {
    return openUrl(url);
  }, [url]);

  const iconComponent = useMemo(() => {
    if (icon) {
      return icon;
    }

    const iconNameToUse = iconName || 'info-outline';

    return <Icon as={MaterialIcons} name={iconNameToUse} size="md" color={iconColor || 'black'} />;
  }, [icon, iconName, iconColor, url]);

  return (
    <Pressable onPress={handlePress}>
      <CardContainer>
        <HStack>
          {iconComponent}
          <Text ml={2} fontWeight="bold">
            {title}
          </Text>
        </HStack>
      </CardContainer>
    </Pressable>
  );
};
