import { Box } from 'native-base';
import React from 'react';
import { ScheduleList } from '../../components/schedule/ScheduleList';
import { ScheduleStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { isWeb } from '../../utils';

export const ScheduleScreen: React.FC<ScheduleStackScreenWithNavProps<'Schedule'>> = ({ navigation, route }) => {
  return (
    <Box flex={1} alignItems={isWeb ? 'center' : undefined}>
      <ScheduleList onItemPress={(item) => navigation.navigate('Event', { id: item.id })} />
    </Box>
  );
};
