import * as yup from 'yup';
import { yupPhone } from '../../rules';
import { emailField } from './common';

export const volunteerRegistrationFormSchema = yup.object({
  type: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: emailField,
  alternateEmail: yup.string().email('Please provide a valid email address'),
  schoolAccountSfid: yup.string().required('Please select a school'),
  mobilePhone: yupPhone.string().phone().formatPhone().required(),
  addressLine1: yup.string().required(),
  addressLine2: yup.string(),
  addressCity: yup.string().required(),
  addressState: yup.string().required(),
  addressStateOther: yup.string().test(
    'address-state-other-required',
    () => `Required when State is "Other"`,
    (value, context) => {
      return context.parent.addressState?.toLowerCase() !== 'other' || !!value;
    },
  ),
  addressCountry: yup.string().required(),
  addressZip: yup.string().required(),
  dateOfBirth: yup.string().required(),
  company: yup.string().required(),
  title: yup.string(),
  designation: yup.string(),
  hundredWomenInFinance: yup.boolean().required(),
  collegeAttended: yup.string(),
  race: yup.string().required(),
  genderIdentification: yup.string().required(),
});
