import { useMemo } from 'react';
import { SelectInputOption } from '../../components/ui';

export const useReferrerSelectOptions = () => {
  const referrerSelectOptions: SelectInputOption[] = useMemo(() => {
    /**
     * Options limited to the picklist values available in Salesforce.
     */
    return [
      { value: 'Invited by Teacher', label: 'Invited by Teacher' },
      { value: 'Poster in School', label: 'Poster in School' },
      { value: 'PA announcement', label: 'PA Announcement' },
      { value: 'Invited by a Guidance Counselor', label: 'Invited by a Guidance Counselor' },
      { value: 'School Newsletter', label: 'School Newsletter' },
      { value: 'School website', label: 'School Website' },
      { value: 'Social Media (Facebook/Instagram/Twitter)', label: 'Social Media (Facebook/Instagram/Twitter)' },
      { value: 'Google Search', label: 'Google Search' },
    ];
  }, []);

  return { referrerSelectOptions };
};
