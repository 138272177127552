import moment from 'moment-timezone';
import { useMemo } from 'react';
import { Activity } from '../../models/view';
import { useCampaign } from '../data';

const BASE_TIME_FORMAT = 'h:mm A';
const TIME_FORMAT_WITH_TIMEZONE = `${BASE_TIME_FORMAT} z`;

export const useActivityData = (activity: Activity | null) => {
  const { campaign } = useCampaign();

  const timezone = useMemo(() => {
    if (!campaign) {
      return 'UTC';
    }

    return campaign.timezone || 'UTC';
  }, [campaign]);

  const date = useMemo(() => {
    if (!activity) {
      return '';
    }

    return moment(activity.dateTime).tz(timezone).format('MMMM D, YYYY');
  }, [activity, timezone]);

  const time = useMemo(() => {
    if (!activity) {
      return '';
    }

    const date = moment(activity.dateTime).tz(timezone);

    if (activity.duration || activity.duration === 0) {
      const end = date.clone().add(activity.duration!, 'hours');

      return `${date.format(BASE_TIME_FORMAT)} - ${end.format(TIME_FORMAT_WITH_TIMEZONE)}`;
    } else {
      return date.format(TIME_FORMAT_WITH_TIMEZONE);
    }
  }, [activity, timezone]);

  return { date, time };
};
