import { useCallback, useMemo } from 'react';
import { SettingSlugs } from '../../constants';
import { Settings } from '../../models/data';
import { useAppSelector } from '../../store';

export const useSettings = () => {
  const { settings: settingsData } = useAppSelector((state) => state.settings);

  const settings: Settings = useMemo(() => {
    const settingsMap: Settings = {};

    settingsData.forEach((setting) => {
      settingsMap[setting.slug] = setting;
    });

    return settingsMap;
  }, [settingsData]);

  const getSettingValueByName = useCallback(
    (name: SettingSlugs | string): string => {
      return settings[name]?.value ?? '';
    },
    [settings],
  );

  return { settings, getSettingValueByName };
};
