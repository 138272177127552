import { Ionicons } from '@expo/vector-icons';
import { Heading, HStack, Pressable, Text, VStack } from 'native-base';
import React, { useMemo } from 'react';
import { useCampaign, useCurrentUser } from '../../hooks';
import { colors } from '../../styles';

export interface MenuHeaderProps {
  onPress: () => void;
}

export const MenuHeader: React.FC<MenuHeaderProps> = ({ onPress }) => {
  const {
    user: { firstName, lastName, email },
  } = useCurrentUser();
  const { campaign } = useCampaign();

  const campaignSchoolName = useMemo(() => {
    return campaign ? campaign?.schoolName : null;
  }, [campaign]);

  return (
    <Pressable onPress={onPress}>
      <VStack>
        <HStack alignItems="center" justifyContent="space-between">
          <Heading>
            {firstName} {lastName}
          </Heading>
          <Ionicons name="settings-outline" size={20} color={colors.primary} />
        </HStack>
        {campaignSchoolName ? <Text>{campaignSchoolName}</Text> : null}
        <Text>{email}</Text>
      </VStack>
    </Pressable>
  );
};
