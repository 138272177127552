import { Text } from 'native-base';
import React from 'react';
import { colors } from '../../../styles';

export interface FormLabelProps {
  label: string;
}

export const FormLabel: React.FC<FormLabelProps> = ({ label }) => {
  return (
    <Text fontWeight="bold" textTransform="uppercase" color={colors.darkGrey}>
      {label}
    </Text>
  );
};
