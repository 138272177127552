import { useMemo } from 'react';
import { FeedItemLocation } from '../../models/data';
import { FeedItem as ViewFeedItem } from '../../models/view';
import { useAppSelector } from '../../store';

export const useHomeFeedItems = () => {
  const { feedItems } = useAppSelector((state) => state.feed);

  const homeFeedItems = useMemo((): ViewFeedItem[] => {
    return feedItems
      .filter((fi) => fi.location === FeedItemLocation.HOME)
      .map((feedItem) => ({
        id: feedItem.id,
        location: feedItem.location,
        title: feedItem.title,
        actionLinkType: feedItem.actionLinkType,
        actionLink: feedItem.actionLink,
        description: feedItem.description,
        imageUrl: feedItem.imageUrl,
        actionText: feedItem.actionText,
        actionColor: feedItem.actionColor,
      }));
  }, [feedItems]);

  return { homeFeedItems };
};
