import { LinkingOptions, NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { useSessionStart } from '../hooks';
import { SplashScreen } from '../screens/SplashScreen';
import { AppNavigation, AppStackParamList } from './AppNavigation';
import { AuthNavigation, AuthStackParamList } from './AuthNavigation';
import { RegistrationNavigation, RegistrationStackParamList } from './RegistrationNavigation';

export type RootStackParamList = AppStackParamList & AuthStackParamList & RegistrationStackParamList;

const modalLinkingOptions = {
  SpringEnrollment: 'enrollment/spring',
  StudentReenrollment: 'enrollment/student',
  VolunteerReenrollment: 'enrollment/volunteer',
  Event: 'events/:id',
  Notifications: 'notifications',
  Notification: 'notifications/:id',
};

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [],
  config: {
    initialRouteName: 'Loading',
    screens: {
      Loading: 'loading',
      Start: 'start',
      Redirect: 'redirect',
      SelectRole: 'registration/select-role',
      StudentRegistration: 'registration/student',
      ParentAgreement: 'registration/parent-agreement',
      VolunteerRegistration: 'registration/volunteer',
      NotificationsOnboard: 'registration/notifications',
      SmsNotificationsOnboard: 'registration/sms-notifications',
      RootMenu: {
        screens: Platform.select({
          web: {},
          default: {
            Menu: 'menu',
            Settings: 'settings',
            Contacts: 'contacts',
            Contact: 'contacts/:id',
            About: 'about',
            StudentCEO: 'about/student-ceo',
            DeleteAccount: 'auth/delete-account',
          },
        }),
      },
      ...(Platform.OS === 'web' ? {} : modalLinkingOptions),
      [Platform.select({ web: 'Drawer', default: 'Tabs' })]: {
        screens: {
          HomeStack: {
            screens: {
              Home: 'home',
              Sponsors: 'sponsors',
            },
          },
          ScheduleStack: {
            screens: {
              Schedule: 'schedule',
            },
          },
          ResourcesStack: {
            screens: {
              Resources: 'resources',
            },
          },
          MenuStack: {
            screens: Platform.select({
              default: {},
              web: {
                Menu: 'menu',
                Settings: 'settings',
                Contacts: 'contacts',
                Contact: 'contacts/:id',
                About: 'about',
                StudentCEO: 'student-ceo',
                DeleteAccount: 'auth/delete-account',
              },
            }),
          },
          ...(Platform.OS === 'web' ? modalLinkingOptions : {}),
        },
      },
    },
  },
};

export interface NavProps {
  navRef: RefObject<NavigationContainerRef<RootStackParamList>>;
}

export const Nav: React.FC<NavProps> = ({ navRef }) => {
  const [viewState, setViewState] = useState<'loading' | 'auth' | 'registration' | 'app'>('loading');

  // If this is a post logout, then close the window
  if (Platform.OS === 'web' && window.opener) {
    if (new URLSearchParams(window.location.search).has('logout')) {
      window.close();
    }
  }

  const { startSession } = useSessionStart({
    onSessionStartWithRole: useCallback(() => {
      setViewState('app');
    }, []),
    onSessionStartWithoutRole: useCallback(() => {
      setViewState('registration');
    }, []),
    onNoSession: useCallback(() => {
      setViewState('auth');
    }, []),
    onSessionEnded: useCallback(() => {
      setViewState('auth');
    }, []),
  });

  useEffect(() => {
    startSession();
  }, []);

  // TODO: This is a hack to get around the fact that the registration navigation is not a stack navigator. We need to
  // refactor the registration navigation to be a stack navigator so that we can use the navigation prop to navigate
  // to the app screen after registration.
  const handleRegistrationSuccess = () => setViewState('app');

  const innerNav = useMemo(() => {
    if (viewState === 'app') {
      return <AppNavigation />;
    } else if (viewState === 'registration') {
      return <RegistrationNavigation onRegistrationSuccess={handleRegistrationSuccess} />;
    } else if (viewState === 'auth') {
      return <AuthNavigation />;
    } else {
      return null;
    }
  }, [viewState]);

  if (viewState === 'loading') {
    return <SplashScreen />;
  }

  return (
    <NavigationContainer ref={navRef} linking={linking}>
      {innerNav}
    </NavigationContainer>
  );
};
