import { DrawerNavigationOptions } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { Box, ChevronLeftIcon } from 'native-base';
import { Image, Platform, Pressable, useWindowDimensions, View } from 'react-native';
import { DrawerHeaderIcon } from '../components/ui/DrawerHeaderIcon';
import { colors } from '../styles';
import { DRAWER_VISIBILITY_BREAKPOINT, isWeb } from '../utils';

export const defaultInnerTabPageOptions: NativeStackNavigationOptions = {
  headerTintColor: colors.black,
  headerTitleStyle: { fontFamily: 'Avenir-Bold' },
  headerBackTitleStyle: {
    fontFamily: 'Avenir-Regular',
  },
  headerLeft: ({ canGoBack }) => {
    const navigation = useNavigation();
    const dimensions = useWindowDimensions();

    if (isWeb && dimensions.width < DRAWER_VISIBILITY_BREAKPOINT) {
      return <DrawerHeaderIcon />;
    }

    return !isWeb && canGoBack ? (
      <Pressable style={Platform.select({ web: { marginLeft: 12 } })} onPress={() => navigation.goBack()}>
        <Box py={4} pr={4}>
          <ChevronLeftIcon />
        </Box>
      </Pressable>
    ) : null;
  },
};

export const defaultInnerDrawerPageOptions: DrawerNavigationOptions = {
  headerTintColor: colors.black,
  headerTitleStyle: { fontFamily: 'Avenir-Bold' },
  headerLeft: () => {
    const dimensions = useWindowDimensions();

    return isWeb && dimensions.width < DRAWER_VISIBILITY_BREAKPOINT ? <DrawerHeaderIcon /> : null;
  },
};

export const optionsWithCustomOnboardHeader: NativeStackNavigationOptions = {
  headerShown: true,
  header: () => {
    return (
      <View
        style={[
          {
            width: '100%',
            height: 100,
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: 4,
            backgroundColor: 'white',
            borderBottomWidth: 1,
            borderBottomColor: colors.lightGrey,
          },
          Platform.select({ web: { justifyContent: 'center', alignItems: 'center' } }),
        ]}
      >
        <Image
          source={require('../../assets/images/homepage_background_white.png')}
          style={{ width: '60%', resizeMode: 'contain', height: 50 }}
        />
      </View>
    );
  },
};
