import { useMemo } from 'react';
import { SelectInputOption } from '../../components/ui';
import { states as statesData } from '../../constants';

export const useStateSelectOptions = () => {
  const stateSelectOptions = useMemo((): SelectInputOption[] => {
    return statesData.map((state) => ({
      label: state.label,
      value: state.value,
    }));
  }, [statesData]);

  return { stateSelectOptions };
};
