import { Box } from 'native-base';
import React, { useCallback, useState } from 'react';
import { VolunteerRegistrationForm } from '../../components/auth/VolunteerRegistrationForm';
import { useRegistrationSubmit } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActions, registrationActions } from '../../store/actions';
import { VolunteerRegistrationData } from '../../store/registration/types';

type VolunteerRegistrationScreenProps = { onRegistrationSuccess: () => void };

export const VolunteerRegistrationScreen: React.FC<VolunteerRegistrationScreenProps> = ({ onRegistrationSuccess }) => {
  const dispatch = useAppDispatch();

  const { currentUser } = useAppSelector((state) => state.auth);

  const [navigating, setNavigating] = useState(false);

  const { loading, disabled, handleSubmit } = useRegistrationSubmit<VolunteerRegistrationData>({
    onDone: useCallback(() => {
      setNavigating(true);
      onRegistrationSuccess();
    }, []),
    onSubmit: useCallback(
      async (data) => {
        if (currentUser && data.mobilePhone) {
          await dispatch(
            authActions.updateCurrentUser({
              ...currentUser,
              receiveSmsNotifications: !!data.mobilePhone,
            }),
          ).unwrap();
        }

        return dispatch(registrationActions.submitRegistration({ registrationData: data }));
      },
      [currentUser, dispatch],
    ),
  });

  return (
    <Box flex={1}>
      <VolunteerRegistrationForm
        loading={loading || navigating}
        disabled={disabled || navigating}
        onSubmit={(data) => handleSubmit(data)}
      />
    </Box>
  );
};
