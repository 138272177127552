import { BlurView } from 'expo-blur';
import { groupBy } from 'lodash';
import { Box, Heading, Pressable, SectionList } from 'native-base';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useContacts } from '../../hooks';
import { Contact } from '../../models/view';
import { useAppDispatch } from '../../store';
import { contactActions } from '../../store/actions';
import { colors } from '../../styles';
import { isWeb, MAX_WEB_CONTAINER_WIDTH } from '../../utils';
import { nativeAlert } from '../../utils/ui';
import { NoData } from '../NoData';
import { Card } from '../ui';

export interface ContactsListItemProps {
  contact: Contact;
  onPress: () => void;
}

export const ContactsListItem: React.FC<ContactsListItemProps> = ({ contact, onPress }) => {
  return (
    <Pressable onPress={onPress}>
      <Card title={contact.name} subtitle={contact.title} hideAction hideBody titleMargin={0} actionText={''} />
    </Pressable>
  );
};

export interface ContactsListProps {
  onItemPress: (item: Contact) => void;
}

export const ContactsList: React.FC<ContactsListProps> = ({ onItemPress }) => {
  const dispatch = useAppDispatch();
  const { contacts: items, loading, error } = useContacts();
  const [handledError, setHandledError] = useState(true);

  useEffect(() => {
    if (error && !handledError) {
      setHandledError(true);
      nativeAlert('Loading Error', 'There was a problem loading your contacts. Please try again.');
    }
  }, [handledError, error]);

  const groupedItems = useMemo(() => {
    return Object.entries(
      groupBy(items, (item) => {
        return item.lastName[0].toUpperCase();
      }),
    )
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([month, items]) => {
        return {
          title: month,
          data: items,
        };
      });
  }, [items]);

  const refresh = useCallback(() => {
    dispatch(contactActions.getContacts());

    setTimeout(() => {
      setHandledError(false);
    }, 1000);
  }, [dispatch]);

  return (
    <SectionList
      w="100%"
      h="100%"
      maxWidth={isWeb ? MAX_WEB_CONTAINER_WIDTH : undefined}
      contentContainerStyle={{ paddingBottom: 24 }}
      sections={groupedItems}
      keyExtractor={(item) => item.id}
      renderItem={({ item }) => (
        <Box px={4}>
          <ContactsListItem contact={item} onPress={() => onItemPress(item)} />
        </Box>
      )}
      renderSectionHeader={({ section: { title } }) => (
        <Box px={4}>
          <BlurView style={{ width: '100%', height: 40, flex: 1, justifyContent: 'flex-end', paddingBottom: 10 }}>
            <Heading fontSize={12} fontWeight={'700'} color={colors.darkGrey} textTransform="uppercase">
              {title}
            </Heading>
          </BlurView>
        </Box>
      )}
      ListHeaderComponent={
        <Heading py={4} px={4}>
          Contacts
        </Heading>
      }
      ListEmptyComponent={<NoData px={4} />}
      onRefresh={refresh}
      refreshing={loading}
    />
  );
};
