import { useMemo } from 'react';
import { Activity } from '../../models/view';
import { useAppSelector } from '../../store';

export const useScheduleItems = () => {
  const { programEvents, error, loading } = useAppSelector((state) => state.programEvents);

  const scheduleItems = useMemo((): Activity[] => {
    return programEvents.map((event) => ({
      id: event.id.toString(),
      sfid: event.sfid,
      title: event.volunteerJob.name,
      description: event.description,
      dateTime: new Date(event.startDateTime),
      duration: event.duration,
      location: event.volunteerJob.locationInformation,
    }));
  }, [programEvents]);

  return { scheduleItems, error, loading };
};
