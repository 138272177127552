import { Box } from 'native-base';
import React from 'react';

export interface CardContainerProps {
  children: React.ReactNode;
  backgroundColor?: string;
}

export const CardContainer: React.FC<CardContainerProps> = ({ backgroundColor, children }) => {
  return (
    <Box backgroundColor={backgroundColor ?? 'white'} p={14} borderRadius={10} mb={4} shadow={1}>
      {children}
    </Box>
  );
};
