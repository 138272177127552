import { Checkbox, ICheckboxProps, Text } from 'native-base';
import React, { useCallback } from 'react';
import { colors } from '../../../../styles';
import { FormControl, FormControlInputProps } from './FormControl';

export interface CheckboxInputProps extends FormControlInputProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
  inputProps?: ICheckboxProps;
}

export const CheckboxInput: React.FC<CheckboxInputProps> = React.memo((props) => {
  const { value, onChange, label, disabled, inputProps } = props;

  const handleChange = useCallback(() => {
    onChange(!value);
  }, [onChange, value]);

  return (
    <FormControl {...props}>
      <Checkbox
        isDisabled={disabled}
        value={value ? 'true' : ''}
        isChecked={value}
        onChange={handleChange}
        borderWidth={1}
        _checked={{
          backgroundColor: colors.primary,
          borderColor: colors.primary,
        }}
        _unchecked={{
          borderColor: colors.lighterGrey,
        }}
        _focus={{
          borderColor: colors.primary,
        }}
        size="lg"
        {...inputProps}
      >
        <Text fontSize={14}>{label}</Text>
      </Checkbox>
    </FormControl>
  );
});
