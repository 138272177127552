import { MaterialIcons } from '@expo/vector-icons';
import { Box, Heading, HStack, Icon, Stack, Text } from 'native-base';
import React, { useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import { PageWrapper } from '../../components/ui';
import { CardAction } from '../../components/ui/cards/CardAction';
import { CardContainer } from '../../components/ui/cards/CardContainer';
import { useNotification } from '../../hooks/data/useNotifications';
import { AppStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppDispatch } from '../../store';
import { notificationActions } from '../../store/actions';
import { colors } from '../../styles';

export const NotificationScreen: React.FC<AppStackScreenWithNavProps<'Notification'>> = ({ navigation, route }) => {
  const dispatch = useAppDispatch();
  const { notification } = useNotification(route.params?.id);

  useEffect(() => {
    if (notification && !notification.readAt) {
      dispatch(notificationActions.markAsRead(notification.id));
    }
  }, [notification]);

  if (!notification) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <ActivityIndicator color={colors.darkGrey} />
      </Stack>
    );
  }

  return (
    <PageWrapper>
      <Box p={4}>
        <Heading mb={4} size="sm">
          {notification.subject}
        </Heading>
        <CardContainer>
          {notification.urgency && (
            <HStack mb={4} space={1} alignItems="center">
              <Icon as={MaterialIcons} name="error" color={colors.warning} />
              <Text color={colors.warning} fontWeight="bold">
                Urgent
              </Text>
            </HStack>
          )}
          <Text fontSize={16} mb={4}>
            {notification.message}
          </Text>
          {notification.link && (
            <Box mb={4}>
              <CardAction actionText={'See More'} actionLink={notification.link} />
            </Box>
          )}
          <Text fontSize={12}>
            {notification.createdAt.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
            {' at '}
            {notification.createdAt.toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              timeZoneName: 'short',
            })}
          </Text>
        </CardContainer>
      </Box>
    </PageWrapper>
  );
};
