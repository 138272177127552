import { API_URL } from '@env';
import { CalendarEvent, google, outlook } from 'calendar-link';
import moment from 'moment';
import { VStack } from 'native-base';
import React, { useCallback, useMemo } from 'react';
import { Activity } from '../../models/view';
import { openUrl } from '../../utils';
import { Button } from '../ui';

export interface CalendarLinksProps {
  activity: Activity;
  timezone?: string;
}

export const CalendarLinks: React.FC<CalendarLinksProps> = ({ activity, timezone }) => {
  const calendarEvent = useMemo((): CalendarEvent => {
    const start = moment(activity.dateTime)
      .tz(timezone ?? 'UTC')
      .format();

    const end = moment(activity.dateTime)
      .tz(timezone ?? 'UTC')
      .add(activity.duration || 0, 'hours')
      .format();

    return {
      title: activity.title,
      description: activity.description,
      location: activity.location ?? 'Check with your RTSWS instructor',
      start,
      end,
      busy: true,
      url: 'http://www.rockthestreetwallstreet.com/',
    };
  }, [activity, timezone]);

  const downloadFile = useCallback(() => {
    openUrl(`${API_URL}/events/${activity.sfid}`);
  }, [activity]);

  const openGoogle = useCallback(() => {
    openUrl(google(calendarEvent));
  }, [calendarEvent]);

  const openOutlook = useCallback(() => {
    openUrl(outlook(calendarEvent));
  }, [calendarEvent]);

  return (
    <VStack space={2} mb={4}>
      <Button transparent onPress={() => downloadFile()} text="Download Event" color="secondary" />
      <Button transparent onPress={() => openGoogle()} text="Add to Google" color="secondary" />
      <Button transparent onPress={() => openOutlook()} text="Add to Outlook" color="secondary" />
    </VStack>
  );
};
