import React from 'react';
import { CardAction, CardActionProps } from './CardAction';
import { CardBody, CardBodyProps } from './CardBody';
import { CardContainer } from './CardContainer';
import { CardTitle, CardTitleProps } from './CardTitle';

export type CardProps = CardTitleProps &
  CardBodyProps &
  CardActionProps & {
    hideBody?: boolean;
    hideAction?: boolean;
  };

export const Card: React.FC<CardProps> = (props) => {
  return (
    <CardContainer>
      <CardTitle {...props} />
      {!props.hideBody && <CardBody {...props} />}
      {!props.hideAction && <CardAction {...props} />}
    </CardContainer>
  );
};
