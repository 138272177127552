import { Ionicons } from '@expo/vector-icons';
import { Box, Divider, Heading, HStack, ScrollView, Stack, Text } from 'native-base';
import React, { useMemo } from 'react';
import { ActivityIndicator } from 'react-native';
import { stripHtml } from 'string-strip-html';
import { CalendarLinks } from '../../components/schedule/CalendarLinks';
import { IconTitle, PageWrapper } from '../../components/ui';
import { CardAction } from '../../components/ui/cards/CardAction';
import { CardContainer } from '../../components/ui/cards/CardContainer';
import { userIsVolunteer } from '../../hooks/alerts/utils';
import { useActivity, useActivityData, useCampaign } from '../../hooks';
import { AppStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppSelector } from '../../store';
import { colors } from '../../styles';

export const EventScreen: React.FC<AppStackScreenWithNavProps<'Event'>> = ({ navigation, route }) => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { campaign } = useCampaign();
  const { activity } = useActivity(route.params?.id);
  const { date, time: originalTime } = useActivityData(activity);

  const time = useMemo(() => {
    return originalTime?.replace(' - ', ' to\n');
  }, [originalTime]);

  const location = useMemo(() => {
    return stripHtml(activity?.location || '').result.trim();
  }, [activity]);

  const isVolunteer = useMemo(() => userIsVolunteer(currentUser), [currentUser]);

  if (!activity) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <ActivityIndicator color={colors.darkGrey} />
      </Stack>
    );
  }

  return (
    <ScrollView>
      <PageWrapper>
        <Box p={4}>
          <Heading mb={4}>{activity.title}</Heading>
          <CardContainer>
            <HStack alignItems="center" justifyContent="space-between">
              <Box w="50%">
                <IconTitle
                  title={date}
                  variant="info"
                  renderIcon={(color) => <Ionicons name="calendar-outline" color={color} size={20} />}
                />
              </Box>
              <Box w="50%">
                <IconTitle
                  title={time}
                  variant="info"
                  renderIcon={(color) => <Ionicons name="time-outline" color={color} size={20} />}
                />
              </Box>
            </HStack>
            <Divider mb={4} />
            {location && (
              <Heading size="xs" mb={4}>
                {location}
              </Heading>
            )}
            <Text mb={4}>{activity.description}</Text>
            <CalendarLinks activity={activity} timezone={campaign?.timezone} />
            {campaign?.attendanceLink && isVolunteer && (
              <>
                <Divider mb={4} />
                <CardAction
                  actionText="Open The Attendance Sheet"
                  actionColor={activity.actionColor}
                  actionLink={campaign.attendanceLink}
                />
              </>
            )}
          </CardContainer>
        </Box>
      </PageWrapper>
    </ScrollView>
  );
};
