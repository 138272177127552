import { startCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ProgramTeams, SettingSlugs } from '../../constants';
import { Contact as DataContact } from '../../models/data';
import { Contact } from '../../models/view';
import { useAppSelector } from '../../store';
import { useCampaign } from './useCampaign';
import { useSettings } from './useSettings';

const campaignContactToViewContact = (contact?: DataContact, title: string = ''): Contact | undefined => {
  if (!contact) {
    return undefined;
  }

  const nameParts = contact.name.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(' ');

  return {
    id: contact.sfid,
    name: contact.name,
    firstName,
    lastName,
    email: contact.email,
    phone: contact.mobilePhone,
    title,
  };
};

export const useContacts = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { campaign } = useCampaign();
  const { settings, getSettingValueByName } = useSettings();
  const { contacts: contactsData, loading, error } = useAppSelector((state) => state.contacts);

  const programContact = useMemo((): Contact | undefined => {
    if (!campaign || !campaign.rtswsContact) {
      return undefined;
    }

    const [teamKeyName] = Object.entries(ProgramTeams).find(
      ([_, value]) => value === campaign.rtswsContact,
    ) as string[];

    const teamName = getSettingValueByName(SettingSlugs[`${teamKeyName}_NAME` as keyof typeof SettingSlugs]);
    const teamEmail = getSettingValueByName(SettingSlugs[`${teamKeyName}_EMAIL` as keyof typeof SettingSlugs]);
    const teamTitle = getSettingValueByName(SettingSlugs[`${teamKeyName}_TITLE` as keyof typeof SettingSlugs]);

    if (!teamName || !teamEmail || !teamTitle) {
      return undefined;
    }

    const nameParts = teamName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(' ');

    return {
      id: campaign.rtswsContact,
      name: teamName,
      firstName,
      lastName,
      email: teamEmail,
      phone: '',
      title: teamTitle,
    };
  }, [campaign, getSettingValueByName]);

  const contacts = useMemo((): Contact[] => {
    const cohortContacts = contactsData.map((contact) => {
      const nameParts = contact.name.split(' ');
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(' ');

      return {
        id: contact.id.toString(),
        name: contact.name,
        firstName,
        lastName,
        email: contact.email,
        phone: contact.mobilePhone,
        title: startCase(currentUser?.role.name),
      };
    });

    const campaignContacts: Contact[] = [
      campaignContactToViewContact(campaign?.schoolChampion, 'School Champion'),
      campaignContactToViewContact(campaign?.coSchoolChampion, 'Co-School Champion'),
      campaignContactToViewContact(campaign?.coSchoolChampion2, 'Co-School Champion'),
      campaignContactToViewContact(campaign?.leadVolunteer, 'Lead Volunteer'),
      campaignContactToViewContact(campaign?.coLeadVolunteer, 'Co-Lead Volunteer'),
      campaignContactToViewContact(campaign?.studentCeo, 'Student CEO'),
      campaignContactToViewContact(campaign?.coStudentCeo, 'Co-Student CEO'),
      programContact,
    ].filter((c) => c) as Contact[];

    return [...cohortContacts, ...campaignContacts];
  }, [currentUser, campaign, contactsData, programContact]);

  return { contacts, loading, error };
};

export const useContact = (id?: string) => {
  const { contacts } = useContacts();
  const [contact, setContact] = useState<Contact | null>(null);

  useEffect(() => {
    setContact(contacts.find((a) => a.id === id) ?? null);
  }, [id]);

  return { contact };
};
