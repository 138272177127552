import { FeedItem } from '../models/data';
import BaseService from './BaseService';

export class FeedItemService extends BaseService {
  async getFeedItems() {
    return (await this.getJson<FeedItem[]>('/feed')).data;
  }
}

export default new FeedItemService();
