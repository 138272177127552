import { Account } from '../models/data';
import BaseService from './BaseService';

export class AccountService extends BaseService {
  async getSchoolAccounts() {
    return (await this.getJson<Account[]>('/accounts/schools')).data;
  }

  async getSponsorAccounts() {
    return (await this.getJson<Account[]>('/accounts/sponsors')).data;
  }
}

export default new AccountService();
