import moment from 'moment';
import { useMemo } from 'react';
import { useScheduleItems } from './useScheduleItems';

export const useUpcomingActivities = () => {
  const { scheduleItems } = useScheduleItems();

  const futureItems = useMemo(() => {
    const now = moment();

    return scheduleItems.filter((i) => {
      const date = moment(i.dateTime);
      return date.isValid() && date.isSameOrAfter(now);
    });
  }, [scheduleItems]);

  return { upcomingActivities: [futureItems[0], futureItems[1]].filter((a) => a) };
};

export const useActivity = (id?: string) => {
  const { scheduleItems } = useScheduleItems();

  const activity = useMemo(() => {
    return scheduleItems.find((a) => a.id === id) ?? null;
  }, [id, scheduleItems]);

  return { activity };
};
