import * as WebBrowser from 'expo-web-browser';
import React, { useEffect } from 'react';
import { SplashScreen } from '../SplashScreen';

export const RedirectScreen: React.FC = () => {
  useEffect(() => {
    WebBrowser.maybeCompleteAuthSession();
  }, []);

  return <SplashScreen />;
};
