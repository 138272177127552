import { Box, Pressable } from 'native-base';
import React from 'react';
import { colors } from '../../../styles';
import { CardBody, CardBodyProps } from './CardBody';
import { CardContainer } from './CardContainer';
import { CardTitle, CardTitleProps } from './CardTitle';

export type ActivityCardProps = CardTitleProps &
  CardBodyProps & {
    onPress?: () => void;
  };

export const ActivityCard: React.FC<ActivityCardProps> = (props) => {
  return (
    <Pressable onPress={props.onPress}>
      <CardContainer>
        <Box borderColor={colors.primary} borderLeftWidth={3} pl={4}>
          <CardTitle {...props} />
          <CardBody {...props} contentColor={colors.darkGrey} contentFontSize={13} contentMargin={0} />
        </Box>
      </CardContainer>
    </Pressable>
  );
};
