import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../models/data';
import { authService, notificationService, userService } from '../../services';
import { commonActions } from '../commonActions';
import { deviceActions } from '../device/slice';
import { AuthState, AuthStatus } from './types';

const initialState: AuthState = {
  status: AuthStatus.IDLE,
  currentUser: null,
  token: null,
  loading: true,
  error: null,
};

export const refreshSession = createAsyncThunk('auth/refreshSession', async (_, thunkAPI) => {
  const sessionRefreshed = await authService.refreshSession();

  if (sessionRefreshed) {
    await thunkAPI.dispatch(fetchUser());
  } else {
    await thunkAPI.dispatch(notAuthenticated());
  }
});

const notAuthenticated = createAsyncThunk('auth/notAuthenticated', async () => {
  // Handle any thing that should happen when a user loads the app without authentication
});

export const fetchUser = createAsyncThunk('auth/refreshCurrentUser', async () => {
  return userService.getSelf();
});

export const signInUser = createAsyncThunk('auth/signInUser', async (_, thunkAPI) => {
  const signedIn = await authService.signIn();

  if (signedIn) {
    await thunkAPI.dispatch(fetchUser());
  } else {
    await thunkAPI.dispatch(notAuthenticated());
  }
});

export const updateCurrentUser = createAsyncThunk('auth/updateCurrentUser', async (user: User) => {
  return userService.updateSelf(user);
});

export const signOutUser = createAsyncThunk(
  'auth/signOutUser',
  async (
    {
      currentDeviceId,
      includeBrowserSessionLogout = true,
    }: { currentDeviceId?: string; includeBrowserSessionLogout?: boolean },
    thunkAPI,
  ) => {
    if (currentDeviceId) {
      await thunkAPI.dispatch(deviceActions.deleteDevice(currentDeviceId));
    }

    await thunkAPI.dispatch(commonActions.clearCache());
    await notificationService.clear();
    await authService.signOut(includeBrowserSessionLogout);
  },
);

export const deleteUser = createAsyncThunk(
  'auth/deleteUser',
  async ({ currentDeviceId }: { currentDeviceId?: string }, thunkAPI) => {
    await userService.deleteSelf();

    return thunkAPI.dispatch(signOutUser({ currentDeviceId, includeBrowserSessionLogout: true }));
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setUser(state, { payload: { newUser } }: PayloadAction<{ newUser: User }>) {
      state.currentUser = newUser;
    },

    setToken(state, { payload: { token } }: PayloadAction<{ token: string }>) {
      state.token = token;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshSession.pending, (state) => {
      state.status = AuthStatus.AUTHENTICATING;
      state.loading = true;
    });

    builder.addCase(notAuthenticated.fulfilled, (state) => {
      state.loading = false;
      state.status = AuthStatus.UNAUTHENTICATED;
    });

    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.status = AuthStatus.AUTHENTICATED;
        console.log('AuthSlice: Fetched user', state.currentUser);
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to set current user.';
        state.currentUser = null;
        console.log('AuthSlice: Could not fetch user:', action);
      });

    builder
      .addCase(updateCurrentUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.status = AuthStatus.AUTHENTICATED;
      })
      .addCase(updateCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to update current user.';
      });

    builder
      .addCase(signInUser.pending, (state) => {
        state.loading = true;
        state.status = AuthStatus.AUTHENTICATING;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.loading = false;
        state.status = AuthStatus.UNAUTHENTICATED;
        state.error = action.error.message ?? 'Unable to sign in user.';
      });

    builder
      .addCase(signOutUser.pending, (state) => {
        state.loading = true;
        state.status = AuthStatus.UNAUTHENTICATING;
      })
      .addCase(signOutUser.fulfilled, (state) => {
        state.loading = false;
        state.currentUser = null;
        state.status = AuthStatus.UNAUTHENTICATED;
      })
      .addCase(signOutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to sign out user.';
      });

    builder
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to delete user.';
      });
  },
});

export const authActions = {
  ...authSlice.actions,
  refreshSession,
  signInUser,
  fetchUser,
  updateCurrentUser,
  signOutUser,
  deleteUser,
};

export type AuthSlice = {
  [authSlice.name]: ReturnType<typeof authSlice['reducer']>;
};
