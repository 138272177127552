import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';
import { UserNotification } from '../models/data';
import BaseService from './BaseService';

export interface NotificationsParams {
  page?: number;
  count?: number;
}

const NOTIFICATIONS_NEEDS_TO_REGISTER = 'notificationsNeedsToRegister';
const NOTIFICATIONS_NEEDS_TO_REGISTER_TRUE = 'notificationsNeedsToRegisterTrue';
const NOTIFICATIONS_NEEDS_TO_REGISTER_FALSE = 'notificationsNeedsToRegisterFalse';
const SMS_NEEDS_TO_REGISTER = 'smsNeedsToRegister';
const SMS_NEEDS_TO_REGISTER_TRUE = 'smsNeedsToRegisterTrue';
const SMS_NEEDS_TO_REGISTER_FALSE = 'smsNeedsToRegisterFalse';

export class NotificationService extends BaseService {
  async getNotifications(page?: number, count?: number) {
    const currentPage: number = page || 1;

    let params: NotificationsParams = {};

    if (page) {
      params['page'] = currentPage;
    }

    if (count) {
      params['count'] = count;
    }

    return (
      await this.getJson<UserNotification[]>('/user-notifications', {
        params,
      })
    ).data;
  }

  async getNotification(id: string) {
    return (await this.getJson<UserNotification>(`/users/${id}`)).data;
  }

  async markAsRead(id: string) {
    return (
      await this.putJson<UserNotification>(`/user-notifications/${id}/read`, { readAt: new Date().toISOString() })
    ).data;
  }

  async postNotification(message: string) {
    return (
      await this.postJson<any>(`/notifications/send`, {
        message,
      })
    ).data;
  }

  async getNeedsToRegister(): Promise<boolean> {
    let stringValue: string | null = null;

    if (Platform.OS === 'web') {
      stringValue = await AsyncStorage.getItem(NOTIFICATIONS_NEEDS_TO_REGISTER);
    } else {
      stringValue = await SecureStore.getItemAsync(NOTIFICATIONS_NEEDS_TO_REGISTER);
    }

    return !stringValue || stringValue === NOTIFICATIONS_NEEDS_TO_REGISTER_TRUE;
  }

  async setNeedsToRegister(value: boolean): Promise<boolean> {
    let stringValue = NOTIFICATIONS_NEEDS_TO_REGISTER_FALSE;

    if (value) {
      stringValue = NOTIFICATIONS_NEEDS_TO_REGISTER_TRUE;
    }

    if (Platform.OS === 'web') {
      await AsyncStorage.setItem(NOTIFICATIONS_NEEDS_TO_REGISTER, stringValue);
    } else {
      await SecureStore.setItemAsync(NOTIFICATIONS_NEEDS_TO_REGISTER, stringValue);
    }

    return value;
  }

  async getNeedsToRegisterForSms(): Promise<boolean> {
    let stringValue: string | null = null;

    if (Platform.OS === 'web') {
      stringValue = await AsyncStorage.getItem(SMS_NEEDS_TO_REGISTER);
    } else {
      stringValue = await SecureStore.getItemAsync(SMS_NEEDS_TO_REGISTER);
    }

    return !stringValue || stringValue === SMS_NEEDS_TO_REGISTER_TRUE;
  }

  async setNeedsToRegisterForSms(value: boolean): Promise<boolean> {
    let stringValue = SMS_NEEDS_TO_REGISTER_FALSE;

    if (value) {
      stringValue = SMS_NEEDS_TO_REGISTER_TRUE;
    }

    if (Platform.OS === 'web') {
      await AsyncStorage.setItem(SMS_NEEDS_TO_REGISTER, stringValue);
    } else {
      await SecureStore.setItemAsync(SMS_NEEDS_TO_REGISTER, stringValue);
    }

    return value;
  }

  async clear() {
    await super.clear();
    await this.clearNeedsToRegister();
  }

  async clearNeedsToRegister() {
    if (Platform.OS === 'web') {
      await Promise.all([
        AsyncStorage.removeItem(NOTIFICATIONS_NEEDS_TO_REGISTER),
        AsyncStorage.removeItem(SMS_NEEDS_TO_REGISTER),
      ]);
    } else {
      await Promise.all([
        SecureStore.deleteItemAsync(NOTIFICATIONS_NEEDS_TO_REGISTER),
        SecureStore.deleteItemAsync(SMS_NEEDS_TO_REGISTER),
      ]);
    }
  }
}

export default new NotificationService();
