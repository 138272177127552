import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { DrawerStackNavigationProp } from '../../navigation/AppNavigation';
import { colors } from '../../styles';

export const DrawerHeaderIcon = () => {
  const navigation = useNavigation<DrawerStackNavigationProp<'HomeStack'>>();

  const onMenuPressed = () => navigation.toggleDrawer();

  return (
    <View
      style={{
        flex: 1,
        marginLeft: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <TouchableOpacity onPress={() => onMenuPressed()} testID="headerMenuBtn">
        <Feather name="menu" size={24} color={colors.primary} />
      </TouchableOpacity>
    </View>
  );
};
