import { Box } from 'native-base';
import React, { useMemo } from 'react';
import { useProfessionalNetworks } from '../../hooks/data/useProfessionalNetworks';
import { ProfessionalNetwork } from '../../models/view';
import { AltLinkCard, LinkedInIcon } from '../ui';

export interface ProfessionalNetworksListItemProps {
  network: ProfessionalNetwork;
}

export const ProfessionalNetworksListItem: React.FC<ProfessionalNetworksListItemProps> = ({ network }) => {
  const iconName = useMemo(() => {
    switch (network.type) {
      case 'linkedin':
      default:
        return 'logo-linkedin';
    }
  }, [network.type]);

  const prefix = useMemo(() => {
    switch (network.type) {
      case 'linkedin':
      default:
        return 'LinkedIn';
    }
  }, [network.type]);

  const icon = useMemo(() => {
    switch (network.type) {
      case 'linkedin':
      default:
        return <LinkedInIcon size={20} />;
    }
  }, [network.type]);

  return <AltLinkCard icon={icon} iconName={iconName} title={`${prefix}: ${network.title}`} url={network.url} />;
};

export interface ProfessionalNetworksListProps {}

export const ProfessionalNetworksList: React.FC<ProfessionalNetworksListProps> = () => {
  const { professionalNetworks } = useProfessionalNetworks();

  return (
    <Box>
      {professionalNetworks.map((network) => (
        <Box mb={-2} key={network.id}>
          <ProfessionalNetworksListItem network={network} />
        </Box>
      ))}
    </Box>
  );
};
