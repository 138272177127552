import { useMemo } from 'react';
import { GalleryItem } from '../../models/view';
import { useAppSelector } from '../../store';

export const useGalleryItems = () => {
  const { galleryItems: galleryData } = useAppSelector((state) => state.gallery);

  const galleryItems = useMemo((): GalleryItem[] => {
    return galleryData.map((item) => ({
      id: item.id,
      caption: item.caption,
      uri: item.url,
    }));
  }, [galleryData]);

  return { galleryItems };
};
