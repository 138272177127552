import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { notificationActions } from '../../store/actions';

interface UseRegisterForSmsOutput {
  needsToRegisterForSms: boolean;
  markAsDone: () => Promise<void>;
}

export const useRegisterForSmsNotifications = (): UseRegisterForSmsOutput => {
  const dispatch = useAppDispatch();
  const { needsToRegisterForSms } = useAppSelector((state) => state.notifications);

  const markAsDone = useCallback(async () => {
    dispatch(notificationActions.setNeedsToRegisterForSms({ value: false }));
  }, []);

  return { needsToRegisterForSms, markAsDone };
};
