import { useNavigation } from '@react-navigation/native';
import { Box } from 'native-base';
import React, { useCallback } from 'react';
import { TabStackNavigationProp } from '../../../navigation/AppNavigation';
import { openUrl } from '../../../utils';
import { nativeAlert } from '../../../utils/ui';
import { Button } from '../buttons';

export interface CardActionProps {
  actionText: string;
  actionLink?: string;
  actionLinkType?: 'URL' | 'NAV';
  actionColor?: 'primary' | 'secondary' | 'default' | 'danger';
  onActionPress?: () => void;
}

export const CardAction: React.FC<CardActionProps> = ({
  actionColor = 'default',
  actionText,
  actionLink,
  actionLinkType = 'URL',
  onActionPress,
}) => {
  const navigation = useNavigation<TabStackNavigationProp<'HomeStack'>>();

  const handlePress = useCallback(async () => {
    if (actionLink) {
      if (actionLinkType === 'URL') {
        openUrl(actionLink);
      } else if (actionLinkType === 'NAV') {
        try {
          const { name, params } = JSON.parse(actionLink) as { name: any; params?: any };
          navigation.navigate(name, params);
        } catch (err) {
          nativeAlert('Navigation Error', 'Could not navigate to the requested page.');
        }
      }
    }

    if (onActionPress) {
      onActionPress();
    }
  }, [actionLink, actionLinkType, onActionPress]);

  return (
    <Box>
      <Button text={actionText} color={actionColor} onPress={() => handlePress()} />
    </Box>
  );
};
