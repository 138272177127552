import React, { PropsWithChildren } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

export interface FormProps extends PropsWithChildren {
  hasNavBar?: boolean;
  extraScrollHeight?: number;
  resetOnBlur?: boolean;
}

export const Form: React.FC<FormProps> = ({
  children,
  hasNavBar = true,
  extraScrollHeight = 64,
  resetOnBlur = false,
}) => {
  return (
    <KeyboardAwareScrollView
      viewIsInsideTabBar={hasNavBar}
      extraScrollHeight={extraScrollHeight}
      enableResetScrollToCoords={resetOnBlur}
    >
      {children}
    </KeyboardAwareScrollView>
  );
};
