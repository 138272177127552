import { FormControl as NBFormControl, Stack, WarningOutlineIcon } from 'native-base';
import React, { useMemo } from 'react';
import { FormLabel } from '../FormLabel';

export interface FormControlProps extends React.PropsWithChildren<{}> {
  label: string;
  helperText?: string;
  hideHelperText?: boolean;
  hideLabel?: boolean;
  error?: string | string[];
  hideErrorMessage?: boolean;
  disabled?: boolean;
  loading?: boolean;
  required?: boolean;
  width?: string;
  center?: boolean;
}

export type FormControlInputProps = Omit<FormControlProps, 'children'>;

export const FormControl: React.FC<FormControlProps> = React.memo((props) => {
  const {
    label,
    error,
    helperText,
    hideHelperText = false,
    hideLabel = false,
    hideErrorMessage = false,
    disabled = false,
    loading = false,
    required = false,
    width = '100%',
    center = false,
    children,
  } = props;

  const errorText = useMemo(() => {
    if (Array.isArray(error)) {
      return error.join('\n');
    }

    return error;
  }, [error]);

  return (
    <NBFormControl w={width} isInvalid={!!error} marginBottom={2} isRequired={required}>
      <Stack w={width} alignItems={center ? 'center' : 'flex-start'} justifyContent={center ? 'center' : 'flex-start'}>
        {!hideLabel && (
          <NBFormControl.Label>
            <FormLabel label={label} />
          </NBFormControl.Label>
        )}
        {children}
        {!hideHelperText && helperText && <NBFormControl.HelperText>{helperText}</NBFormControl.HelperText>}
        {!hideErrorMessage && (
          <NBFormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
            {errorText}
          </NBFormControl.ErrorMessage>
        )}
      </Stack>
    </NBFormControl>
  );
});
