import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedItem } from '../../models/data';
import { feedItemService } from '../../services';
import { commonActions } from '../commonActions';
import { FeedState } from './types';

const getFeedItems = createAsyncThunk('feedItems/getFeedItems', async () => {
  return feedItemService.getFeedItems();
});

const initialState: FeedState = {
  feedItems: [],
  loading: false,
  error: null,
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setFeedItems(state, { payload: { feedItems } }: PayloadAction<{ feedItems: FeedItem[] }>) {
      state.feedItems = feedItems;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeedItems.fulfilled, (state, action) => {
        state.loading = false;
        state.feedItems = action.payload;
      })
      .addCase(getFeedItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load feed.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });
  },
});

export const feedActions = { ...feedSlice.actions, getFeedItems };

export type FeedItemSlice = {
  [feedSlice.name]: ReturnType<typeof feedSlice['reducer']>;
};
