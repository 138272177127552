import { sampleSize } from 'lodash';
import { Flex } from 'native-base';
import React, { useMemo } from 'react';
import { useSponsorItems } from '../../hooks';
import { SponsorItem } from '../sponsors/SponsorItem';

const NUM_SPONSORS = 4;

export interface SponsorProps {}

export const SponsorsList: React.FC<SponsorProps> = () => {
  const { sponsors } = useSponsorItems();

  const randomSponsors = useMemo(() => {
    return sampleSize(sponsors, NUM_SPONSORS);
  }, [sponsors]);

  return (
    <Flex w="100%" flexDirection="row" flexWrap="wrap" justifyContent="space-around">
      {randomSponsors.map((sponsor) => (
        <SponsorItem key={sponsor.id} sponsor={sponsor} />
      ))}
    </Flex>
  );
};
