import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserNotification } from '../../models/data';
import { notificationService } from '../../services';
import { NotificationsParams } from '../../services/NotificationService';
import { commonActions } from '../commonActions';
import { NotificationState } from './types';

const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async (params: NotificationsParams = {}) => {
    return notificationService.getNotifications(params.page, params.count);
  },
);

const markAsRead = createAsyncThunk('notifications/markAsRead', async (id: string) => {
  return notificationService.markAsRead(id);
});

const sendNotification = createAsyncThunk<any, { message: string }>(
  'notifications/sendNotification',
  async ({ message }) => {
    return notificationService.postNotification(message);
  },
);

const setNeedsToRegister = createAsyncThunk<any, { value: boolean }>(
  'notifications/setNeedsToRegister',
  async ({ value }) => {
    return notificationService.setNeedsToRegister(value);
  },
);

const getNeedsToRegister = createAsyncThunk<boolean>('notifications/getNeedsToRegister', async () => {
  return await notificationService.getNeedsToRegister();
});

const setNeedsToRegisterForSms = createAsyncThunk<any, { value: boolean }>(
  'notifications/setNeedsToRegisterForSms',
  async ({ value }) => {
    return notificationService.setNeedsToRegisterForSms(value);
  },
);

const getNeedsToRegisterForSms = createAsyncThunk<boolean>('notifications/getNeedsToRegisterForSms', async () => {
  return await notificationService.getNeedsToRegisterForSms();
});

const initialState: NotificationState = {
  userNotifications: [],
  loading: false,
  error: null,
  needsToRegister: false,
  needsToRegisterForSms: false,
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setNotifications(state, { payload: { notifications } }: PayloadAction<{ notifications: UserNotification[] }>) {
      state.userNotifications = notifications;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.userNotifications = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load notifications.';
      });

    builder.addCase(getNeedsToRegister.fulfilled, (state, action) => {
      state.needsToRegister = action.payload;
    });

    builder.addCase(setNeedsToRegister.fulfilled, (state, action) => {
      state.needsToRegister = action.payload;
    });

    builder
      .addCase(markAsRead.pending, (state) => {
        state.loading = true;
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        state.loading = false;
        const notification = action.payload;
        state.userNotifications = state.userNotifications.map((n) => {
          if (n.id === notification.id) {
            return notification;
          }
          return n;
        });
      })
      .addCase(markAsRead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to mark notification as read.';
      });

    builder
      .addCase(sendNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendNotification.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(sendNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to send notification.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });

    builder.addCase(getNeedsToRegisterForSms.fulfilled, (state, action) => {
      state.needsToRegisterForSms = action.payload;
    });

    builder.addCase(setNeedsToRegisterForSms.fulfilled, (state, action) => {
      state.needsToRegisterForSms = action.payload;
    });
  },
});

export const notificationActions = {
  ...notificationSlice.actions,
  getNotifications,
  markAsRead,
  sendNotification,
  setNeedsToRegister,
  getNeedsToRegister,
  getNeedsToRegisterForSms,
  setNeedsToRegisterForSms,
};

export type NotificationSlice = {
  [notificationSlice.name]: ReturnType<typeof notificationSlice['reducer']>;
};
