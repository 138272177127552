import { Box } from 'native-base';
import React from 'react';
import { useActivityData, useUpcomingActivities } from '../../hooks';
import { Activity } from '../../models/view';
import { NoData } from '../NoData';
import { ActivityCard } from '../ui';

export interface UpcomingActivityListProps {
  onPressActivity: (activity: Activity) => void;
}

export interface UpcomingActivityListItemProps {
  activity: Activity;
  onPress: () => void;
}

export const UpcomingActivityListItem: React.FC<UpcomingActivityListItemProps> = ({ activity, onPress }) => {
  const { date, time } = useActivityData(activity);

  return <ActivityCard title={activity.title} overline={date} content={time} onPress={onPress} />;
};

export const UpcomingActivityList: React.FC<UpcomingActivityListProps> = ({ onPressActivity }) => {
  const { upcomingActivities } = useUpcomingActivities();

  if (upcomingActivities.length === 0) {
    return <NoData py={4} message="No upcoming activities." />;
  }

  return (
    <Box>
      {upcomingActivities.map((activity) => (
        <Box mb="-2" key={activity.id}>
          <UpcomingActivityListItem activity={activity} onPress={() => onPressActivity(activity)} />
        </Box>
      ))}
    </Box>
  );
};
