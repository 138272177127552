import * as Clipboard from 'expo-clipboard';
import { Box, Heading, Pressable, Text } from 'native-base';
import React, { useCallback, useMemo } from 'react';
import Curricula from '../../constants/Curricula';
import { useCampaign, useSettings } from '../../hooks';
import { openUrl } from '../../utils';
import { nativeAlert } from '../../utils/ui';
import { CardContainer } from '../ui/cards/CardContainer';

export interface CurriculumLinksProps {}

export const CurriculumLinks: React.FC<CurriculumLinksProps> = () => {
  const { campaign } = useCampaign();
  const { getSettingValueByName } = useSettings();

  const curriculum = useMemo(() => {
    return Curricula.find((c) => c.name === campaign?.curriculumSet);
  }, [campaign]);

  const { url, name, password } = useMemo(() => {
    return {
      name: curriculum?.curriculum_display_name ?? '',
      url: curriculum ? getSettingValueByName(curriculum.curriculum_url_slug) : '',
      password: curriculum ? getSettingValueByName(curriculum.curriculum_password_slug) : '',
    };
  }, [curriculum, getSettingValueByName]);

  const openCurriculum = useCallback(async () => {
    await Clipboard.setStringAsync(password);
    await nativeAlert(name, `The password has been copied to the clipboard. Press "Okay" to continue.`);
    openUrl(url);
    setTimeout(() => {
      Clipboard.setStringAsync('');
    }, 30000);
  }, [name, url, password]);

  if (!campaign || !curriculum) {
    return null;
  }

  return (
    <Box>
      <CardContainer>
        <Pressable onPress={openCurriculum}>
          <Heading size="xs" mb={2}>
            View {name}
          </Heading>
          <Text>
            Password: <Text fontFamily="mono">{password}</Text>
          </Text>
        </Pressable>
      </CardContainer>
    </Box>
  );
};
