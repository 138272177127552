import { Text } from 'native-base';
import React from 'react';

export interface AboutTextProps {
  fontSize?: number;
  textAlign?: 'left' | 'center' | 'right';
}

export const AboutText: React.FC<AboutTextProps> = ({ fontSize, textAlign }) => {
  return (
    <Text fontSize={fontSize} textAlign={textAlign}>
      Rock The Street, Wall Street is a financial and investment literacy program designed to bring both gender and
      racial equity to the financial markets and spark the interest of high school girls into careers of finance.
    </Text>
  );
};
