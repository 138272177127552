import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProgramEvent } from '../../models/data';
import { programEventService } from '../../services';
import { commonActions } from '../commonActions';
import { ProgramEventState } from './types';

const getProgramEvents = createAsyncThunk('programEvents/getProgramEvents', async () => {
  return programEventService.getProgramEvents();
});

const initialState: ProgramEventState = {
  currentProgramEvent: null,
  programEvents: [],
  loading: false,
  error: null,
};

export const programEventSlice = createSlice({
  name: 'programEvents',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setProgramEvent(state, { payload: { programEvent } }: PayloadAction<{ programEvent: ProgramEvent }>) {
      state.currentProgramEvent = programEvent;
    },

    setProgramEvents(state, { payload: { programEvents } }: PayloadAction<{ programEvents: ProgramEvent[] }>) {
      state.programEvents = programEvents;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProgramEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProgramEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.programEvents = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(getProgramEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load programEvents.';
      });

    builder.addCase(commonActions.clearCache.fulfilled, () => {
      return { ...initialState };
    });
  },
});

export const programEventActions = { ...programEventSlice.actions, getProgramEvents };

export type ProgramEventSlice = {
  [programEventSlice.name]: ReturnType<typeof programEventSlice['reducer']>;
};
