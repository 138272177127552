import { Box } from 'native-base';
import React from 'react';

export interface FullWidthSectionProps {
  children: React.ReactNode;
  backgroundColor?: string;
  mx?: number;
}

export const FullWidthSection: React.FC<FullWidthSectionProps> = ({ backgroundColor, mx, children }) => {
  return (
    <Box
      backgroundColor={backgroundColor ?? 'white'}
      mx={mx ?? -4}
      p={4}
      borderTopWidth={0.5}
      borderBottomWidth={0.5}
      borderColor="gray.200"
    >
      {children}
    </Box>
  );
};
