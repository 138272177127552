import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from '@env';
import { FirebaseApp, getApps, initializeApp } from 'firebase/app';

const defaultFirebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

let app: FirebaseApp;

export const createFirebaseApp = (options = defaultFirebaseConfig) => {
  if (!getApps().length) {
    Object.keys(options).forEach((key) => {
      if (!options[key as keyof typeof options]) {
        throw new Error(`Missing Firebase config value for ${key}`);
      }
    });

    app = initializeApp(options);
  }

  return app;
};
