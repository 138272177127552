import { Box } from 'native-base';
import React from 'react';

export interface SectionContainerProps {
  children: React.ReactNode;
  mb?: number | string;
  marginBottom?: number | string;
}

export const SectionContainer: React.FC<SectionContainerProps> = ({ mb, marginBottom, children }) => {
  return <Box mb={mb || marginBottom || 6}>{children}</Box>;
};
