import * as yup from 'yup';
import { yupPhone } from '../../rules';
import { emailField } from './common';

export const parentAgreementFormSchema = yup.object({
  parentFirstName: yup.string().required(),
  parentLastName: yup.string().required(),
  parentEmail: emailField,
  parentMobilePhone: yupPhone.string().phone().formatPhone().required(),
  parentAcknowledged: yup
    .boolean()
    .oneOf([true], 'You must agree to the terms and conditions.')
    .required('You must agree to the terms and conditions.'),
});
