import { APP_ENV } from '@env';
import { NavigationContainerRef } from '@react-navigation/native';
import { Accelerometer } from 'expo-sensors';
import { useEffect } from 'react';
import { Platform } from 'react-native';
import { RootStackParamList } from '../navigation';

export const useDebugMenu = (navRef: React.RefObject<NavigationContainerRef<RootStackParamList>>) => {
  useEffect(() => {
    if (APP_ENV !== 'local') {
      return;
    }

    if (Platform.OS !== 'ios') {
      return;
    }

    let lastX: number, lastY: number, lastZ: number;
    let lastUpdate = 0;
    const { remove } = Accelerometer.addListener((data) => {
      const { x, y, z } = data;
      const curTime = new Date().getTime();
      if (curTime - lastUpdate > 100) {
        const diffTime = curTime - lastUpdate;
        lastUpdate = curTime;
        const speed = (Math.abs(x + y + z - lastX - lastY - lastZ) / diffTime) * 10000;

        if (speed > 150) {
          navRef?.current?.navigate('Debug');
        }

        lastX = x;
        lastY = y;
        lastZ = z;
      }
    });

    return () => {
      remove();
    };
  }, []);
};
