import { useMemo } from 'react';
import { Link } from '../../models/view';
import { useAppSelector } from '../../store';

export const useAdditionalLinks = () => {
  const { resources } = useAppSelector((state) => state.resources);

  const additionalLinks = useMemo((): Link[] => {
    return resources.map((resource) => ({
      id: resource.id,
      title: resource.name,
      url: resource.link,
      iconName: resource.icon,
    }));
  }, [resources]);

  return { additionalLinks };
};
