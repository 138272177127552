import { Student } from '../models/data';
import BaseService from './BaseService';

export class StudentService extends BaseService {
  protected currentStudent?: Student;

  async getStudents() {
    return (await this.getJson<Student[]>('/users')).data;
  }

  async getStudent(id: string) {
    return (await this.getJson<Student>(`/users/${id}`)).data;
  }
}

export default new StudentService();
