import { User } from '../models/data';
import {
  ReenrollmentData,
  RegistrationData,
  VolunteerReenrollmentData,
  VolunteerRegistrationData,
} from '../store/registration/types';
import BaseService from './BaseService';

export class UserService extends BaseService {
  async getSelf() {
    return (await this.getJson<User>('/users/me')).data;
  }

  async updateSelf(user: User) {
    return (await this.putJson<User>('/users/me', user)).data;
  }

  async deleteSelf() {
    return (await this.deleteJson('/users/me')).data;
  }

  async submitRegistration(registrationData: RegistrationData | VolunteerRegistrationData, currentUser: User) {
    return (await this.postJson<User>(`/users/${currentUser.id}/register`, registrationData)).data;
  }

  async submitSpringEnrollment(currentUser: User) {
    return (await this.postJson<any>(`/users/${currentUser.id}/spring-enroll`, {})).data;
  }

  async submitReenrollment(currentUser: User, data: ReenrollmentData | VolunteerReenrollmentData) {
    return (await this.postJson<any>(`/users/${currentUser.id}/reenroll`, data)).data;
  }
}

export default new UserService();
