import {
  Box,
  Center,
  ChevronLeftIcon,
  ChevronRightIcon,
  HStack,
  IconButton,
  Image,
  Pressable,
  Text,
} from 'native-base';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Platform } from 'react-native';
import ImageView from 'react-native-image-viewing';
import { useGalleryItems } from '../../hooks';
import { colors } from '../../styles';

export interface GalleryProps {}

export const GalleryList: React.FC<GalleryProps> = () => {
  const { galleryItems } = useGalleryItems();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isGalleryVisible, setIsGalleryVisible] = useState(false);

  useEffect(() => {
    (async () => {
      await Promise.allSettled(galleryItems.map((item) => Image.prefetch(item.uri)));
      setLoading(false);
    })();
  }, [galleryItems]);

  const handleNext = useCallback(() => {
    let nextIndex: number;

    if (currentIndex < galleryItems.length - 1) {
      nextIndex = currentIndex + 1;
    } else {
      nextIndex = 0;
    }

    setLoading(true);
    setCurrentIndex(nextIndex);
  }, [currentIndex]);

  const handlePrevious = useCallback(() => {
    let prevIndex: number;

    if (currentIndex > 0) {
      prevIndex = currentIndex - 1;
    } else {
      prevIndex = galleryItems.length - 1;
    }

    setLoading(true);
    setCurrentIndex(prevIndex);
  }, [currentIndex]);

  const currentImage = useMemo(() => {
    return galleryItems[currentIndex];
  }, [currentIndex]);

  if (!currentImage) {
    return null;
  }

  return (
    <Box>
      <HStack>
        <IconButton
          flex={0}
          flexBasis={Platform.OS === 'web' ? 'min-content' : undefined}
          onPress={() => handlePrevious()}
          color="primary.100"
        >
          <ChevronLeftIcon color={colors.primary} />
        </IconButton>
        <Pressable flex={1} onPress={() => setIsGalleryVisible(true)}>
          <Image
            flex={1}
            borderRadius={10}
            style={{ aspectRatio: 3 / 2, height: undefined }}
            source={{ uri: currentImage.uri }}
            alt={currentImage.caption}
            fallbackElement={
              <Box flex={1} height="200" justifyContent="center" alignItems="center" borderRadius={10}>
                <Text fontWeight="bold">Image Couldn't Be Loaded!</Text>
                <Text>{currentImage.caption}</Text>
              </Box>
            }
            onError={() => setLoading(false)}
            onLoadEnd={() => setLoading(false)}
            mb={4}
          />
        </Pressable>
        <IconButton flex={0} flexBasis={Platform.OS === 'web' ? 'min-content' : undefined} onPress={() => handleNext()}>
          <ChevronRightIcon color={colors.primary} />
        </IconButton>
      </HStack>
      <Box
        position="absolute"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        pointerEvents="none"
      >
        {loading && <ActivityIndicator color={colors.darkGrey} />}
      </Box>
      <ImageView
        images={galleryItems}
        imageIndex={currentIndex}
        visible={isGalleryVisible}
        FooterComponent={(data) => (
          <Center flex={1} mb={40}>
            <Text color={'white'} fontSize="md">
              {galleryItems[data.imageIndex].caption}
            </Text>
          </Center>
        )}
        onRequestClose={() => setIsGalleryVisible(false)}
      />
    </Box>
  );
};
