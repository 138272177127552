import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { FC } from 'react';
import { ParentAgreementScreen } from '../screens/auth/ParentAgreementScreen';
import { SelectRoleScreen } from '../screens/auth/SelectRoleScreen';
import { StudentRegistrationScreen } from '../screens/auth/StudentRegistrationScreen';
import { VolunteerRegistrationScreen } from '../screens/auth/VolunteerRegistrationScreen';
import { DebugScreen } from '../screens/menu/DebugScreen';
import { defaultInnerTabPageOptions, optionsWithCustomOnboardHeader } from './shared';

export type RegistrationStackParamList = {
  SelectRole: undefined;
  ParentAgreement: undefined;
  StudentRegistration: undefined;
  VolunteerRegistration: undefined;
  Debug: undefined;
};

export type RegistrationStackScreenWithNavProps<T extends keyof RegistrationStackParamList> = NativeStackScreenProps<
  RegistrationStackParamList,
  T
>;

type RegistrationNavScreenProps = { onRegistrationSuccess: () => void };

const RegistrationStack = createNativeStackNavigator<RegistrationStackParamList>();

export const RegistrationNavigation: FC<RegistrationNavScreenProps> = ({ onRegistrationSuccess }) => {
  return (
    <RegistrationStack.Navigator initialRouteName="SelectRole" screenOptions={{ gestureEnabled: false }}>
      <RegistrationStack.Screen
        name="SelectRole"
        component={SelectRoleScreen}
        options={optionsWithCustomOnboardHeader}
      />
      <RegistrationStack.Screen
        name="StudentRegistration"
        component={StudentRegistrationScreen}
        options={optionsWithCustomOnboardHeader}
      />
      <RegistrationStack.Screen name="ParentAgreement" options={optionsWithCustomOnboardHeader}>
        {(props) => <ParentAgreementScreen {...props} onRegistrationSuccess={onRegistrationSuccess} />}
      </RegistrationStack.Screen>
      <RegistrationStack.Screen name="VolunteerRegistration" options={optionsWithCustomOnboardHeader}>
        {(props) => <VolunteerRegistrationScreen {...props} onRegistrationSuccess={onRegistrationSuccess} />}
      </RegistrationStack.Screen>
      <RegistrationStack.Group screenOptions={{ presentation: 'modal' }}>
        <RegistrationStack.Screen name="Debug" component={DebugScreen} options={defaultInnerTabPageOptions} />
      </RegistrationStack.Group>
    </RegistrationStack.Navigator>
  );
};
