import { Box, Heading, ScrollView, Text, VStack } from 'native-base';
import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import { Button, PageWrapper } from '../../components/ui';
import { CardContainer } from '../../components/ui/cards/CardContainer';
import { useRegisterForSmsNotifications } from '../../hooks';
import { AppStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppDispatch, useAppSelector } from '../../store';
import { authActions } from '../../store/actions';
import { colors } from '../../styles';

export const SmsNotificationsOnboardScreen: React.FC<AppStackScreenWithNavProps<'SmsNotificationsOnboard'>> = ({
  navigation,
}) => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const { markAsDone } = useRegisterForSmsNotifications();

  const navToHome = useCallback(() => navigation.navigate((Platform.OS === 'web' ? 'Drawer' : 'Tabs') as any), []);

  const decline = useCallback(async () => {
    await markAsDone();

    if (currentUser) {
      await dispatch(
        authActions.updateCurrentUser({
          ...currentUser,
          receiveSmsNotifications: false,
        }),
      ).unwrap();
    }

    navToHome();
  }, [currentUser, markAsDone, navToHome]);

  const requestPermissions = useCallback(async () => {
    await markAsDone();

    if (currentUser) {
      await dispatch(
        authActions.updateCurrentUser({
          ...currentUser,
          receiveSmsNotifications: true,
        }),
      ).unwrap();
    }

    navToHome();
  }, [currentUser, markAsDone, navToHome]);

  return (
    <ScrollView>
      <PageWrapper>
        <Box p={4}>
          <Heading mb={4}>SMS Notifications</Heading>
          <CardContainer>
            <VStack>
              <Text fontSize={16} mb={4}>
                Rock The Street, Wall Street sends important notifications about events and other information including
                the following:
              </Text>
              <VStack space={2} mb={4}>
                <Text fontSize={16}>• Sessions & Event Details</Text>
                <Text fontSize={16}>• Session Reminders</Text>
                <Text fontSize={16}>• Event Opportunities</Text>
                <Text fontSize={16}>• Mentorship Signup</Text>
                <Text fontSize={16}>• Curriculum</Text>
                <Text fontSize={16}>• Career Center</Text>
              </VStack>
              <Text fontSize={12} fontStyle="italic" mb={4} color={colors.darkGrey}>
                We will use the phone number have on file to send you SMS notifications.
              </Text>
            </VStack>
            <VStack space={2}>
              <Button onPress={requestPermissions} text="Enable SMS Notifications" color="primary" />
              <Button onPress={decline} text="Do Not Enable SMS Notifications" />
            </VStack>
          </CardContainer>
        </Box>
      </PageWrapper>
    </ScrollView>
  );
};
