import { Box, Image, Text } from 'native-base';
import { ColorSchemeType } from 'native-base/lib/typescript/components/types';
import React from 'react';

export interface CardBodyProps {
  content?: React.ReactNode | string;
  contentColor?: ColorSchemeType;
  contentFontSize?: number;
  imageUrl?: string | null;
  imageAlt?: string;
  contentMargin?: number;
}

export const CardBody: React.FC<CardBodyProps> = ({
  imageAlt,
  imageUrl,
  content,
  contentColor,
  contentFontSize = 16,
  contentMargin = 4,
}) => {
  return (
    <Box>
      {imageUrl && (
        <Image
          borderRadius={10}
          style={{ aspectRatio: 3 / 2, width: '100%', height: undefined }}
          source={{ uri: imageUrl }}
          alt={imageAlt}
          mb={4}
        />
      )}
      {content && (
        <Text color={contentColor} fontSize={contentFontSize} mb={contentMargin}>
          {content}
        </Text>
      )}
    </Box>
  );
};
